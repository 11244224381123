import { Box, Button, Grid } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocationFilter } from "../../../../components/dashboard/shemes";
import {
  getSchemes,
  resetSchemes,
  setFilters,
} from "../../../../slices/siteInfo";
import { historyDate } from "../../../../utils/helper";
import InstalledWardList from "./InstalledWardList";
import { Loader } from "../../../../components/Loader/Loader";
import { Typography } from "@mui/material";
import {
  genearteDeviceInstallationReport,
  genearteDeviceInstallationReportMultiple,
} from "../../FirmwareTestSteps/_service";

function NewDeviceInstallation() {
  const { district, schemes, lastSearchFilter } = useSelector(
    (state) => state.siteinfo
  );
  const [allSchemes, setAllSchemes] = useState([]);
  const [installationDoneSchemes, setInstallationDoneSchemes] = useState([]);
  const [schemeLocation, setSchemeLocation] = useState({});
  const [selecltedWards, setSelectedWards] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const sendGetSchemeReq = () => {
    let d = {};
    if (schemeLocation.district) {
      d["district"] = schemeLocation.district;
    }
    if (schemeLocation.division) {
      d["division"] = schemeLocation.division;
    }
    if (schemeLocation.block) {
      d["block"] = schemeLocation.block;
    }
    if (schemeLocation.panchayat) {
      d["panchayat"] = schemeLocation.panchayat;
    }
    if (schemeLocation.ward) {
      d["ward"] = schemeLocation.ward;
    }
    dispatch(setFilters(schemeLocation));
    dispatch(getSchemes(d, historyDate(0, "currentDay")));
  };

  useEffect(() => {
    const installationDoneList = allSchemes.filter(
      (e) => e.installation_user_mapping && e.installation_data
    );
    setInstallationDoneSchemes(installationDoneList);
  }, [allSchemes]);

  const onChangeLocation = (data) => {
    const keys = Object.keys(data);
    let allReset = true;
    for (let i = 0; i < keys.length; i++) {
      allReset = allReset && !data[keys[i]];
    }

    if (allReset) {
      setAllSchemes([]);
      dispatch(resetSchemes());
    }
    setSchemeLocation(data);
  };

  useEffect(() => {
    if (
      schemeLocation.district ||
      schemeLocation.block ||
      schemeLocation.panchayat ||
      schemeLocation.ward
    ) {
      sendGetSchemeReq();
    }
  }, [schemeLocation]);

  useEffect(() => {
    setAllSchemes(schemes["data"]);
  }, [schemes]);

  return (
    <>
      <Box sx={{ py: 3 }}>
        <Grid container>
          <Grid md={12}>
            <LocationFilter
              onChange={onChangeLocation}
              initialValues={lastSearchFilter}
              schemes={allSchemes}
              downloadExcel={true}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ py: 1 }}>
        <Grid container spacing={3}>
          <Grid item xl={12} md={12} xs={12}>
            <div>
              Total Number of wards:{" "}
              <span style={{ fontWeight: "bold", marginRight: 10 }}>
                {allSchemes.length}
              </span>
              Installation Completed Number of Wards:{" "}
              <span style={{ fontWeight: "bold" }}>
                {installationDoneSchemes.length}
              </span>
            </div>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ py: 1 }}>
        <Grid container spacing={3}>
          <Grid item xl={12} md={12} xs={12}>
            {installationDoneSchemes && installationDoneSchemes.length > 0 ? (
              <InstalledWardList
                onSelect={setSelectedWards}
                sx={{ height: "100%" }}
                schemes={installationDoneSchemes}
              />
            ) : null}
          </Grid>
        </Grid>
        <Box sx={{ py: 1 }}>
          <Grid>
            {selecltedWards.length ? (
              <Button
                onClick={() => {
                  setLoading(true);
                  genearteDeviceInstallationReportMultiple({
                    selecltedWards,
                    assignmentType: "New Device Installation",
                  }).then((e) => {
                    setLoading(false);
                    if (e.statusCode == 200) {
                      if (e.data && e.data.filePath) {
                        var link = document.createElement("a");
                        link.href = e.data.filePath;
                        link.target = "_blank";
                        link.download = e.data.filename;
                        link.dispatchEvent(new MouseEvent("click"));
                      }
                    }
                  });
                }}
                variant="contained"
              >
                Download Report for Selected {selecltedWards.length} Wards
              </Button>
            ) : (
              ""
            )}
          </Grid>
          <Grid></Grid>
        </Box>
      </Box>
      {schemes.status == "loading" && <Loader />}
      {loading && <Loader />}
    </>
  );
}

export default NewDeviceInstallation;
