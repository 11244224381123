import { experimentalStyled } from '@material-ui/core/styles';

// const LogoRoot = experimentalStyled('svg');
import logo from "../images/logo.png"

const Logo = (props) => (
  <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
    <img width={"30%"} src={logo} />
  </div>
);

export default Logo;