import { createSlice } from '@reduxjs/toolkit';
import { API } from '../config';
import { get, post } from './service';

const initialState = {
    firmwareData: {
        status: null,
        data: null
    },
    firmwareDataByBarcode: {//registrationdata-with-barcode
        status: null,
        data: null
    },
    barCodeAssignedToWard:{
        status: null,
        data: null
    },
    linkDeviceToWard:{
        status: null,
        data: null
    },
    manualLinkingUpdateWardStatus:{
        status: null,
        data: null
    },
    updateFirmwareState : {
        status: null,
        data: null
    }
};

const slice = createSlice({
    name: 'firmwareData',
    initialState,
    reducers: {
        setFirmwareData(state, action) {
            state.firmwareData = action.payload
        },
        setFirmwareDataByBarcode(state, action) {
            state.firmwareDataByBarcode = action.payload
        },
        setBarcodeAssignedToWard(state, action) {
            state.barCodeAssignedToWard = action.payload
        },
        setLinkDeviceToWard(state, action) {
            state.linkDeviceToWard = action.payload
        },
        setManualWardStatusUpdate(state, action) {
            state.manualLinkingUpdateWardStatus = action.payload
        },
        setUpdateFWData(state, action){
            state.updateFirmwareState = action.payload
        }
    }
});

export const { reducer } = slice;

export const getRegisteredDevices = () => async (dispatch) => {
    dispatch(slice.actions.setFirmwareData({ ...initialState.firmwareData, status: "loading" }));

    get(API.base_url + "phed/debugging/getRegisteredDevices")
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setFirmwareData(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setFirmwareData({ ...errResponse, status: "failed" }))
        });
};

export const checkIfBarCodeAssignedToWard = (barcode) => async (dispatch) => {
    dispatch(slice.actions.setBarcodeAssignedToWard({ ...initialState.barCodeAssignedToWard, status: "loading" }));

    get(API.base_url + "phed/debugging/checkif-barcode-assigned-to-ward", {barcode})
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setBarcodeAssignedToWard(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setBarcodeAssignedToWard({ ...errResponse, status: "failed" }))
        });
};

export const getFirmwareDataByBarcode = (barcode) => async (dispatch) => {
    dispatch(slice.actions.setFirmwareDataByBarcode({ ...initialState.firmwareDataByBarcode, status: "loading" }));

    get(API.base_url + "phed/debugging/registrationdata-with-barcode", { barcode })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setFirmwareDataByBarcode(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setFirmwareDataByBarcode({ ...errResponse, status: "failed" }))
        });
};

export const linkDeviceToWardAction = (deviceId, wardId, doi, forceLink) => async (dispatch) => {
    dispatch(slice.actions.setLinkDeviceToWard({ ...initialState.linkDeviceToWard, status: "loading" }));

    post(API.base_url + "phed/manual-linking-device", { deviceId, wardId, doi, forceLink })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setLinkDeviceToWard(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setLinkDeviceToWard({ ...errResponse, status: "failed" }))
        });
};

export const linkDeviceUpdateWardStatus = (statusData, wardId, firmwareStatus) => async (dispatch) => {
    dispatch(slice.actions.setManualWardStatusUpdate({ ...initialState.manualLinkingUpdateWardStatus, status: "loading" }));

    post(API.base_url + "phed/debugging/update-manual-entry-status", { statusData, wardId, firmwareStatus })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setManualWardStatusUpdate(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setManualWardStatusUpdate({ ...errResponse, status: "failed" }))
        });
};

export const updateFirmwareData = (regData) => async (dispatch) => {
    dispatch(slice.actions.setUpdateFWData({ ...initialState.updateFirmwareState, status: "loading" }));

    post(API.base_url + "phed/debugging/update-firmware-data", { regData })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setUpdateFWData(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setUpdateFWData({ ...errResponse, status: "failed" }))
        });
};
export const resetManualLinking = () => async (dispatch) => {
    dispatch(slice.actions.setManualWardStatusUpdate({ ...initialState.manualLinkingUpdateWardStatus  }));
    dispatch(slice.actions.setLinkDeviceToWard({ ...initialState.linkDeviceToWard  }));
    dispatch(slice.actions.setBarcodeAssignedToWard({ ...initialState.barCodeAssignedToWard  }));
}
export default slice;