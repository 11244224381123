import { Box, Card, CardHeader, Grid, Typography } from "@material-ui/core"
import { Fragment } from "react"
import FlashOnIcon from '@material-ui/icons/FlashOn';
import EventIcon from '@material-ui/icons/Event';
import SurroundSoundIcon from '@material-ui/icons/SurroundSound';
import OpacityIcon from '@material-ui/icons/Opacity';
import CompassCalibrationIcon from '@material-ui/icons/CompassCalibration';
import { convertNumToTime } from "../../utils/helper";

function validateScheduleElectricity(d) {
    if (d["electricityAvalabilityV2"] && d["electricityAvalabilityV2"]["scheduled"]) {
        return convertNumToTime(d["electricityAvalabilityV2"]["scheduled"])
    } else {
        return "--:--"
    }
}

function unScheduledElectricity(d) {
    let scheduledEle = 0
    let totalEle = 0

    if (d["electricityAvalabilityV2"] && d["electricityAvalabilityV2"]["scheduled"]) {
        scheduledEle = d["electricityAvalabilityV2"]["scheduled"]
    } else {
        scheduledEle = 0
    }

    totalEle = d["electricityAvalability"]
    const res = (totalEle - scheduledEle)
    return isNaN(res) ? "--:--" : convertNumToTime(res.toFixed(1))
    // return totalEle - scheduledEle
}

function validateMotorRunning(d, key = "scheduled") {
    if (d["motorRunningTimeV2"] && d["motorRunningTimeV2"][key] >= 0) {
        return convertNumToTime(d["motorRunningTimeV2"][key])
    } else {
        return "--:--"
    }
}

function validateWaterConsumption(d, key = "waterConsumption") {
    if (d["motorRunningTimeV2"] && d["motorRunningTimeV2"][key]) {
        return d["motorRunningTimeV2"][key] / 1000
    } else {
        return "--"
    }
}

function unScheduledWaterConsumption(d, key = "waterConsumption") {
    let scheduledEle = 0
    let totalEle = 0
    if (d["motorRunningTimeV2"] && d["motorRunningTimeV2"][key]) {
        scheduledEle = d["motorRunningTimeV2"][key]
    } else {
        scheduledEle = 0
    }

    totalEle = d[key]

    // return (totalEle - scheduledEle) / 1000
    const res = (totalEle - scheduledEle) / 1000
    return isNaN(res) ? "--" : res.toFixed(1)
}

function unScheduledMotorRunning(d, key = "scheduled") {
    let scheduledEle = 0
    let totalEle = 0
    if (d["motorRunningTimeV2"] && d["motorRunningTimeV2"][key]) {
        scheduledEle = d["motorRunningTimeV2"][key]
    } else {
        scheduledEle = 0
    }

    totalEle = d["motorRunningTime"]

    const res = totalEle - scheduledEle
    return isNaN(res) ? "--:--" : convertNumToTime(res.toFixed(1))
}

function ValueDisplay(props) {
    return <div style={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "space-around" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <EventIcon style={{ fontSize: 16, color: props.type == "scheduled" ? "green" : "red", marginRight: 10 }} />
            <Typography variant="h6">{props.value}</Typography>
        </div>
    </div>
}


const FunctionalityUtilisation = function (props) {
    //console.log("props", props)
    const scheduleData = props.scheduleData
    return <Fragment>
        <Grid
            item
            md={props.type == "verticle"?12:6}
            xs={12}
        >
            <Card style={{ height: "100%" }}>
                <CardHeader title={"Functionality"} style={{ textAlign: 'center' }}/>
                <Box sx={{ py: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item md={6} textAlign={"center"}>
                            <div>
                                <div style={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "space-around" }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <FlashOnIcon style={{ color: "green" }} />
                                        <Typography variant="h6" alignItems="center"> Electricity</Typography>
                                    </div>
                                </div>
                                <Typography variant="h5">{convertNumToTime(scheduleData["electricityAvalability"] || 0)} Hrs</Typography>
                                <Grid container spacing={3}>
                                    <Grid item md={6} style={{ color: "green" }}>
                                        <ValueDisplay type="scheduled" value={`${validateScheduleElectricity(scheduleData)} Hrs`} />
                                        <span style={{ fontSize: 12 }}>  Schedule</span>
                                    </Grid>
                                    <Grid item md={6} style={{ color: "red" }}>
                                        <ValueDisplay value={`${unScheduledElectricity(scheduleData)} Hrs`} />
                                        <span style={{ fontSize: 12 }}>  Un-Schedule</span>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item md={6} textAlign={"center"}>
                            <div>
                                <div style={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "space-around" }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <SurroundSoundIcon style={{ color: "green" }} />
                                        <Typography variant="h6" alignItems="center">&nbsp;Motor Running</Typography>
                                    </div>
                                </div>
                                <Typography variant="h5">{convertNumToTime(scheduleData["motorRunningTime"] || 0)} Hrs</Typography>
                                <Grid container spacing={3}>
                                    <Grid item md={6} style={{ color: "green" }}>
                                        <ValueDisplay type="scheduled" value={`${validateMotorRunning(scheduleData || 0)} Hrs`} />
                                        <span style={{ fontSize: 12 }}>  Schedule</span>
                                    </Grid>
                                    <Grid item md={6} style={{ color: "red" }}>
                                        <ValueDisplay value={`${unScheduledMotorRunning(scheduleData)} Hrs`} />
                                        <span style={{ fontSize: 12 }}>  Un-Schedule</span>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>

                </Box>
            </Card>
        </Grid>
        <Grid
            item
            md={props.type == "verticle"?12:6}
            xs={12}
            style={{marginTop:props.type == "verticle"?15:0}}
        >
            <Card style={{ height: "100%" }}>
                <CardHeader title={"Utilization"} style={{ textAlign: 'center' }} />
                <Box sx={{ py: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item md={6} textAlign={"center"}>
                            <div>
                                <div style={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "space-around" }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <OpacityIcon style={{ color: "green" }} />
                                        <Typography variant="h6" alignItems="center">&nbsp;Water Volume</Typography>
                                    </div>
                                </div>
                                <Typography variant="h5">{(scheduleData["waterConsumption"] / 1000 )|| 0}  Kl</Typography>
                                <Grid container spacing={3}>
                                    <Grid item md={6} style={{ color: "green" }}>
                                        <ValueDisplay type="scheduled" value={`${validateWaterConsumption(scheduleData|| 0)} Kl`} />
                                        <span style={{ fontSize: 12 }}>  Schedule</span>
                                    </Grid>
                                    <Grid item md={6} style={{ color: "red" }}>
                                        <ValueDisplay value={`${unScheduledWaterConsumption(scheduleData)} Kl`} />
                                        <span style={{ fontSize: 12 }}>  Un-Schedule</span>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item md={6} textAlign={"center"}>
                            <div>
                                <div style={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "space-around" }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <CompassCalibrationIcon style={{ color: "green" }} />
                                        <Typography variant="h6" alignItems="center">&nbsp;Avg. Water pressure</Typography>
                                    </div>
                                </div>
                                {/* <BootstrapTooltip title="Add"> */}
                                <span>Single ward: N/A <br />
                                    Multiward: WIP
                                </span>
                                {/* <Button disabled>NA</Button> */}
                                {/* </BootstrapTooltip> */}
                                <Grid container spacing={3}>
                                    <Grid item md={6} style={{ color: "green" }}>
                                        <ValueDisplay type="scheduled" value={"--:-- Hrs"} />
                                        <span style={{ fontSize: 12 }}>  Schedule</span>
                                    </Grid>
                                    <Grid item md={6} style={{ color: "red" }}>
                                        <ValueDisplay value={"--:-- Hrs"} />
                                        <span style={{ fontSize: 12 }}>  Un-Schedule</span>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>

                </Box>
            </Card>
        </Grid>
    </Fragment>
}

export default FunctionalityUtilisation