function OnIndicator(){
    return <svg viewBox="59.084 71.876 130.858 31.591" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="clip4">
          <rect x="2041" y="1070" width="428" height="302"/>
        </clipPath>
      </defs>
      <g clip-path="url(#clip4)" transform="matrix(1, 0, 0, 1, -2003.7684326171875, -1027.557373046875)">
        <path d="M 2085.456 1095.862 C 2085.456 1090.551 2090.239 1086.244 2096.136 1086.244 C 2102.034 1086.244 2106.817 1090.551 2106.817 1095.862 L 2106.817 1135.232 C 2106.817 1140.542 2102.034 1144.848 2096.136 1144.848 C 2090.239 1144.848 2085.456 1140.542 2085.456 1135.232 L 2085.456 1095.862 Z" fill="#00B050" fill-rule="evenodd" style={{transformBox: 'fill-box', transformOrigin: '50% 50%'}} transform="matrix(0, 1, -1, 0, 0.000002, 0.000002)"/>
        <text style={{fill: 'rgb(0, 176, 80)', fontFamily:'Arial, sans-serif', fontSize: '19px', fontWeight: 700, whiteSpace: 'pre'}} x="2133.57" y="1122.15">On</text>
      </g>
    </svg>
}

export default OnIndicator