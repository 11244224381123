import moment from "moment-timezone";

function currentDateTime(getWhat = "both") {
  const date = new Date()
  if (getWhat == "both")
    return date.toLocaleString()
  else if (getWhat == "date") {
    return date.toLocaleDateString()
  }
}

function convertToDate(datetime) {
  //datetime = datetime.replace(/-/g, "/")
  if (datetime) {
    var date = new Date(datetime);
  } else {
    var date = new Date();
  }
  var opDate = date.getTime() / 1000;
  return opDate;
}

function convertToFilterDate(datetime, setToEnd = false) {
  //datetime = datetime.replace(/-/g, "/")
  var date = new Date();
  if (datetime) {
    date = new Date(datetime);
    if (setToEnd) { //to set starting of next day
      date.setDate(date.getDate() + 1)
    }
  }

  date.setHours(5, 30, 0, 0)
  var opDate = date.getTime() / 1000;
  return opDate;
}

function getDiffInMin(from, to) {
  return Math.abs(to - from) / 60
}

function getDateTime(unixts) {
  let lrtime = moment.unix(unixts).format("h:mm:ss a")
  let lrdate = moment.unix(unixts).format("DD/MM/YYYY")
  return { date: lrdate, time: lrtime }
}

function convertNumToTime(number) {
  try {
    // Check sign of given number
    var sign = (number >= 0) ? 1 : -1;

    // Set positive value of number of sign negative
    number = number * sign;

    // Separate the int from the decimal part
    var hour = Math.floor(number);
    var decpart = number - hour;

    var min = 1 / 60;
    // Round to nearest minute
    decpart = min * Math.round(decpart / min);

    var minute = Math.floor(decpart * 60) + '';

    // Add padding if need
    if (minute.length < 2) {
      minute = '0' + minute;
    }
  
    if (hour < 10) {
      hour = '0' + hour;
    }

    // Add Sign in final result
    sign = sign == 1 ? '' : '-';

    // Concate hours and minutes
    const time = sign + hour + ':' + minute;
    return time;
  } catch (E) {
    return "00:00"
  }
}

function historyDate(lastXdays = 5, type) {
  if (type != "currentDay") {
    var cdate = new Date();
    cdate.setHours(5, 30, 0, 0)
    const cTS = cdate.getTime()

    const lastDate = new Date()
    lastDate.setDate(lastDate.getDate() - lastXdays)
    // if(lastXdays == 0){
    lastDate.setHours(5, 30, 0, 0)
    // }
    const lastXDayTS = lastDate.getTime()

    return [lastXDayTS / 1000, cTS / 1000];
  } else {
    var cdate = new Date();
    const cTS = cdate.getTime()

    const lastDate = new Date()
    lastDate.setDate(lastDate.getDate() - lastXdays)
    // if(lastXdays == 0){
    lastDate.setHours(5, 30, 0, 0)
    // }
    const lastXDayTS = lastDate.getTime()

    return [lastXDayTS / 1000, cTS / 1000];
  }
}

function checkIfRoleExist(userRoleString, allowedRoles) {
  let userRoles = userRoleString.split(",")
  return userRoles.some((e) => allowedRoles.includes(e))
}

function getSGMStrength(v) {
  if (v < 2) {
    return "No Signal";
  } else if (v >= 2 && v < 10) {
    return "Poor";
  } else if (v >= 10 && v < 15) {
    return "Ok";
  } else if (v >= 15 && v < 20) {
    return "Good";
  } else {
    return "Excellent";
  }
}

function getSGMStrengthNumber(v) {
  if (v < 2) {
    return 0;
  } else if (v >= 2 && v < 10) {
    return 1;
  } else if (v >= 10 && v < 15) {
    return 2;
  } else if (v >= 15 && v < 20) {
    return 3;
  } else {
    return 4;
  }
}

function getSGMStrengthColor(v) {
  if (v < 2) {
    return "red";
  } else if (v >= 2 && v < 10) {
    return "red";
  } else if (v >= 10 && v < 15) {
    return "orange";
  } else if (v >= 15 && v < 20) {
    return "#E5F6DF";
  } else {
    return "green";
  }
}

function getDeviceDataFlag(d) {
  let f = {
    102: "Power On",
    114: "Motor On",
    105: "Motor Off",
    101: "Device Tamper",
    100: "Device HB",
    103: "Device HB",
    108: "Device HB",
    107: "Device HB",
    106: "Device HB",
    110: "Device HB",
    113: "Device HB",
    118: "Device HB",
    117: "Device HB",
    116: "Device HB",
    112: "Power On",
    111: "Device Tamper",
  };

  return f[`${d.p}${d.l}${d.m}`];
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getInstallationDate(wardData) {
  if (wardData && wardData.doi) {
    const datetime = getDateTime(wardData.doi)
    return `${datetime["date"]} ${datetime["time"]}`
  }
  if (wardData && wardData.installation_data && wardData.installation_data.date) {
    return wardData.installation_data.date
  } else {
    const datetime = getDateTime(wardData._ts)
    return `${datetime["date"]} ${datetime["time"]}`
  }
}

export {
  convertToDate,
  historyDate,
  getDiffInMin,
  getDateTime,
  convertToFilterDate,
  convertNumToTime,
  currentDateTime,
  checkIfRoleExist,
  getSGMStrength,
  getDeviceDataFlag,
  capitalizeFirstLetter,
  getInstallationDate,
  getSGMStrengthNumber,
  getSGMStrengthColor
}