import { createSlice } from '@reduxjs/toolkit';
import { API } from '../config';
import { filepost, get, post } from './service';

const initialState = {
    uploadWardImage: {
        status: null
    },
    wardImages: {
        data: [],
        state: null
    },
    wardGIS: {
        data: [],
        state: null
    }
};

const slice = createSlice({
    name: 'uploadWardImage',
    initialState,
    reducers: {
        setUploadWardImage(state, action) {
            state.uploadWardImage = action.payload
        },
        resetStatus: (state, action) => {
            state.uploadWardImage = { status: null }
            state.wardImages = {
                data: [], status: null
            }
            state.wardGIS = {
                data: [], status: null
            }
        },
        setWardImages(state, action) {
            state.wardImages = action.payload
        },
        setWardGIS(state, action) {
            state.wardGIS = action.payload
        }
    }
});

export const { reducer } = slice;

export const uploadWardImages = (file, smsid, name) => async (dispatch) => {
    dispatch(slice.actions.setUploadWardImage({ ...initialState.uploadWardImage, status: "loading" }));

    filepost(API.base_url + "phed/ward_image_upload", { uifile: file, smsid, name })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setUploadWardImage(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setUploadWardImage({ ...errResponse, status: "failed" }))
        });
};

export const uploadWardGIS = (file, smsid) => async (dispatch) => {
    dispatch(slice.actions.setUploadWardImage({ ...initialState.uploadWardImage, status: "loading" }));

    filepost(API.base_url + "phed/ward_gis_upload", { uifile: file, smsid })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setUploadWardImage(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setUploadWardImage({ ...errResponse, status: "failed" }))
        });
};

export const getWardImages = (smsid) => async (dispatch) => {
    dispatch(slice.actions.setWardImages({ ...initialState.wardImages, status: "loading" }));

    get(API.base_url + "phed/ward_images", { smsid })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setWardImages(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setWardImages({ ...errResponse, status: "failed" }))
        });
};

export const getWardGIS = (smsid) => async (dispatch) => {
    dispatch(slice.actions.setWardGIS({ ...initialState.wardGIS, status: "loading" }));

    get(API.base_url + "phed/ward_gis", { smsid })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setWardGIS(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setWardGIS({ ...errResponse, status: "failed" }))
        });
};

export const uploadWardPDF = (file, smsid) => async (dispatch) => {
    dispatch(slice.actions.setUploadWardImage({ ...initialState.uploadWardImage, status: "loading" }));

    filepost(API.base_url + "phed/ward_pdf_upload", { uifile: file, smsid })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setUploadWardImage(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setUploadWardImage({ ...errResponse, status: "failed" }))
        });
};

export const resetUploadImageStatus = (file, dist) => async (dispatch) => {
    dispatch(slice.actions.resetStatus());
}


export default slice;