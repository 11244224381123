import { Box, Container } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import { useLocation } from "react-router";
import PageHeading from "../../../../components/PageHeading";
import {
  INSTALLATION_REPORT_OPTIONS,
  InstallationStatusColor,
  priColor,
  title,
} from "../../../../constants";
import useSettings from "../../../../hooks/useSettings";
import { useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import {
  getDeviceLastData,
} from "../../FirmwareTestSteps/_service";
import {
  capitalizeFirstLetter,
  getDateTime,
  getInstallationDate,
  getSGMStrength,
} from "../../../../utils/helper";
import _ from "lodash";
import { DialogPopup } from "../../../../components/ImageViewer";
import SubmitControl from "./SubmitControl";
import { API } from "../../../../config";
import SimpleImageSlider from "react-simple-image-slider";

function getInstallationStatus(wardData) {
  if (wardData && wardData.installation_data) {
    if (wardData.installation_data.rejected_by) {
      return `Rejected with Remark: ${wardData.installation_data.remark}`;
    }
    if (wardData && wardData.verification && wardData.verification.status) {
      return capitalizeFirstLetter(wardData.verification.status);
    } else {
      return "Pending For Approval";
    }
  } else {
    return "Yet to install !!";
  }
}

function TestItem({ data }) {
  const status = data.toLowerCase().includes("pass");
  return (
    <span
      style={{
        marginLeft: 8,
        fontWeight: "bold",
        color: status ? "green" : "red",
      }}
    >
      {data}
    </span>
  );
}

function OtherDetailsItem(props) {
  let status = _.get(props.wardData, `survey_data.${props.item}.status`, false)

  if (props.item == "distribution_line") {
    if (status) {
      status = "Ok"
    } else {
      status = "Not Ok"
    }
  } else {
    if (status) {
      status = "Working"
    } else {
      status = "Not Working"
    }
  }

  return (
    <Grid item md={12} style={{ display: "flex", flexDirection: "row" }}>
      <Typography color="textSecondary" variant="subtitle2">
        {props.title}
      </Typography>
      <span
        style={{
          marginLeft: 8,
          fontWeight: "bold",
          color: status == "Working" || status == "Ok" ? "green" : "red",
        }}
      >
        {status}
      </span>
    </Grid>
  );
}

const getLastTelemetryData = (data) => {
  const token = window.localStorage.getItem("accessToken");
  const options = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  };
  return new Promise((resolve, reject) => {
    fetch(API.base_url + `phed/debugging/get-one-telementry-prev-to-time`, options)
      .then((rawData) => rawData.json())
      .then(function (response) {
        response = response || {};
        resolve(response);
      })
      .catch(function (errResponse) {
        reject(new Error("Internal server error"));
      });
  });
};

function InstallationReportDeviceInfo() {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [currentUrlIndex, setCurrentUrlIndex] = useState(null);
  const [currentSurveyUrlIndex, setCurrentSurveyUrlIndex] = useState(null);
  const [surveyImages, setSurveyImages] = useState([]);
  const [deviceImages, setDeviceImages] = useState([]);
  const settings = useSettings();
  const [assignmentType, setAssignementType] = useState("");
  const [wardData, setWardData] = useState({});
  const [gsmStrength, setGSMStrength] = useState("");
  const [heartBeatTime, setHeartBeatTime] = useState("");

  useEffect(() => {
    const assignmentType = location.state.assignmentType
      ? location.state.assignmentType
      : "-";
    setWardData(location.state.wardData);
    setAssignementType(assignmentType);

    if (location.state.wardData && location.state.wardData.smsid) {
      getDeviceLastData(location.state.wardData.smsid).then((e) => {
        if (e.data && e.data.g) setGSMStrength(getSGMStrength(e.data.g));
      });

      //   dispatch(getAllDeviceTelemetry(location.state.wardData.smsid, '', 4320))
    }
    const wdt = location.state.wardData;
    let simages = [];
    let device_images = [];
    if (wdt && wdt.survey_data) {
      if (wdt.survey_data.anurakshak) {
        simages.push({
          title: "Anurakshak Photo",
          url: wdt.survey_data.anurakshak.photo,
        });
      }

      if (wdt.survey_data.siteDetails) {
        simages.push({
          title: "Site Image",
          url: wdt.survey_data.siteDetails.photo,
        });
      }

      if (wdt.survey_data.borewell && wdt.survey_data.borewell.borewell_photo) {
        simages.push({
          title: "Borewell",
          url: wdt.survey_data.borewell.borewell_photo,
        });
      }

      if (
        wdt.survey_data.borewell &&
        wdt.survey_data.borewell.borewell_motor_photo
      ) {
        simages.push({
          title: "Borewell Motor",
          url: wdt.survey_data.borewell.borewell_motor_photo,
        });
      }

      if (
        wdt.survey_data.electricity_meter &&
        wdt.survey_data.electricity_meter.photo
      ) {
        simages.push({
          title: "Electricity Meter",
          url: wdt.survey_data.electricity_meter.photo,
        });
      }

      if (wdt.survey_data.starter && wdt.survey_data.starter.photo) {
        simages.push({
          title: "Starter",
          url: wdt.survey_data.starter.photo,
        });
      }

      if (
        wdt.survey_data.distribution_line &&
        wdt.survey_data.distribution_line.photo
      ) {
        simages.push({
          title: "Distribution Line",
          url: wdt.survey_data.distribution_line.photo,
        });
      }

      if (wdt.deviceImage) {
        device_images.push({
          title: "New Device Image",
          url: wdt.deviceImage,
        });
      }
    }
    setSurveyImages(simages);
    setDeviceImages(device_images)
  }, [location.state]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "left",
    height: "100%",
    color: theme.palette.text.secondary,
  }));

  useEffect(() => {
    if (wardData.regData?.deviceId) {
      const idate = getInstallationDate(wardData)
      const dt = (new Date(idate)).toLocaleString('en-GB')

      getLastTelemetryData({
        deviceid: wardData.regData?.deviceId,
        timestamp: new Date(dt).getTime() / 1000
      }).then((e) => {
        if (e && e._ts) {
          setGSMStrength(getSGMStrength(e.g));
          let datetime = getDateTime(e._ts)
          setHeartBeatTime(`${datetime.date} ${datetime.time}`)
        }
      })
    }
  }, [wardData.regData?.deviceId])

  const instReport = _.get(wardData, "survey_data.installation_remark", "-")
  const instReportObj = INSTALLATION_REPORT_OPTIONS.find((e) => e.value == instReport)
  // if (wardData && wardData.regData) datTime = getDateTime(wardData.regData._ts);
  return (
    <>
      <Helmet>
        <title>{title.label2} | Installation Device Details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <PageHeading
            title={"Installation"}
            prevPath={[
              { name: " Installation Report" },
              { name: "Scheme Installation Approval" },
            ]}
          />
          <Box sx={{ py: 1 }}>
            <Grid container spacing={1}>
              <Grid item md={1}>
                <Typography color="textPrimary" variant="subtitle2">
                  Assignment Type:
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography
                  style={{ fontWeight: "500", color: "#777" }}
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {assignmentType}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ py: 1 }}>
            <Grid container spacing={1}>
              <Grid item md={5}>
                <Item>
                  <Grid
                    item
                    md={12}
                    display="flex"
                    flexDirection={"row"}
                    justifyContent="center"
                    marginBottom={5}
                  >
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      style={{ color: priColor }}
                    >
                      Site Survey
                    </Typography>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item md={6}>
                      <Typography color={priColor} variant="subtitle2">
                        Site Details
                      </Typography>

                      <Box sx={{ py: 2 }}>
                        <Grid container>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              {" "}
                              District:{" "}
                            </Typography>{" "}
                            <span style={{ marginLeft: 8 }}>
                              {wardData.districtName}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Division:{" "}
                            </Typography>{" "}
                            <span style={{ marginLeft: 8 }}>
                              {wardData.division}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Block:{" "}
                            </Typography>{" "}
                            <span style={{ marginLeft: 8 }}>
                              {" "}
                              {wardData.blockName}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Panchayat:{" "}
                            </Typography>{" "}
                            <span style={{ marginLeft: 8 }}>
                              {wardData.panchayatName}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Ward:{" "}
                            </Typography>{" "}
                            <span style={{ marginLeft: 8 }}>
                              {" "}
                              {wardData.name}
                            </span>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item md={6} borderLeft={"1px solid #CCC"}>
                      <Typography color={priColor} variant="subtitle2">
                        Device Details
                      </Typography>
                      <Box sx={{ py: 2 }}>
                        <Grid container>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Device Barcode:
                            </Typography>
                            <span style={{ marginLeft: 8 }}>
                              {wardData.regData ? wardData.regData.barcode : ""}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Date of Installation:
                            </Typography>
                            <span style={{ marginLeft: 8 }}>
                              {getInstallationDate(wardData)}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              SIM Number:
                            </Typography>
                            <span style={{ marginLeft: 8 }}>
                              {wardData.regData
                                ? wardData.regData.simNumber
                                : ""}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Carrier Name:
                            </Typography>
                            <span style={{ marginLeft: 8 }}>
                              {wardData.regData
                                ? wardData.regData.carrierName
                                : ""}
                            </span>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item md={6} borderTop={"1px solid #CCC"}>
                      <Typography color={priColor} variant="subtitle2">
                        TE Details
                      </Typography>
                      <Box sx={{ py: 2 }}>
                        <Grid container>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Name:
                            </Typography>
                            <span style={{ marginLeft: 8 }}>
                              {wardData.installation_user_mapping
                                ? wardData.installation_user_mapping.name
                                : ""}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Mobile Number:
                            </Typography>
                            <span style={{ marginLeft: 8 }}>
                              {wardData.installation_user_mapping
                                ? wardData.installation_user_mapping
                                  .mobile_number
                                : "Not Mapped"}
                            </span>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      borderLeft={"1px solid #CCC"}
                      borderTop="1px solid #CCC"
                    >
                      <Typography color={priColor} variant="subtitle2">
                        Anurakshak Details
                      </Typography>
                      <Box sx={{ py: 2 }}>
                        <Grid container>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Name:
                            </Typography>
                            <span style={{ marginLeft: 8 }}>
                              {_.has(wardData, "survey_data.anurakshak.name")
                                ? wardData.survey_data.anurakshak.name
                                : ""}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Mobile Number:
                            </Typography>
                            <span style={{ marginLeft: 8 }}>
                              {_.has(
                                wardData,
                                "survey_data.anurakshak.mobile_number"
                              )
                                ? wardData.survey_data.anurakshak.mobile_number
                                : ""}
                            </span>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item md={12} style={{ borderTop: "1px solid #CCC" }}>
                      <Typography color={priColor} variant="subtitle2">
                        Other Details
                      </Typography>
                      <Box sx={{ py: 2 }}>
                        <Grid container>
                          <OtherDetailsItem
                            title="Borewell/Motor Status:"
                            wardData={wardData}
                            item={"borewell"}
                          />
                          <OtherDetailsItem
                            title="Electricity Meter:"
                            wardData={wardData}
                            item={"electricity_meter"}
                          />
                          <OtherDetailsItem
                            title="Distribution line:"
                            wardData={wardData}
                            item={"distribution_line"}
                          />
                          <OtherDetailsItem
                            title="Starter Status:"
                            wardData={wardData}
                            item={"starter"}
                          />
                          <OtherDetailsItem
                            title="Starter Status:"
                            wardData={wardData}
                            item={"starter"}
                          />
                          <Grid item md={12} style={{ display: "flex", flexDirection: "row" }}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Installation remark: {instReportObj ? instReportObj.label: "-"}
                            </Typography></Grid>
                          <Grid item md={12} style={{ display: "flex", flexDirection: "row" }}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Reason: {_.get(wardData, "survey_data.extra_input", "-")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    display="flex"
                    flexDirection={"row"}
                    justifyContent="center"
                    style={{ borderTop: "1px solid #CCC" }}
                  >
                    <Typography color={priColor} variant="subtitle2">
                      Testing
                    </Typography>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item md={12}>
                      <Box sx={{ py: 2 }}>
                        <Grid container>
                          {/* <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Device Barcode:
                            </Typography>
                            <span style={{ marginLeft: 8 }}>
                              {_.has(wardData, "regData.barcode")
                                ? wardData.regData.barcode
                                : ""}
                            </span>
                          </Grid> */}
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Power On Test:
                            </Typography>
                            <TestItem
                              data={
                                _.has(
                                  wardData,
                                  "regData.installation_testing_status.power_status"
                                )
                                  ? wardData.regData.installation_testing_status
                                    .power_status
                                  : "Not Found"
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Network Status:
                            </Typography>{" "}
                            <span style={{ marginLeft: 8 }}>
                              {" "}
                              {gsmStrength} (last received)
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Device Heartbeat Details:
                            </Typography>{" "}
                            <span style={{ marginLeft: 1 }}>
                              {/* {_.has(
                                wardData,
                                "regData.installation_testing_status.hb_datetime"
                              )
                                ? wardData.regData.installation_testing_status
                                    .hb_datetime
                                : "Not Found"} */}
                              {heartBeatTime}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Motor On Test:
                            </Typography>{" "}
                            <TestItem
                              data={
                                _.has(
                                  wardData,
                                  "regData.installation_testing_status.motor_on_status"
                                )
                                  ? wardData.regData.installation_testing_status
                                    .motor_on_status
                                  : "Not Found"
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            md={12}
                            marginTop={3}
                            marginBottom={3}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography color={priColor} variant="h5">
                              Test result:
                            </Typography>
                            <Typography
                              color={
                                _.has(
                                  wardData,
                                  "regData.installation_testing_status.overall"
                                )
                                  ? wardData.regData.installation_testing_status
                                    .overall == "pass"
                                    ? "green"
                                    : "red"
                                  : ""
                              }
                              variant="h5"
                              marginLeft={3}
                            >
                              {_.has(
                                wardData,
                                "regData.installation_testing_status.overall"
                              )
                                ? wardData.regData.installation_testing_status.overall.toUpperCase()
                                : "Not Found"}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Status:
                            </Typography>{" "}
                            <span
                              style={{
                                marginLeft: 8,
                                fontWeight: "bold",
                                color: InstallationStatusColor(
                                  getInstallationStatus(wardData)
                                ),
                              }}
                            >
                              {getInstallationStatus(wardData)}
                            </span>
                            {getInstallationStatus(wardData) == "Approved" && (
                              <>
                                {wardData.verification &&
                                  wardData.verification.report_url ? (
                                  <a
                                    href={wardData.verification.report_url}
                                    target="_blank"
                                    style={{ marginLeft: 20 }}
                                  >
                                    View Report
                                  </a>
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>

              <Grid item md={7}>
                <Item>
                  <Grid
                    item
                    md={12}
                    display="flex"
                    flexDirection={"row"}
                    marginBottom={2}
                  >
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      style={{ color: priColor }}
                    >
                      Site Survey Photos
                    </Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    {surveyImages.map((e, i) => {
                      return (
                        <Grid item key={i} md={2}>
                          <img
                            width={"100%"}
                            src={e.url}
                            onClick={() => {
                              setCurrentSurveyUrlIndex(i);

                            }}
                          />
                          <span>{e.title}</span>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid
                    item
                    md={12}
                    marginBottom={2}
                    marginTop={2}
                  >
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      style={{ color: priColor }}
                    >
                      Device Installation & Testing
                    </Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    {deviceImages.map((e, i) => {
                      return (
                        <Grid item key={i} md={2}>
                          <img
                            width={"100%"}
                            src={e.url}
                            onClick={() => {
                              setCurrentUrlIndex(i);
                            }}
                          />
                          <span>{e.title}</span>
                        </Grid>
                      );
                    })}
                  </Grid>
                  {getInstallationStatus(wardData) ==
                    "Pending For Approval" && (
                      <SubmitControl wardData={wardData} />
                    )}
                </Item>
              </Grid>
            </Grid>
          </Box>
        </Container>

        <DialogPopup
          open={currentUrlIndex !== null}
          handleClose={() => { setCurrentUrlIndex(null) }}
        >
          <SimpleImageSlider
            width={500}
            height={300}
            startIndex={currentUrlIndex}
            images={deviceImages}
            showBullets={true}
            showNavs={true}
          />
        </DialogPopup>

        <DialogPopup open={currentSurveyUrlIndex !== null} handleClose={() => { setCurrentSurveyUrlIndex(null) }}>
          <SimpleImageSlider
            width={500}
            height={300}
            startIndex={currentSurveyUrlIndex}
            images={surveyImages}
            showBullets={true}
            showNavs={true}
          />
        </DialogPopup>
      </Box>
    </>
  );
}

export default InstallationReportDeviceInfo;
