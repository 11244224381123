import {
    Box,
    Grid,
    Typography,
    Container,
    Breadcrumbs,
    Link,
    Button
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import ChevronRightIcon from '../../icons/ChevronRight';
import { useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import useSettings from '../../hooks/useSettings';
import { LocationFilter } from '../../components/dashboard/shemes';
import { getSchemes, resetSchemes, setFilters } from '../../slices/siteInfo';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../components/Loader/Loader';
import { resetOpData } from '../../slices/operationalData';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { historyDate } from '../../utils/helper';
import { downloadReportReducer, resetdownloadData } from '../../slices/downloadReport';

const Reports = (props) => {
    const { settings } = useSettings();
    const [schemeLocation, setSchemeLocation] = useState({})
    const dispatch = useDispatch();
    const { district, schemes, lastSearchFilter } = useSelector((state) => state.siteinfo);
    const { reportDownloadData: reportReqObj } = useSelector((state) => state.downloadReport);
    const [distName, setDistName] = useState("")
    const [lastPowerOn, setLastPowerOn] = useState([])
    const [allSchemes, setAllSchemes] = useState([])
    const [enableDownload, setEnableDownload] = useState(false)

    const onChangeLocation = (data) => {
        const keys = Object.keys(data)
        let allReset = true
        for (let i = 0; i < keys.length; i++) {
            allReset = allReset && !data[keys[i]]
        }

        if (allReset) {
            setEnableDownload(false)
            setAllSchemes([])
            dispatch(resetSchemes())
            dispatch(resetOpData())
        }
        setSchemeLocation(data)
    }

    useEffect(() => {
        //console.log("lastSearchFilter", lastSearchFilter)
        setEnableDownload(true)
    }, [lastSearchFilter])

    useEffect(() => {
        if (reportReqObj && reportReqObj["status"] == "SUCCESS") {
            const anchor = document.createElement('a');
            anchor.href = reportReqObj["data"];
            anchor.target = "_blank"
            anchor.download = reportReqObj["data"];
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            dispatch(resetdownloadData())
        }
    }, [reportReqObj])

    useEffect(() => {
        if (parseInt(schemeLocation.district)) {
            if (district["data"] && district["data"].length > 0) {
                const d = district["data"].filter((e) => e.id == schemeLocation.district)
                if (d.length) {
                    setDistName(d[0]["name"])
                }
            }
        } else {
            setDistName("")
        }
    }, [schemeLocation.district])

    useEffect(() => {
        if (schemeLocation.district || schemeLocation.block || schemeLocation.panchayat || schemeLocation.ward) {
            let d = {}
            if (schemeLocation.district) {
                d["district"] = schemeLocation.district
            }
            if (schemeLocation.block) {
                d["block"] = schemeLocation.block
            }
            if (schemeLocation.panchayat) {
                d["panchayat"] = schemeLocation.panchayat
            }
            if (schemeLocation.ward) {
                d["ward"] = schemeLocation.ward
            }
            dispatch(setFilters(schemeLocation))
            dispatch(getSchemes(d))
        }
    }, [schemeLocation])

    useEffect(() => {
        setAllSchemes(schemes["data"])
    }, [schemes])

    const downloadReportFile = (type) => {
        if (lastSearchFilter && lastSearchFilter["district"]) {
            dispatch(downloadReportReducer(lastSearchFilter["district"], historyDate(3, "currentDay"), type))

        } else {
            alert("Something is wrong!!")
        }
    }

    return (
        <>
            <Helmet>
                <title></title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 2
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Download Reports
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/overview"
                                    variant="subtitle2"
                                >
                                    Home
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Reports
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Box sx={{ py: 3 }}>
                        <LocationFilter onChange={onChangeLocation} initialValues={lastSearchFilter} schemes={allSchemes} downloadExcel={false} showDistrictOnly={true}/>
                    </Box>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xl={12}
                            md={12}
                            xs={12}
                        >
                            <Button
                                onClick={() => {
                                    downloadReportFile("nonfunctional")
                                }}
                                disabled={!enableDownload}
                                color="primary"
                                size="large"
                                sx={{ mr: 3 }}
                                variant="contained"
                            >
                                <CloudDownloadIcon style={{ marginRight: 10 }} />
                                Download Non Functional Report
                            </Button>
                            <Button
                                onClick={() => {
                                    downloadReportFile("poorlyfunctional")
                                }}
                                disabled={!enableDownload}
                                color="primary"
                                size="large"
                                sx={{ mr: 3 }}
                                variant="contained"
                            >
                                <CloudDownloadIcon style={{ marginRight: 10 }} />
                                Download Poorly Functional Report
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            {(reportReqObj && reportReqObj["status"] == "loading") && <Loader />}
        </>
    );
};

export default Reports;
