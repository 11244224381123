import {
  Button,
  Card,
  Typography
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLastPowerOn } from '../../../slices/lastPowerOn';
import moment from 'moment-timezone';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getDateTime, getDiffInMin, getSGMStrengthNumber, historyDate } from '../../../utils/helper';
import {
  DataGrid, GridToolbarContainer,
  GridToolbarExport
} from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/styles';
import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar';
import SignalCellularConnectedNoInternet0BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet0Bar';
import { getLastMotorOn } from '../../../slices/lastMotorOn';
import IOSSwitch from '../../IOSSwitch';
import GSMSignal from '../../GSMSignal';
import OnIndicator from '../../StatusIndicator/OnIndicator';
import OffIndicator from '../../StatusIndicator/OffIndicator';
import { get } from 'lodash';

const useStyles = makeStyles({
  root: {
    '& .scheme-data-table': {
      fontWeight: 'bold'
    },
  },
});

function CustomToolbar(props) {
  let cdate = moment(new Date()).format("DD_MM_YYYY_HH_mm_ss")
  return (
    <GridToolbarContainer style={{ padding: 10, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
      <Typography
        color="textPrimary"
        variant="h6"
      >
        Scheme details
      </Typography>
      <GridToolbarExport csvOptions={{ allColumns: true, fileName: `${props.fileName}_${props.locationName}_${cdate}` }} />
    </GridToolbarContainer>
  );
}

const SchemeList = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { lastPowerOn: lastPowerOn } = useSelector((state) => state.lastPowerOn); 
  const classes = useStyles();

  const [lastPowerOnData, setLastPoweronData] = useState([])
  const [tableData, setTableData] = useState([])
  const handleClick = (data) => {
    navigate('siteinfo', { state: { data } })
  }

  const fetchDeviceStatus = (type) =>{
    const deviceids = props.schemes.map((e) => { if (e.smsid) return e.smsid })
    const clearDids = deviceids.filter(e => e)

    dispatch(getLastPowerOn(clearDids, type))
    dispatch(getLastMotorOn(clearDids, historyDate(20, "currentDay"), type))
  }

  useEffect(() => {
    if (props.schemes) {
      fetchDeviceStatus()

      const callRefreshApi = setInterval(() => {
        fetchDeviceStatus("refresh")
      }, 8000)
      
      return () => {
        clearInterval(callRefreshApi)
      }
    }
  }, [])

  // const getRandomTime = () => {
  //   const dt = new Date()
  //   let max = (dt.getTime()/1000) - 144000
  //   let min = (dt.getTime()/1000) - 165600 
  //   return Math.random() * (max - min) + min;
  // }

  useEffect(() => {
    let tableDataTemp = []
    if (props.schemes && props.schemes.length) {
      let lastPowerOnData = []
      if (lastPowerOn["data"] && lastPowerOn["data"].length) {
        lastPowerOnData = lastPowerOn["data"]
      }
      // const dr = historyDate(0, "currentDay")
      props.schemes.map((page, i) => {
        if (props.showWardsIds && props.showWardsIds.length) {
          if (!props.showWardsIds.includes(page["id"])) {
            return
          }
        }

        let motorOn = false
        let powerOn = false

        let dateTime = { time: "-", date: "-" }
        let lpo = null
        let network = 0
        if (lastPowerOnData) {
          const lastDataReceivedTsArr = lastPowerOnData.filter((e) => e.id == page.smsid)
          
          if (lastDataReceivedTsArr && lastDataReceivedTsArr.length) {
            let ldr = lastDataReceivedTsArr[0]
            let lastDataReceivedTs = ldr["_ts"]
            if (ldr["manualGeneratedTS"])
              lastDataReceivedTs = ldr["manualGeneratedTS"]
            lpo = lastDataReceivedTs
            dateTime = getDateTime(lastDataReceivedTs)

            let t1 = new Date()
            let diff = getDiffInMin(lastDataReceivedTs, t1.getTime() / 1000)
            if (ldr.current >= 2) { //Motor flag
              if (diff <= 17) {
                motorOn = true
              }
            }

            if (diff <= 17) {
              powerOn = true
            }

            if (diff <= 1440) {
              network = Number(lastDataReceivedTsArr[0]["gsm"])?lastDataReceivedTsArr[0]["gsm"]:1
            }
          }
        }
        
        tableDataTemp.push({
          lpo,
          ...page,
          ...page.other,
          nicId: page.nicdata ? page.nicdata.NICDeviceId : '',
          did: page.other.deviceid,
          wname: page.name, powerOn, motorOn,
          datastamp: dateTime, action: {...page, network},
          network,
          id: i + 1,
          op_name: get(page, "survey_data.anurakshak.name", "-"),
          op_number: get(page, "survey_data.anurakshak.mobile_number", "-"),
          lat: get(page, "survey_data.location.coords.latitude", "-"),
          lng: get(page, "survey_data.location.coords.longitude", "-"),
        })
      })
    }

    // console.log(">>", opData[""])
    // const workingWardCount = tableDataTemp.filter(e => e.lpo)
    
    // if(opData.data && opData.data.functional){
    //   let diffCount = opData.data.functional - workingWardCount.length
    //   if(diffCount > 0){
    //     tableDataTemp = tableDataTemp.map((e) => {
    //       if(e.lpo === null && diffCount > 0){
    //         const _tsTime = getRandomTime()
    //         let dttm = getDateTime(_tsTime)
    //         diffCount--
    //         return { ...e, lpo: _tsTime, datastamp: {...dttm, time: dttm.time.replace(" ", "_")},  network: true}
    //       } else {
    //         return e
    //       }
    //     })
    //   }  
    // }

    // tableDataTemp.sort((a,b) =>  b.lpo - a.lpo)
    tableDataTemp = tableDataTemp.map((e, i) => {
      return { ...e, id:i+1}
    })
    
    setTableData(tableDataTemp)
    setLastPoweronData(lastPowerOn["data"])
    props.onChangeLastPowerOn(lastPowerOn["data"])
  }, [lastPowerOn, props.showWardsIds])

  const columns = [
    { field: 'id', headerName: 'Sl No', width: 130, sortable: false, headerClassName: 'scheme-data-table' },
    {
      field: 'blockName',
      headerName: 'Block', width: 130, sortable: true
    },
    {
      field: 'panchayatName',
      headerName: 'Panchayat', width: 130, sortable: true
    },
    {
      field: 'wname',
      headerName: 'Ward', width: 130, sortable: true
    },
    {
      field: 'subScheme',
      headerName: 'Scheme Type', width: 130, sortable: false
    },
    {
      field: 'schemeId',
      headerName: 'Scheme Name', width: 130, sortable: false
    },
    {
      field: 'powerOn',
      headerName: 'Electricity', width: 130, sortable: false,
      renderCell: (params) => {
        const data = params["formattedValue"]
        if(data){
          return <OnIndicator />
        }
         else {
          return <OffIndicator />
         }
      }
    },
    {
      field: 'motorOn',
      headerName: 'Motor', width: 130, sortable: false,
      renderCell: (params) => {
        const data = params["formattedValue"]
        if(data){
          return <OnIndicator />
        }
         else {
          return <OffIndicator />
         }
      }
    },
    {
      field: 'network',
      headerName: 'Network', width: 130, sortable: false,
      renderCell: (params) => {
        const data = params["formattedValue"]
        return <GSMSignal strength={getSGMStrengthNumber(data)}/>
        // return data ? <SignalCellular4BarIcon style={{ color: data ? "green" : "red" }} /> : <SignalCellularConnectedNoInternet0BarIcon style={{ color: "red" }} />
      }
    },
    {
      field: "datastamp",
      sortable: false,
      headerName: "Data Stamp",
      width: 180,
      valueFormatter: ({ value }) => value["date"] + " " + value["time"],
      renderCell: (params) => {
        const data = params["formattedValue"];
        const dataToDisplay = data.split(/(?<=^\S+)\s/);
        
        return (
          <div style={{ lineHeight: 1 }}>
            <span style={{ fontSize: 10 }}>{dataToDisplay[0]}</span> <br />
            <span>{dataToDisplay[1]}</span>
          </div>
        );
      }
    },
    {
      field: 'action',
      sortable: false,
      disableExport: true,
      width: 130,
      headerName: 'Action', width: 130, sortable: false,
      renderCell: (params) => {
        return <Button onClick={() => handleClick(params["formattedValue"])}>View Details</Button>
      }
    },
    {
      headerName: 'Anurakshak Name',
      field: "op_name",
      hide: true
    },
    {
      headerName: 'Anurakshak Number',
      field: "op_number",
      hide: true
    },
    {
      headerName: 'Device Id',
      field: "did",
      hide: true
    },
    {
      headerName: 'SMS ID',
      field: "smsid",
      hide: true
    },
    {
      headerName: 'NICID',
      field: "nicId",
      hide: true
    },
    {
      headerName: 'FHTC',
      field: "fhtc",
      hide: true
    },
    {
      headerName: 'LPS',
      field: "lps",
      hide: true
    },
    {
      headerName: 'LAT',
      field: "lat",
      hide: true
    },
    {
      headerName: 'LNG',
      field: "lng",
      hide: true
    },
    {
      headerName: 'Motor Capacity',
      field: "motorCapacity",
      hide: true
    },
    {
      headerName: 'Household',
      field: "household",
      hide: true
    }
  ];

  return (
    <Card>
      <div style={{ height: 800, width: '100%' }} className={classes.root}>
        <DataGrid
          rows={tableData}
          columns={columns}
          // sortModel={sortModel}
          pageSize={40}
          rowsPerPageOptions={[5]}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{ toolbar: { fileName: props.fileName, locationName: props.distName } }}
        />
      </div>

      {/* {lastPowerOn["status"] == "loading" ? <Loader /> : null} */}
    </Card>
  )
}

export default SchemeList;
