import { Box, Button, ButtonGroup } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getSchemes, resetSchemes, setFilters, updatePlaceData } from "../../../slices/siteInfo";
import { LocationFilter } from "../../../components/dashboard/shemes";

import { resetOpData } from "../../../slices/operationalData";
import { historyDate } from "../../../utils/helper";
import { addBlock, addPanchayat } from "../../../slices/manageMasterData";
const menus = ["Block", "Panchayat"]

function ModifyMaster(props) {

    const [menuId, setMenuId] = useState(0)
    const [schemeLocation, setSchemeLocation] = useState({});
    const dispatch = useDispatch();
    const { addBlock: { status: addBlockStatus, data: addBlockData }, addPanchayat: { status: addPanchayatStatus, data: addPanchayatData } } = useSelector((state) => state.manageMasterData);

    const { district, schemes, lastSearchFilter, block, panchayat, updatePanchayat: { status: updatePanchayatStatus } } = useSelector((state) => state.siteinfo);
    const [allSchemes, setAllSchemes] = useState([]);

    const [blockData, setBlockData] = useState([])
    const [panchayatData, setPanchayatData] = useState([])

    const [changedBlockData, setChangedBlockData] = useState([])
    const [changedPanchayatData, setChangedPanchayatData] = useState([])

    const onChangeLocation = (data) => {
        const keys = Object.keys(data);
        let allReset = true;
        for (let i = 0; i < keys.length; i++) {
            allReset = allReset && !data[keys[i]];
        }

        if (allReset) {
            setAllSchemes([]);
            dispatch(resetSchemes());
            dispatch(resetOpData());
        }
        setSchemeLocation(data);
    };

    useEffect(() => {
        setBlockData(block.data || [])
    }, [block]);

    useEffect(() => {
        setPanchayatData(panchayat.data || [])
    }, [panchayat]);

    const onBlockNameChange = (id, name) => {
        if (name !== "") {
            let cbdTemp = [...changedBlockData]
            let getDataForId = cbdTemp.filter(e => e.id == id)
            if (getDataForId.length) {
                cbdTemp = cbdTemp.map(cbdTempEle => {
                    if (cbdTempEle["id"] == id)
                        return { id, name }
                    else
                        return cbdTempEle
                })
                setChangedBlockData(cbdTemp)
            } else {
                cbdTemp.push({ id, name })
                setChangedBlockData(cbdTemp)
            }
        } else {
            let newCbd = changedBlockData.filter(e => e.id != id)
            setChangedBlockData(newCbd)
        }
    }

    const onPanchayatNameChange = (id, name) => {
        if (name !== "") {
            let cbdTemp = [...changedPanchayatData]
            let getDataForId = cbdTemp.filter(e => e.id == id)
            if (getDataForId.length) {
                cbdTemp = cbdTemp.map(cbdTempEle => {
                    if (cbdTempEle["id"] == id)
                        return { id, name }
                    else
                        return cbdTempEle
                })
                setChangedPanchayatData(cbdTemp)
            } else {
                cbdTemp.push({ id, name })
                setChangedPanchayatData(cbdTemp)
            }
        } else {
            let newCbd = changedPanchayatData.filter(e => e.id != id)
            setChangedPanchayatData(newCbd)
        }
    }

    const saveChangedData = (type) => {
        if (type == "blocks")
            if (changedBlockData.length) {
                dispatch(updatePlaceData(type, changedBlockData))
            }

        if (type == "panchayats")
            if (changedPanchayatData.length) {
                dispatch(updatePlaceData(type, changedPanchayatData))
            }
    }

    useEffect(() => {
        setChangedBlockData([])
        setChangedPanchayatData([])
    }, [updatePanchayatStatus])

    const addBlockClicked = () => {
        const newBlockName = window.prompt("Enter new block name to be added:")
        if (newBlockName && newBlockName !== "") {
            let blockExist = false
            for (let i = 0; i < blockData.length; i++) {
                if (blockData[i]["name"] == newBlockName) {
                    blockExist = true
                }
            }
            
            if (blockExist) {
                alert("Block name already used")
            } else {
                if (schemeLocation["district"] && schemeLocation["division"]) {
                    dispatch(addBlock(newBlockName, schemeLocation["district"], schemeLocation["division"]))
                }
            }
        }
    }

    const addPanchayatClicked = () => {
        const newPanchayatName = window.prompt("Enter new panchayat name to be added:")
        if (newPanchayatName && newPanchayatName !== "") {
            let panchayatExist = false

            for (let i = 0; i < panchayatData.length; i++) {
                if (panchayatData[i]["name"] == newPanchayatName) {
                    panchayatExist = true
                }
            }

            if (panchayatExist) {
                alert("Panchayat name already used")
            } else {
                if (schemeLocation["district"] && schemeLocation["division"] && schemeLocation["block"]) {
                    dispatch(addPanchayat(newPanchayatName, schemeLocation["district"], schemeLocation["division"], schemeLocation["block"]))
                }
            }
        }
    }

    useEffect(() => {
        if (addBlockStatus == "SUCCESS") {
            alert("Block added successfully. Please refresh")
        }
    }, [addBlockStatus, addBlockData])

    useEffect(() => {
        if (addPanchayatStatus == "SUCCESS") {
            alert("Panchayat added successfully. Please refresh")
        }
    }, [addPanchayatStatus, addPanchayatData])

    return <div>
        Modify master
        <Box
            sx={{
                mt: 3
            }}
            width="100%"
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                }}
            >
                <ButtonGroup aria-label="small outlined button group">
                    {menus.map((m, i) => {
                        return <Button
                            key={i}
                            color="primary"
                            variant={menuId == i ? "contained" : ""}
                            onClick={() => i > menus.length ? null : setMenuId(i)}
                        >
                            {m}
                        </Button>
                    })}
                </ButtonGroup>
            </div>
        </Box>
        {menuId == 0 && <>
            <Box sx={{ py: 3 }}>
                <LocationFilter
                    onChange={onChangeLocation}
                    initialValues={lastSearchFilter}
                    schemes={allSchemes}
                    downloadExcel={true}
                    removeOptions={["blocks", "panchayats", "wards"]}
                />
                {blockData.length ?
                    <button onClick={addBlockClicked}>Add Block</button> : ""}
            </Box>
            <Box>
                <table>
                    <tr>
                        <th>Current Name</th>
                        <th>Edit</th>
                    </tr>
                    {blockData.map(blockElement => {
                        let changedName = changedBlockData.filter(e1 => e1.id == blockElement["id"])
                        let defaultValue = ""
                        if (changedName.length) {
                            defaultValue = changedName[0]["name"]
                        }
                        return <tr>
                            <td>
                                {blockElement["name"]} </td><td><input type="text" value={defaultValue} onChange={(e) => onBlockNameChange(blockElement["id"], e.target.value)} />
                            </td>
                        </tr>
                    })}
                </table>

                {changedBlockData.length ? <button onClick={() => saveChangedData("blocks")}>Submit</button> : ""}
            </Box>
        </>}

        {menuId == 1 && <>
            <Box sx={{ py: 3 }}>
                <LocationFilter
                    onChange={onChangeLocation}
                    initialValues={lastSearchFilter}
                    schemes={allSchemes}
                    downloadExcel={true}
                    removeOptions={["panchayats", "wards"]}
                />
                {panchayatData.length ?
                    <button onClick={addPanchayatClicked}>Add Panchayat</button> : ""}
            </Box>
            <Box>
                <table>
                    <tr>
                        <th>Current Name</th>
                        <th>Edit</th>
                    </tr>
                    {panchayatData.map(panchElement => {
                        let changedName = changedPanchayatData.filter(e1 => e1.id == panchElement["id"])
                        let defaultValue = ""
                        if (changedName.length) {
                            defaultValue = changedName[0]["name"]
                        }
                        return <tr>
                            <td>
                                {panchElement["name"]} </td><td><input type="text" value={defaultValue} onChange={(e) => onPanchayatNameChange(panchElement["id"], e.target.value)} />
                            </td>
                        </tr>
                    })}

                </table>
                {changedPanchayatData.length ? <button onClick={() => saveChangedData("panchayats")}>Submit</button> : ""}
            </Box>
        </>}
    </div>
}

export default ModifyMaster