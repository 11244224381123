import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Typography,
  Link,
  TableHead,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ChevronRightIcon from "../../icons/ChevronRight";
import { Helmet } from "react-helmet-async";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useDispatch, useSelector } from "react-redux";
import { getRegisteredDevices } from "../../slices/firmwareRegistration";
import { getDateTime } from "../../utils/helper";
import { DataGrid } from "@mui/x-data-grid";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// function createData(name, calories, fat) {
//   return { name, calories, fat };
// }

// const rows = [
//   createData("Cupcake", 305, 3.7),
//   createData("Donut", 452, 25.0),
//   createData("Eclair", 262, 16.0),
//   createData("Frozen yoghurt", 159, 6.0),
//   createData("Gingerbread", 356, 16.0),
//   createData("Honeycomb", 408, 3.2),
//   createData("Ice cream sandwich", 237, 9.0),
//   createData("Jelly Bean", 375, 0.0),
//   createData("KitKat", 518, 26.0),
//   createData("Lollipop", 392, 0.2),
//   createData("Marshmallow", 318, 0),
//   createData("Nougat", 360, 19.0),
//   createData("Oreo", 437, 18.0),
// ].sort((a, b) => (a.calories < b.calories ? -1 : 1));

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

export default function RegisteredDeviceList() {
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRegDeviceData] = React.useState([]);
  const { firmwareData: firmwareDataApiResponse } = useSelector(
    (state) => state.firmwareRegistration
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRegisteredDevices());
  }, []);

  useEffect(() => {
    if (firmwareDataApiResponse && firmwareDataApiResponse.data) {
      const rws = firmwareDataApiResponse.data.map((e, i) => {
        const dt = getDateTime(e._ts);
        return {
          slno: i + 1,
          ...e,
          created_datetime: `${dt["date"]} ${dt["time"]}`,
        };
      });
      setRegDeviceData(rws);
      if (rws.length < rowsPerPage) {
        setRowsPerPage(rws.length + 1);
      }
    }
  }, [firmwareDataApiResponse]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    {
      field: "slno",
      headerName: "Sl No",
      width: 130,
      sortable: false,
      headerClassName: "scheme-data-table",
    },
    {
      field: "barcode",
      headerName: "Barcode",
      width: 130,
      sortable: false,
    },
    {
      field: "imei",
      headerName: "IMEI",
      width: 160,
      sortable: false,
    },
    {
      field: "deviceId",
      headerName: "Software Device Id",
      width: 130,
      sortable: false,
    },
    {
      field: "simNumber",
      headerName: "SIM Number",
      width: 190,
      sortable: false,
    },
    {
      field: "carrierName",
      headerName: "Carrier Name",
      width: 130,
      sortable: false,
    },

    {
      field: "user_name",
      headerName: "User Name",
      width: 180,
      sortable: false,
    },
    {
      field: "mobile_number",
      headerName: "Mobile Number",
      width: 130,
      sortable: false,
    },
    {
      field: "created_datetime",
      headerName: "Date Time",
      width: 180,
      sortable: false,
    },
    {
      field: "certificate_path",
      headerName: "Certificate",
      width: 130,
      sortable: false,
      renderCell: (params) => {
        const data = params["formattedValue"];
        return (
            data?
          <Link target="_blank" href={data}>
            Download
          </Link>: "Not Found"
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Maintenance | Har Ghar Nal Jal Yojna</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={"xl"}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Registered Device List
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/overview"
                  variant="subtitle2"
                >
                  Home
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Firmware Loading
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ py: 3 }}>
            <div style={{ height:650, width: "100%" }}>
              <DataGrid rows={rows} columns={columns} pageSize={10} />
            </div>
            {/* <TableContainer component={Paper}>
              <Table
                className={classes.table}
                aria-label="custom pagination table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Sl No</TableCell>
                    <TableCell>Barcode</TableCell>
                    <TableCell>IMEI</TableCell>
                    <TableCell>Software Device Id</TableCell>
                    <TableCell>SIM Number</TableCell>
                    <TableCell>Carrier Name</TableCell>
                    <TableCell>User Name</TableCell>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Certificate</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? rows.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : rows
                  ).map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.slno}</TableCell>
                      <TableCell>{row.barcode}</TableCell>
                      <TableCell>{row.imei}</TableCell>
                      <TableCell>{row.deviceId}</TableCell>
                      <TableCell>{row.simNumber}</TableCell>
                      <TableCell>{row.carrierName}</TableCell>
                      <TableCell>{row.user_name}</TableCell>
                      <TableCell>{row.mobile_number}</TableCell>
                      <TableCell>{ row.created_datetime}</TableCell>{" "}
                      <TableCell>{row.certificate_path?<Link target="_blank" href={row.certificate_path}>Download</Link>:"Not Found"}</TableCell>
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  {rows.length > rowsPerPage ? (
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: "All", value: -1 },
                        ]}
                        colSpan={9}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "rows per page" },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  ) : (
                    ""
                  )}
                </TableFooter>
              </Table>
            </TableContainer> */}
          </Box>
        </Container>
      </Box>
    </>
  );
}
