import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

const DialogUI = (props) => {
  return (
    <Dialog open={props.open} fullWidth={true}>
      <DialogTitle id="scroll-dialog-title">{props.image["title"]}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="scroll-dialog-description"
          // ref={descriptionElementRef}
          tabIndex={-1}
        >
          <img width={"100%"} src={props.image["imgURL"]} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};


const DialogPopup = (props) => {
  return (
    <Dialog open={props.open} fullWidth={true}>
      <DialogContent>
        <DialogContentText
          id="scroll-dialog-description"
          // ref={descriptionElementRef}
          tabIndex={-1}
        >
        {props.children}  
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Close</Button>
        {props.handleConfirm && <Button onClick={props.handleConfirm}>Confirm</Button>}
      </DialogActions>
    </Dialog>
  );
};

export default DialogUI;
export { DialogPopup }