import React from 'react'

function GSMSignal({ strength }) {

    let color = ["grey", "grey", "grey", "grey", "grey"]
    
    if(strength == 1){
        color[0] = "red"
    }
    if(strength == 2){
        color[0] = "yellow"
        color[1] = "yellow"
    }
    if(strength == 3){
        color[0] = "green"
        color[1] = "green"
        color[2] = "green"
    }
    if(strength == 4){
        color[0] = "green"
        color[1] = "green"
        color[2] = "green"
        color[3] = "green"
    }
    return <svg viewBox="26.801 19.492 900 300" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip4)" transform="matrix(1, 0, 0, 1, -2001.9410400390625, -1026.9482421875)">
            <path d="M 2047.941 1308.456 C 2047.941 1291.058 2062.271 1276.948 2079.941 1276.948 C 2097.611 1276.948 2111.941 1291.058 2111.941 1308.456 L 2111.941 1309.44 C 2111.941 1326.838 2097.611 1340.948 2079.941 1340.948 C 2062.271 1340.948 2047.941 1326.838 2047.941 1309.44 L 2047.941 1308.456 Z" fill={color[0]} fill-rule="evenodd" />
            <path d="M 2138.966 1244.062 C 2138.966 1226.664 2153.296 1212.554 2170.966 1212.554 C 2188.635 1212.554 2202.966 1226.664 2202.966 1244.062 L 2202.966 1309.047 C 2202.966 1326.444 2188.635 1340.554 2170.966 1340.554 C 2153.296 1340.554 2138.966 1326.444 2138.966 1309.047 L 2138.966 1244.062 Z" fill={color[1]} fill-rule="evenodd" />
            <path d="M 2231.948 1179.578 C 2231.948 1162.18 2246.278 1148.07 2263.948 1148.07 C 2281.618 1148.07 2295.948 1162.18 2295.948 1179.578 L 2295.948 1308.562 C 2295.948 1325.96 2281.618 1340.07 2263.948 1340.07 C 2246.278 1340.07 2231.948 1325.96 2231.948 1308.562 L 2231.948 1179.578 Z" fill={color[2]} fill-rule="evenodd" />
            <path d="M 2336.186 1115.884 L 2336.186 1308.868 C 2336.186 1326.266 2350.516 1340.376 2368.186 1340.376 C 2385.856 1340.376 2400.186 1326.266 2400.186 1308.868 L 2400.186 1115.884 C 2400.186 1098.486 2385.856 1084.376 2368.186 1084.376 C 2350.516 1084.376 2336.186 1098.486 2336.186 1115.884 Z" fill={color[3]} fill-rule="evenodd" style={{ strokeLinecap: 'round' }} />
        </g>
        <text style={{
            fill: 'rgb(255, 0, 0)', opacity:strength==0?1:0, fontFamily: 'Arial, sans-serif', fontSize: '103px', fontWeight: 700, letterSpacing: '0.3px', strokeWidth: '11px',
            textTransform: 'uppercase'
        }} x="60.361" y="141.793">x</text>

    </svg>
}

export default GSMSignal