import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { genearteDeviceInstallationReport, getDeviceLastData } from "../FirmwareTestSteps/_service";
import { getAllDeviceTelemetry } from "../../../slices/debugging";
import { getDateTime, getDeviceDataFlag, getSGMStrength } from "../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { has } from "lodash";
import { Loader } from "../../../components/Loader/Loader";

function DeviceDataDump(props) {
    const { allDeviceTelemetry: allDeviceData } = useSelector((state) => state.debugging);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()

    useEffect(() => {
        if (has(props, 'wardData.fw_device_id', null)) {
            if (props.wardData.fw_device_id)
                dispatch(getAllDeviceTelemetry(props.wardData.fw_device_id, '', 4320))
        }
    }, [props.wardData]);

    return <>
        <Box sx={{ py: 2 }}>
            <Grid container direction={"row"} justifyContent={"space-between"}>
                <Grid item md={3}>
                    <Typography style={{ color: "#5664d2", fontWeight: "bold" }}>
                        Three Days Device Data
                    </Typography>
                </Grid>

                <Grid item md={2}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setLoading(true);
                            if (props.wardData && props.assignmentType) {
                                genearteDeviceInstallationReport({
                                    ...props.wardData,
                                    assignmentType: props.assignmentType,
                                }).then((e) => {
                                    setLoading(false);
                                    if (e.statusCode == 200) {
                                        if (e.data && e.data.filePath) {
                                            var link = document.createElement("a");
                                            link.href = e.data.filePath;
                                            link.target = "_blank";
                                            link.download = e.data.filename;
                                            link.dispatchEvent(new MouseEvent("click"));
                                        }
                                    }
                                });
                            }
                        }}
                    >
                        Download Report
                    </Button>
                </Grid>
            </Grid>
        </Box>
        {allDeviceData &&
            allDeviceData.data &&
            allDeviceData.data.length > 0 ? (
            <Box sx={{ py: 2, maxHeight: 300, overflow: "scroll" }}>
                <table width={"50%"}>
                    <tr>
                        <th>Sl No</th>
                        <th>Time Stamp (DD/MM/YYYY HH:MM:SS) </th>
                        <th>Event</th>
                    </tr>
                    {allDeviceData.data.map((e, i) => {
                        const datetime = getDateTime(e._ts);
                        return (
                            <tr>
                                <td style={{ textAlign: "center" }}>
                                    <Typography color="textSecondary" variant="subtitle2">
                                        {i + 1}
                                    </Typography>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                    <Typography
                                        color="textSecondary"
                                        variant="subtitle2"
                                    >{`${datetime.date} ${datetime.time}`}</Typography>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                    <Typography color="textSecondary" variant="subtitle2">
                                        {getDeviceDataFlag(e)}
                                    </Typography>
                                </td>
                            </tr>
                        );
                    })}
                </table>
            </Box>
        ) : (
            allDeviceData.status != "loading" && (
                <span style={{ color: "red" }}>
                    Device data not found for last Three Days
                </span>
            )
        )}
        {((allDeviceData.status && allDeviceData.status == "loading") || loading) && (
            <Loader />
        )}
    </>
}

export default DeviceDataDump