import { createSlice } from '@reduxjs/toolkit';
import { API } from '../config';
import { get, post } from './service';

const initialState = {
    lastPowerOn: {
        status: null,
        data: null
    }
};

const slice = createSlice({
    name: 'lastPowerOn',
    initialState,
    reducers: {
        setLastPowerOn(state, action) {
            state.lastPowerOn = action.payload
        }
    }
});

export const { reducer } = slice;

export const getLastPowerOn = (did, type) => async (dispatch) => {
    if(type && type != "refresh")
        dispatch(slice.actions.setLastPowerOn({ ...initialState.lastPowerOn, status: "loading" }));

    post(API.base_url + "phed/device_last_power_on", { did })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setLastPowerOn(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setLastPowerOn({ ...errResponse, status: "failed" }))
        });
};

export default slice;