import { createSlice } from '@reduxjs/toolkit';
import { API } from '../config';
import { get } from './service';

const initialState = {
    deviceStats: {
        status: null,
        data: null
    }
};

const slice = createSlice({
    name: 'deviceStats',
    initialState,
    reducers: {
        setDeviceStats(state, action) {
            state.deviceStats = action.payload
        }
    }
});

export const { reducer } = slice;
export const resetDeviceStats = () => async (dispatch) => {
    dispatch(slice.actions.setDeviceStats({ ...initialState.deviceStats }));
}

export const getDeviceStats = (place, dateRange) => async (dispatch) => {
    dispatch(slice.actions.setDeviceStats({ ...initialState.deviceStats, status: "loading" }));

    get(API.base_url + "phed/homepage_device_stats", { ...place, from: dateRange[0], to: dateRange[1] })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setDeviceStats(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setDeviceStats({ ...errResponse, status: "failed" }))
        });
};

export default slice;