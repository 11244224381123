import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import {
  Box,
  Card,
  CardContent,
  Button,
  Grid,
  Link,
  Typography,
  TextField,
} from "@material-ui/core";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "react-datepicker/dist/react-datepicker.css";
import AuthBanner from "../../components/authentication/AuthBanner";
import useAuth from "../../hooks/useAuth";
import {
  POWERED_BY,
  title,
} from "../../constants";
import { useState } from "react";
import { Formik } from "formik";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { generateOTP, verifyOtpAndResetPassword, verifyOtpAndUpdate } from "../../slices/userManagement";
import { useNavigate } from "react-router";

const ResetPassword = () => {
  const [respMessage, setRespMessage] = useState({
    type: null,
    message: "",
    fields: [],
  });
  const dispatch = useDispatch()

  const { logout } = useAuth();
  const navigate = useNavigate()
  const { resetPasswordResponse } = useSelector((state) => state.userManagement);
  const { generateOTPResponse } = useSelector((state) => state.userManagement);
  const [genOtpStatus, setGenOtpStatus] = useState({
    type: null,
    message: "",
    fields: [],
  })
  const [resendOtpState, setResetOtpState] = useState(null)

  useEffect(() => {
    let message = "",
      type = null,
      fields = [];
    if (generateOTPResponse) {
      if (generateOTPResponse.statusCode == 200) {
        setResetOtpState(true)
        setTimeout(() => {
          setResetOtpState(false)
        }, 60000)
        message = generateOTPResponse.message + ".";
        type = "success";
      } else if (generateOTPResponse.statusCode == 400) {
        message = generateOTPResponse.message;
        type = "error";
        if (generateOTPResponse.errors && generateOTPResponse.errors.length)
          fields = generateOTPResponse.errors.map((re) => {
            return <li>{re.msg}</li>;
          });
      } else {
        message = generateOTPResponse.message;
        type = "error";
      }
    }
    setGenOtpStatus({ type, message, fields })
  }, [generateOTPResponse]);

  useEffect(() => {
    let message = "",
      type = null,
      fields = [];

    if (resetPasswordResponse) {
      if (resetPasswordResponse.statusCode == 200) {
        message = resetPasswordResponse.message + ". Redirecting to login page in 4 seconds.";
        type = "success";
        // setCanLogin(true);
        setTimeout(async () => {
          await logout();
          navigate("/")
        }, 5000);
      } else if (resetPasswordResponse.statusCode == 400) {
        message = resetPasswordResponse.message;
        type = "error";
        if (resetPasswordResponse.errors && resetPasswordResponse.errors.length)
          fields = resetPasswordResponse.errors.map((re) => {
            return <li>{re.msg}</li>;
          });
      } else {
        message = resetPasswordResponse.message;
        type = "error";
      }
    }

    setRespMessage({ type, message, fields });
  }, [resetPasswordResponse]);
  const [mobileNumber, setMobileNumber] = useState("");

  return (
    <>
      <Helmet>
        <title>{title.label2}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AuthBanner />
        <Box
          display="flex"
          flexDirection={"column"}
          justifyContent="center"
          alignItems="center"
          marginTop={5}
        >
          <Card
            sx={{
              width: { xs: 1, sm: 1, md: 1 / 3, lg: 1 / 3, xl: 1 / 3 },
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid
                container
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 0.5,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <Typography variant="h5" component="h2">
                      Reset Password Form
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Formik
                      initialValues={{
                        email: "",
                        mobile_number: "",
                        dob: "",
                      }}
                      validationSchema={Yup.object().shape({
                        email: Yup.string()
                          .email("Must be a valid email")
                          .max(255, "Max 255 length allowed")
                          .required("Email is required"),
                        mobile_number: Yup.string()
                          .required("Mobile number is required")
                          .matches(/^[0-9]{10}$/, "Must be 10 digits"),
                        dob: Yup.date().required("DOB is required"),
                      })}
                      onSubmit={async (
                        values,
                        { setErrors, setStatus, setSubmitting }
                      ) => {
                        let updatedValues = {
                          ...values,
                          dob: dayjs(values.dob).format("DD/MM/YYYY"),
                        };
                        try {
                          // console.log("updatedValues", updatedValues)
                            setMobileNumber(values.mobile_number)
                            dispatch(generateOTP(updatedValues));
                        //   await register(updatedValues);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        touched,
                        values,
                      }) => (
                        <form noValidate onSubmit={handleSubmit}>
                          <Grid container spacing={2}>
                            <Grid item md={6}>
                              <TextField
                                error={Boolean(
                                  touched.mobile_number && errors.mobile_number
                                )}
                                helperText={
                                  touched.mobile_number && errors.mobile_number
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                                label="Mobile Number"
                                margin="normal"
                                name="mobile_number"
                                variant="outlined"
                                value={values.mobile_number}
                              />
                            </Grid>
                            <Grid item md={6}>
                              <TextField
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                                label="Email Id"
                                margin="normal"
                                type="email"
                                name="email"
                                variant="outlined"
                                value={values.email}
                              />
                            </Grid>
                            <Grid item md={6}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  label="Date of Birth"
                                  inputFormat="DD/MM/YYYY"
                                  name="dob"
                                  value={values.dob}
                                  disableFuture
                                  onChange={(value) => {
                                    setFieldValue("dob", value);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      fullWidth
                                      {...params}
                                      error={Boolean(touched.dob && errors.dob)}
                                      helperText={touched.dob && errors.dob}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                          </Grid>
                          <Box
                            sx={{
                              mt: 2,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              size="large"
                              type="submit"
                              disabled={resendOtpState}
                              variant="contained"
                            >
                              {resendOtpState===false?"Res":"S"}end OTP
                            </Button>
                          </Box>
                        </form>
                      )}
                    </Formik>
                    
                    {genOtpStatus.type ? (
                      <div
                        style={{
                          color:
                          genOtpStatus.type == "success" ? "green" : "red",
                        }}
                      >
                        <p>{genOtpStatus.message}</p>
                        <ul>{genOtpStatus.fields}</ul>
                      </div>
                    ) : (
                      ""
                    )}
                    <Formik
                      initialValues={{
                        npassword: "",
                        cpassword: "",
                        otp: "",
                      }}
                      validationSchema={Yup.object().shape({
                        npassword: Yup.string()
                          .required("Password is required")
                          .min(8, "Min 8 charecters required"),
                        cpassword: Yup.string()
                          .oneOf(
                            [Yup.ref("npassword"), null],
                            "Passwords must match"
                          )
                          .required("Confirm password is required"),
                        otp: Yup.string().required("OTP is required"),
                      })}
                      onSubmit={async (
                        values,
                        { setErrors, setStatus, setSubmitting }
                      ) => {
                        try {
                          // console.log("updatedValues", updatedValues)
                          dispatch(verifyOtpAndResetPassword({...values, mobile_number:mobileNumber}));
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        touched,
                        values,
                      }) => (
                        <form noValidate onSubmit={handleSubmit}>
                          <Grid container spacing={1}>
                            <Grid item md={12}>
                              <TextField
                                margin="normal"
                                fullWidth
                                autoComplete={"off"}
                                error={Boolean(touched.otp && errors.otp)}
                                helperText={touched.otp && errors.otp}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="otp"
                                label="Enter OTP reveived to your mobile"
                                value={values.otp}
                              />
                            </Grid>
                            <Grid item md={6}>
                              <TextField
                                error={Boolean(
                                  touched.npassword && errors.npassword
                                )}
                                helperText={
                                  touched.npassword && errors.npassword
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label="New Password:"
                                type="password"
                                name="npassword"
                                margin="normal"
                                fullWidth
                                value={values.npassword}
                              />
                            </Grid>
                            <Grid item md={6}>
                              <TextField
                                error={Boolean(
                                  touched.cpassword && errors.cpassword
                                )}
                                helperText={
                                  touched.cpassword && errors.cpassword
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label="Confirm Password:"
                                type="password"
                                name="cpassword"
                                margin="normal"
                                fullWidth
                                value={values.cpassword}
                              />
                            </Grid>
                          </Grid>
                          <Box
                            sx={{
                              mt: 2,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              size="large"
                              type="submit"
                              variant="contained"
                            >
                              Submit
                            </Button>
                          </Box>
                        </form>
                      )}
                    </Formik>
                    
                    {respMessage.type ? (
                      <div
                        style={{
                          color:
                          respMessage.type == "success" ? "green" : "red",
                        }}
                      >
                        <p>{respMessage.message}</p>
                        <ul>{respMessage.fields}</ul>
                      </div>
                    ) : (
                      ""
                    )}
                    <Grid container marginTop={1} flexDirection="column">
                      <Grid item>
                        Already have login details?{" "}
                        <Link href={"/authentication/signin"}>Login</Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <span>
              Powered by <b>{POWERED_BY} &#174;</b>
            </span>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default ResetPassword;
