import numeral from 'numeral';
import {
    Box,
    Button,
    Card,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@material-ui/core';
// import { DataGrid } from '@mui/x-data-grid';

import ExternalLinkIcon from '../../../icons/ExternalLink';
import InformationCircleIcon from '../../../icons/InformationCircle';
import { useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLastPowerOn } from '../../../slices/lastPowerOn';
import moment from 'moment-timezone';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { getDateTime, getDiffInMin, historyDate } from '../../../utils/helper';
import { Loader } from '../../Loader/Loader';
import {
    DataGrid, GridToolbarContainer,
    GridToolbarExport
} from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/styles';
import { operationalInitialValue } from '../../../constants';
import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar';
import SignalCellularConnectedNoInternet0BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet0Bar';
import { getLastMotorOn } from '../../../slices/lastMotorOn';
import IOSSwitch from '../../IOSSwitch';
import UpdateNewDeviceUI from './UpdateNewDeviceUI';
import { deleteDevice, updateDevice } from '../../../slices/deviceUpdate';

const useStyles = makeStyles({
    root: {
        '& .scheme-data-table': {
            fontWeight: 'bold'
        },
    },
});

function CustomToolbar(props) {
    let cdate = moment(new Date()).format("DD_MM_YYYY_HH_mm_ss")
    return (
        <GridToolbarContainer style={{ padding: 10, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Typography
                color="textPrimary"
                variant="h6"
            >
                Scheme details
            </Typography>
            <GridToolbarExport csvOptions={{ allColumns: true, fileName: `${props.fileName}_${props.locationName}_${cdate}` }} />
        </GridToolbarContainer>
    );
}

const DeviceList = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { lastPowerOn: lastPowerOn } = useSelector((state) => state.lastPowerOn);
    const classes = useStyles();

    const [showUpdateAction, setShowUpdateAction] = useState(false);
    const [currentUpdateDevice, setCurrentUdpateDevice] = useState(null);
    const [lastPowerOnData, setLastPoweronData] = useState([])
    const [tableData, setTableData] = useState([])

    const handleClick = (d) => {
        setCurrentUdpateDevice(d);
        setShowUpdateAction(true);
    };

    const handleDeleteClick = (d) => {
        const confirmRes = window.confirm("Are you sure to delete this?")
        if (confirmRes && d["id"]) {
            // console.log("Delete ", d, confirmRes)
            dispatch(deleteDevice(d["id"], { tag: "delete" }))
        }
    }

    useEffect(() => {
        if (props.schemes) {
            const deviceids = props.schemes.map((e) => { if (e.smsid) return e.smsid })
            const clearDids = deviceids.filter(e => e)
            dispatch(getLastPowerOn(clearDids))
            dispatch(getLastMotorOn(clearDids, historyDate(20, "currentDay")))
        }
    }, [])

    useEffect(() => {

        let tableDataTemp = []
        if (props.schemes && props.schemes.length) {
            let lastPowerOnData = []
            if (lastPowerOn["data"] && lastPowerOn["data"].length) {
                lastPowerOnData = lastPowerOn["data"]
            }
            const dr = historyDate(0, "currentDay")
            props.schemes.map((page, i) => {
                if (props.showWardsIds && props.showWardsIds.length) {
                    if (!props.showWardsIds.includes(page["id"])) {
                        return
                    }
                }

                let motorOn = false
                let powerOn = false

                let dateTime = { time: "-", date: "-" }
                let network = false
                if (lastPowerOnData) {
                    const lastDataReceivedTsArr = lastPowerOnData.filter((e) => e.id == page.smsid)
                    if (lastDataReceivedTsArr && lastDataReceivedTsArr.length) {
                        let ldr = lastDataReceivedTsArr[0]
                        let lastDataReceivedTs = ldr["_ts"]
                        if (ldr["manualGeneratedTS"])
                            lastDataReceivedTs = ldr["manualGeneratedTS"]

                        dateTime = getDateTime(lastDataReceivedTs)

                        let t1 = new Date()
                        let diff = getDiffInMin(lastDataReceivedTs, t1.getTime() / 1000)
                        if (ldr.current >= 2) { //Motor flag
                            if (diff <= 4) {
                                motorOn = true
                            }
                        }

                        if (diff <= 10) {
                            powerOn = true
                        }

                        if (diff <= 1440) {
                            network = true
                        }
                    }
                }

                tableDataTemp.push({
                    ...page,
                    did: page.other.deviceid,
                    // dname: page.districtName,
                    // bname: page.blockName,
                    wname: page.name, powerOn, motorOn,
                    datastamp: dateTime, action: page,
                    network,
                    id: i + 1,
                    op_name: page.other.op_name,
                    op_number: page.other.op_number
                })
            })
        }

        setTableData(tableDataTemp)
        setLastPoweronData(lastPowerOn["data"])
        props.onChangeLastPowerOn(lastPowerOn["data"])
    }, [lastPowerOn, props.showWardsIds])

    const columns = [
        { field: 'id', headerName: 'Sl No', width: 130, sortable: false, headerClassName: 'scheme-data-table' },
        {
            field: 'blockName',
            headerName: 'Block', width: 130, sortable: false
        },
        {
            field: 'panchayatName',
            headerName: 'Panchayat', width: 130, sortable: false
        },
        {
            field: 'wname',
            headerName: 'Ward', width: 130, sortable: false
        },
        {
            field: 'subScheme',
            headerName: 'Scheme Type', width: 130, sortable: false
        },
        {
            field: 'schemeId',
            headerName: 'Scheme Id', width: 130, sortable: false
        },
        // {
        //     field: 'powerOn',
        //     headerName: 'Electricity', width: 130, sortable: false,
        //     renderCell: (params) => {
        //         const data = params["formattedValue"]
        //         return <FormControlLabel
        //             control={<IOSSwitch checked={data} />}
        //             label={data ? "on" : "off"}
        //         />
        //     }
        // },
        // {
        //     field: 'motorOn',
        //     headerName: 'Motor', width: 130, sortable: false,
        //     renderCell: (params) => {
        //         const data = params["formattedValue"]
        //         return <FormControlLabel
        //             control={<IOSSwitch checked={data} />}
        //             label={data ? "on" : "off"}
        //         />
        //     }
        // },
        // {
        //     field: 'network',
        //     headerName: 'Network', width: 130, sortable: false,
        //     renderCell: (params) => {
        //         const data = params["formattedValue"]
        //         return data ? <SignalCellular4BarIcon style={{ color: data ? "green" : "red" }} /> : <SignalCellularConnectedNoInternet0BarIcon style={{ color: "red" }} />
        //     }
        // },
        // {
        //     field: "datastamp",
        //     sortable: false,
        //     headerName: "Data Stamp",
        //     width: 180,
        //     valueFormatter: ({ value }) => value["date"] + " " + value["time"],
        //     renderCell: (params) => {
        //         const data = params["formattedValue"];
        //         const dataToDisplay = data.split(/(?<=^\S+)\s/);

        //         return (
        //             <div style={{ lineHeight: 1 }}>
        //                 <span style={{ fontSize: 10 }}>{dataToDisplay[0]}</span> <br />
        //                 <span>{dataToDisplay[1]}</span>
        //             </div>
        //         );
        //     }
        // },
        {
            field: 'action',
            sortable: false,
            disableExport: true,
            width: 130,
            headerName: 'Action', width: 230, sortable: false,
            renderCell: (params) => {
                return <>
                    <Button onClick={() => handleClick(params["formattedValue"])}>Edit</Button>
                    <Button onClick={() => handleDeleteClick(params["formattedValue"])}>Delete</Button>
                </>
            }
        },
        {
            headerName: 'Anurakshak Name',
            field: "op_name",
            hide: true
        },
        {
            headerName: 'Anurakshak Number',
            field: "op_number",
            hide: true
        },
        {
            headerName: 'Device Id',
            field: "did",
            hide: true
        },
        {
            headerName: 'SMS ID',
            field: "smsid",
            hide: true
        },
        {
            headerName: 'NICID',
            field: "nicId",
            hide: true
        }
    ];

    const onUpdateDevice = (data) => {
        setShowUpdateAction(false)
        dispatch(updateDevice(data))
    };

    return (
        <Card>
            <div style={{ height: 800, width: '100%' }} className={classes.root}>
                <DataGrid
                    rows={tableData}
                    columns={columns}
                    pageSize={40}
                    rowsPerPageOptions={[5]}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{ toolbar: { fileName: props.fileName, locationName: props.distName } }}
                />
            </div>

            <UpdateNewDeviceUI
                update={onUpdateDevice}
                setOpenStatus={(stat) => setShowUpdateAction(stat)}
                openStatus={showUpdateAction}
                deviceDatails={currentUpdateDevice}
            />

            {/* {lastPowerOn["status"] == "loading" ? <Loader /> : null} */}
        </Card>
    )
}

export default DeviceList;
