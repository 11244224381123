import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Chip,
  Container,
  Link,
  Tooltip,
  Typography,
} from "@material-ui/core";
import logo from "../../images/logo.png";

const platformIcons = {
  Amplify: "/static/icons/amplify.svg",
  Auth0: "/static/icons/auth0.svg",
  Firebase: "/static/icons/firebase.svg",
  JWT: "/static/icons/jwt.svg",
};

const AuthBanner = () => (
  <Box
    sx={{
      backgroundColor: "background.paper",
      borderBottom: 1,
      borderColor: "divider",
      py: 2,
    }}
  >
    <Container maxWidth="md">
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <img width={"9%"} src={logo}  style={{
          marginRight:10}}/>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            ml: 2,
            "& > img": {
              height: 30,
              mx: 2,
            },
          }}
        >
          <Typography color="textPrimary" variant="h5">
            HAR GHAR NAL JAL YOJNA
            <br />Public Health Engineering Department
            <br />
            Government of Bihar
          </Typography>
        </Box>
      </Box>
    </Container>
  </Box>
);

export default AuthBanner;
