import React from "react"
import { Box, Typography } from "@mui/material"
import useAuth from "../hooks/useAuth";

function MaintenancePage() {

  const { user, logout } = useAuth();
  
    return <>
        <Box m={4} textAlign={"center"}>
            <Box>
                <Typography variant="h5">{ user ? `Hello ${user.name}` : ""}</Typography>
            </Box>
            <Box>
                <img src="/static/wip.png" width={500} />
            </Box>
            <Typography>
                Resource allocation in progress. Contact admin for more information.
            </Typography>
        </Box>
    </>
}

export default MaintenancePage