import { Box, Container, Card, CardHeader, Grid, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getWardImages } from "../../../../slices/uploadWardFiles";
import DialogUI from "../../../ImageViewer";
import { getInstallationStatus, priColor } from "../../../../constants";
import { get, has } from "lodash";

const SiteInfoImages = (props) => {

    const [open, setOpen] = useState(false)
    const [currentUrl, setCurrentUrl] = useState(null)
    const dispatch = useDispatch();
    const { wardImages: ward_images } = useSelector((state) => state.uploadWardImage);
    // const [wardImageArray, setWardImageArray] = useState([])

    // useEffect(() => {
    //     //console.log(ward_images)
    //     if (ward_images && ward_images["status"] == "SUCCESS" && ward_images["data"].length) {
    //         setWardImageArray(ward_images["data"])
    //     }
    // }, [ward_images])
    // useEffect(() => {
    //     dispatch(getWardImages(props.smsid))
    // }, [props.smsid])

    let wardImageArray = []
    const sureveryData = props.wardData && props.wardData.survey_data ? props.wardData.survey_data : null
    if (sureveryData) {
        wardImageArray = [
            {
                "title": "Starter",
                "imgURL": sureveryData.starter.photo
            },
            {
                "title": "Meter",
                "imgURL": sureveryData.electricity_meter.photo
            },
            {
                "title": "Borewell",
                "imgURL": sureveryData.borewell.borewell_photo
            },
            {
                "title": "Site",
                "imgURL": sureveryData.siteDetails.photo
            },
            {
                "title": "Anurakshak",
                "imgURL": sureveryData.anurakshak.photo
            }
        ]
    }

    if (props.wardData.deviceImage) {
        wardImageArray.push({
            title: "Device Image",
            imgURL: props.wardData.deviceImage
        })
    }

    const status = getInstallationStatus(props.wardData)
    return (
        <Card {...props}>
            <CardHeader title="Scheme Images" />
            <Box paddingBottom={5}>
                <Container>
                    <Grid container spacing={3}>
                        {wardImageArray.length ? wardImageArray.map((i) => {
                            return <Grid item md={2}>
                                <Grid container display={"flex"} flexDirection={"column"}>
                                    <Grid item>
                                        <img height={"100%"} width={100} style={{objectFit: 'cover'}} src={i["imgURL"]} onClick={() => { setOpen(true); setCurrentUrl(i) }} />
                                    </Grid>
                                    <Grid item display={"flex"} justifyContent={"center"}>
                                        <Typography variant="caption">{i["title"]}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }) :
                            <Grid container spacing={3}>
                                <Grid item md={12} ml={3} mt={3}>
                                    No Images Found
                                </Grid>
                            </Grid>}
                    </Grid>
                </Container>
                <Box mt={5}>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems:"center", margin:2}}>
                        <Typography color={priColor} fontWeight={"bold"} marginRight={2}>Installation Status: </Typography>
                        <Typography fontWeight={"bold"} color={status && status.color}  marginRight={2}>{" "}{status && status.text}</Typography>
                        <Button variant="contained" onClick={() => {
                            if (has(props.wardData, 'verification.report_url', null)) {
                                var link = document.createElement("a");
                                link.href = get(props.wardData, 'verification.report_url', '');
                                link.target = "_blank";
                                link.download = get(props.wardData, 'verification.report_url', '');
                                link.dispatchEvent(new MouseEvent("click"));
                            }
                        }}>Download installation report</Button>
                    </Box>
                </Box>
            </Box>
            {currentUrl &&
                <DialogUI handleClose={() => setOpen(false)} open={open} image={currentUrl} />}
        </Card>
    )
}

export default SiteInfoImages