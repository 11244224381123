import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "react-hot-toast";
import { API } from "../config";
import { get, post } from "./service";

const ld = localStorage.getItem("locationData")
let initLocData = {
    district: 1,
    block: null,
    panchayat: null,
    ward: null,
}
if(ld){
    initLocData = JSON.parse(ld)
}

const initialState = {
    district: {
        status: null,
        data: null,
    },
    division: {
        status: null,
        data: null,
    },
    block: {
        status: null,
        data: null,
    },
    panchayat: {
        status: null,
        data: null,
    },
    ward: {
        status: null,
        data: null,
    },
    summary: {
        scheme: "Loading",
        site: "wip",
        block: "Loading",
        panchayat: "Loading",
    },
    schemes: {
        status: null,
        data: [],
    },
    schemeHealth: {
        status: null,
        data: null,
    },
    lastSearchFilter: initLocData,
    updatePanchayat: {
        status: null,
        data: null,
    },
    saveSurveyDetailsData:{
        status: null,
        data: null,
    }
};

const slice = createSlice({
    name: "siteinfo",
    initialState,
    reducers: {
        setDistrict(state, action) {
            state.district = action.payload;
            state.status = action.payload.status;
        },
        setDivision(state, action) {
            state.division = action.payload;
            state.status = action.payload.status;
        },
        setBlock(state, action) {
            state.block = action.payload;
            state.status = action.payload.status;
        },
        setPanchayat(state, action) {
            state.panchayat = action.payload;
            state.status = action.payload.status;
        },
        setWard(state, action) {
            state.ward = action.payload;
            state.status = action.payload.status;
        },
        setSummary(state, action) {
            state.summary = { ...state.summary, ...action.payload };
        },
        setSchemes(state, action) {
            state.schemes = action.payload;
        },
        setSchemeHealth(state, action) {
            state.schemeHealth = action.payload;
        },
        setFilterValues(state, action) {
            state.lastSearchFilter = action.payload;
        },
        setUpdatePanchayat(state, action) {
            state.updatePanchayat = action.payload;
        },
        setSaveSurveydetailsReponse(state, action) {
            state.saveSurveyDetailsData = action.payload;
        }
    },
});

export const { reducer } = slice;

export const getDistrict = () => async (dispatch) => {
    dispatch(slice.actions.setDistrict({ ...initialState.district, status: "loading" }));

    get(API.base_url + "phed/sitedata", { site_type: "districts" })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setDistrict(response));
            // dispatch(slice.actions.setSummary({ district : Array.isArray(response.data)?response.data.length:0 }));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setDistrict({ ...errResponse, status: "failed" }));
        });
};

export const getDivision = (distid) => async (dispatch) => {
    dispatch(slice.actions.setDivision({ ...initialState.division, status: "loading" }));

    get(API.base_url + "phed/sitedata", { site_type: "divisions", ref: "district", id: distid })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setDivision(response));
            // dispatch(slice.actions.setSummary({ district : Array.isArray(response.data)?response.data.length:0 }));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setDivision({ ...errResponse, status: "failed" }));
        });
};

export const getBlock = (distid) => async (dispatch) => {
    dispatch(slice.actions.setBlock({ ...initialState.block, status: "loading" }));
    get(API.base_url + "phed/sitedata", { site_type: "blocks", ref: "division", id: distid })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setBlock(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setBlock({ ...errResponse, status: "failed" }));
        });
};

export const getPanchayat = (id) => async (dispatch) => {
    dispatch(slice.actions.setPanchayat({ ...initialState.panchayat, status: "loading" }));

    get(API.base_url + "phed/sitedata", { site_type: "panchayats", ref: "block", id })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setPanchayat(response));
            // dispatch(slice.actions.setSummary({ panchayat : Array.isArray(response.data)?response.data.length:0 }));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setPanchayat({ ...errResponse, status: "failed" }));
        });
};

export const updatePlaceData = (type, data ) => async (dispatch) => {
    dispatch(slice.actions.setUpdatePanchayat({ ...initialState.updatePanchayat, status: "loading" }));

    post(API.base_url + "phed/updatePlaceData", { type,  data })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setUpdatePanchayat(response));
            // dispatch(slice.actions.setSummary({ panchayat : Array.isArray(response.data)?response.data.length:0 }));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setUpdatePanchayat({ ...errResponse, status: "failed" }));
        });
};

export const getWard = (id) => async (dispatch) => {
    dispatch(slice.actions.setWard({ ...initialState.ward, status: "loading" }));

    get(API.base_url + "phed/sitedata", { site_type: "wards", ref: "panchayat", id })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setWard(response));
            // dispatch(slice.actions.setSummary({ wards : Array.isArray(response.data)?response.data.length:0 }));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setWard({ ...errResponse, status: "failed" }));
        });
};

export const getSummary = (id) => async (dispatch) => {
    dispatch(slice.actions.setSummary({ ...initialState.summary, status: "loading" }));

    get(API.base_url + "phed/summary")
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setSummary(response.data ? response.data : {}));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setSummary({ ...errResponse, status: "failed" }));
        });
};

export const getSchemes = (data, dateRange) => async (dispatch) => {
    dispatch(slice.actions.setSchemes({ ...initialState.schemes, status: "loading" }));
    let dataToSend = { ...data }
    if (dateRange) {
        dataToSend = { ...dataToSend, from: dateRange[0], to: dateRange[1] }
    }
    get(API.base_url + "phed/schemes", { ...dataToSend })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setSchemes(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setSchemes({ ...errResponse, status: "failed" }));
        });
};
export const resetLocations = (id) => async (dispatch) => {
    if (id == "division"){
        dispatch(slice.actions.setDivision({ ...initialState[id] }));
    }
    if (id == "block"){
        dispatch(slice.actions.setBlock({ ...initialState[id] }));
    }
    if (id == "panchayat"){
        dispatch(slice.actions.setPanchayat({ ...initialState[id] }));
    }
    if (id == "ward"){
        dispatch(slice.actions.setWard({ ...initialState[id] }));
    }
}
export const resetSchemes = () => async (dispatch) => {
    dispatch(slice.actions.setSchemes({ ...initialState.schemes }));
};

export const getSchemeHealth = (schemeId, dateRange) => async (dispatch) => {
    dispatch(slice.actions.setSchemeHealth({ ...initialState.scheme_health, status: "loading" }));

    get(API.base_url + "phed/scheme_health", { id: schemeId, from: dateRange[0], to: dateRange[1] })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setSchemeHealth(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setSchemeHealth({ ...errResponse, status: "failed" }));
        });
};

export const setFilters = (data) => async (dispatch) => {
    dispatch(slice.actions.setFilterValues({ ...initialState.lastSearchFilter, ...data }));
};


export const saveSurveyDetails = (surveyData, wardid) => async (dispatch) => {
    dispatch(slice.actions.setSaveSurveydetailsReponse({ ...initialState.saveSurveyDetailsData, status: "loading" }));

    post(API.base_url + "phed/saveSurveyDetails", { surveyData , wardid})
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setSaveSurveydetailsReponse(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setSaveSurveydetailsReponse({ ...errResponse, status: "failed" }))
        });
}

export const resetSaveSurveyDetails = () => async (dispatch) => {
    dispatch(slice.actions.setSaveSurveydetailsReponse({ ...initialState.saveSurveyDetailsData }));
}


export default slice;
