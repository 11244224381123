import { Button, Grid } from "@material-ui/core"
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateNICData } from "../../../slices/deviceUpdate";
import { Loader } from "../../Loader/Loader";

function NicLinking(props) {

    const [calledUpload, setCalledUpload] = useState(false)
    const { nicDataUpdate: { status: nicUpdateStatus} } = useSelector((state) => state.deviceUpdate);

    const dispatch = useDispatch();
    const onChange = function (e) {
        if (!calledUpload) {
            const file = e.target.files[0]
            if (file && (file['type'] == "text/csv" || file["type"] == "application/vnd.ms-excel")) {
                // console.log(e.target.files[0])
                dispatch(updateNICData(e.target.files[0]))
            } else {
                alert("Please select CSV file")
            }
        } else {
            setCalledUpload(true)
        }
    };

    return <div>
        <Grid item md={4}>
            <Button
                variant="contained"
                component="label"
                size="large"
                sx={{ mr: 3 }}>
                Choose CSV File
                <input
                    type="file"
                    onChange={onChange}
                    hidden
                />
            </Button>
            <a href="https://storageaccounthires8e69.blob.core.windows.net/templates/nic_linking_template.csv?sp=r&st=2022-06-19T15:02:59Z&se=2023-06-19T23:02:59Z&spr=https&sv=2021-06-08&sr=b&sig=xspo0BJfs6IOdZZMFU3W2qS%2BSRDsckYl4UEicrSTbBg%3D">Download Template</a>
        </Grid>
        {nicUpdateStatus == "loading" ? <Loader /> : ""}
    </div>
}

export default NicLinking