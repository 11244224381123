import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getDuplicateDeviceIds } from "../../../slices/deviceUpdate";
import { Loader } from "../../Loader/Loader";
import DuplicateDevices from "./DuplicateDevices";
import { deleteDevice } from '../../../slices/deviceUpdate';
import { getDeleteLog, resetDeleteLogDownloadData } from "../../../slices/downloadReport";


function ShowDuplicates(props) {
    const dispatch = useDispatch();
    const {
        duplicateDevices: { data: dupDetails, status: apiStatus },
        deleteDevice: { status: deleteAPIStatus }
    } = useSelector((state) => state.deviceUpdate);
    
    const { downloadDeleteLog: { data: downloadData, status: downloadStatus } } = useSelector(state => state.downloadReport)

    const handleDeleteDevice = (ids) => {
        const conf = window.confirm("Are you sure?")
        if (conf) {
            for (let i = 0; i < ids.length; i++) {
                dispatch(deleteDevice(ids[i], { tag: "duplicate" }))
            }
        }
    }

    useEffect(() => {
        dispatch(getDuplicateDeviceIds())
    }, [])

    useEffect(() => {
        if (deleteAPIStatus == "SUCCESS") {
            dispatch(getDuplicateDeviceIds())
        }

    }, [deleteAPIStatus])

    const downloadDeleteLog = () => {
        dispatch(getDeleteLog())
    }

    useEffect(() => {
        if (downloadStatus == "SUCCESS") {
            var hiddenElement = document.createElement('a');
            hiddenElement.href = downloadData
            hiddenElement.target = '_blank';

            hiddenElement.click();
            dispatch(resetDeleteLogDownloadData())
        }
    }, [downloadStatus])

    return <div>
        <button onClick={() => downloadDeleteLog()}>Download Delete Log</button>
        {dupDetails &&
            <DuplicateDevices
                deleteDevice={handleDeleteDevice}
                dupDevices={dupDetails["duplicateDevices"]}
                dupDevicesDetails={dupDetails["devData"]} />
        }
        {apiStatus == "loading" ? <Loader /> : ""}
    </div>
}

export default ShowDuplicates