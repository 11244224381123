import React, { Fragment } from "react"
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Box, 
    Breadcrumbs, 
    Button, 
    Container, 
    Grid, 
    Link, 
    Typography,
    ButtonGroup
} from '@material-ui/core';
import ChevronRightIcon from '../../../../icons/ChevronRight';
import useSettings from '../../../../hooks/useSettings';
import GISMap from "./GISMap";
import { useDispatch } from "react-redux";
import SiteInfoSection from "./_site_info";
import IOTEventReport from "./_iot_event_report";
import SchemeDashboard from "../../../../pages/dashboard/SchemeDashboard";
import { resetUploadImageStatus } from "../../../../slices/uploadWardFiles";

const menus = ["Site Info", "IOT Event Report", "GIS Map", "IOT Dashboard"]//, "Daily Summary Report", "Monthly Summary Report"]

const SiteInfo = (props) => {
    const { settings } = useSettings();
    const location = useLocation()
    const [menuId, setMenuId] = useState(0)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let schemeData = {}
    if (location.state.data) {
        schemeData = location.state.data
    }

    useEffect(() => {
        dispatch(resetUploadImageStatus())
    }, [])
    // useEffect(() => {
    //     if (lastMotorOn && lastMotorOn["status"] == "SUCCESS" && lastMotorOn["data"].length) {
    //         let ts = lastMotorOn["data"][0]["_ts"]
    //         let dt = getDateTime(ts)
    //         setLastMotorOnData(dt)
    //     }
    // }, [lastMotorOn])

    return <>
        <Helmet>
            <title>Site Information | Har Ghar Nal Jal Yojna</title>
        </Helmet>
        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                py: 3
            }}
        >
            <Container maxWidth={settings.compact ? 'xl' : false}>
                <Grid
                    container
                    justifyContent="space-between"
                    spacing={3}
                >
                    <Grid item
                        width="100%">
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            Site Information
                            {/* <span style={{ color: "red" }}>(WIP)</span> */}
                        </Typography>
                        <Typography
                            color="textSecondary"
                            variant="h6">

                        </Typography>
                        <Breadcrumbs
                            aria-label="breadcrumb"
                            separator={<ChevronRightIcon fontSize="small" />}
                            sx={{ mt: 1 }}
                        >
                            <Link
                                color="textPrimary"
                                component={RouterLink}
                                to="/overview"
                                variant="subtitle2"
                            >
                                Home
                            </Link>
                            <Link
                                color="textPrimary"
                                component={RouterLink}
                                to="/overview/schemes"
                                variant="subtitle2"
                            >
                                Schemes
                            </Link>
                            <Typography
                                color="textSecondary"
                                variant="subtitle2"
                            >
                                Site Information
                            </Typography>
                        </Breadcrumbs>
                        <Box
                            sx={{
                                mt: 3
                            }}
                            width="100%"
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between"
                                }}
                            >
                                <ButtonGroup aria-label="small outlined button group">
                                    {menus.map((m, i) => {
                                        return <Button
                                            color="primary"
                                            variant={menuId == i ? "contained" : ""}
                                            onClick={() => i > 3 ? null : setMenuId(i)}
                                        >
                                            {m}
                                        </Button>
                                    })}
                                </ButtonGroup>
                                <Button onClick={() => { navigate(-1) }}>Back</Button>
                            </div>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 3 }}>
                    {menuId == 0 && <SiteInfoSection schemeData={schemeData} />}
                    {menuId == 1 && <IOTEventReport schemeData={schemeData} />}
                    {menuId == 3 && <SchemeDashboard type={"subComponent"} schemeId={schemeData["id"]} />}
                    {/* {menuId == 4 && <span>IOT 3</span>}
                    {menuId == 5 && <span>IOT4 </span>} */}
                    {menuId == 2 &&  <GISMap smsid={schemeData["smsid"]} />}
                </Box>

            </Container>
        </Box>
    </>
}

export default SiteInfo