import { Box, Grid, Typography, Container, Breadcrumbs, Link, Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ChevronRightIcon from "../../../icons/ChevronRight";
import { useTheme } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { AnalyticsGeneralOverview, SchemeList } from "../../../components/dashboard/schemes";
import useSettings from "../../../hooks/useSettings";
import { LocationFilter } from "../../../components/dashboard/shemes";
import { getSchemes, resetSchemes, setFilters } from "../../../slices/siteInfo";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../components/Loader/Loader";
import { resetOpData } from "../../../slices/operationalData";
import { historyDate } from "../../../utils/helper";
import { getShcemeOperationalData } from "../../../slices/schemesDeviceOperational";
import DeviceList from "./DeviceList";

const Schemes = (props) => {
    const theme = useTheme();
    const { settings } = useSettings();
    const [schemeLocation, setSchemeLocation] = useState({});
    const dispatch = useDispatch();
    const { district, schemes, lastSearchFilter } = useSelector((state) => state.siteinfo);
    const [distName, setDistName] = useState("VAISHALI");
    const [lastPowerOn, setLastPowerOn] = useState([]);
    const [allSchemes, setAllSchemes] = useState([]);
    const [showWardsIds, setShowWardsIds] = useState([]);
    const [schemeExportFileName, setSchemeExportFileName] = useState("All_Schemes");

    // useEffect(() => {
    //     console.log(">>>", schemeExportFileName, distName)
    // }, [schemeExportFileName])

    const onChangeLocation = (data) => {
        const keys = Object.keys(data);
        let allReset = true;
        for (let i = 0; i < keys.length; i++) {
            allReset = allReset && !data[keys[i]];
        }

        if (allReset) {
            setAllSchemes([]);
            dispatch(resetSchemes());
            dispatch(resetOpData());
        }
        setSchemeLocation(data);
    };

    // useEffect(() => {
    //     console.log("lastSearchFilter", lastSearchFilter)
    // }, [lastSearchFilter]);

    useEffect(() => {
        // console.log(">>>", schemeLocation, district)
        if (parseInt(schemeLocation.district)) {
            if (district["data"] && district["data"].length > 0) {
                const d = district["data"].filter((e) => parseInt(e.id) == schemeLocation.district);
                if (d.length) {
                    setDistName(d[0]["name"]);
                }
            }
        } else {
            setDistName("");
        }
    }, [schemeLocation.district, district]);

    useEffect(() => {
        if (
            schemeLocation.district ||
            schemeLocation.block ||
            schemeLocation.panchayat ||
            schemeLocation.ward
        ) {
            let d = {};
            if (schemeLocation.district) {
                d["district"] = schemeLocation.district;
            }
            if (schemeLocation.division) {
                d["division"] = schemeLocation.division;
            }
            if (schemeLocation.block) {
                d["block"] = schemeLocation.block;
            }
            if (schemeLocation.panchayat) {
                d["panchayat"] = schemeLocation.panchayat;
            }
            if (schemeLocation.ward) {
                d["ward"] = schemeLocation.ward;
            }
            dispatch(setFilters(schemeLocation));
            dispatch(getSchemes(d, historyDate(0, "currentDay")));
            // console.log(">>", props.districtId, schemeLocation)
            dispatch(getShcemeOperationalData(d, historyDate(3, "currentDay")))
        }
    }, [schemeLocation]);

    useEffect(() => {
        setAllSchemes(schemes["data"]);
    }, [schemes]);

    return (
        <>
            <Box sx={{ py: 3 }}>
                <LocationFilter
                    onChange={onChangeLocation}
                    initialValues={lastSearchFilter}
                    schemes={allSchemes}
                    downloadExcel={true}
                />
            </Box>
            <Grid container spacing={3}>
                <Grid item xl={12} md={12} xs={12}>
                    {allSchemes && allSchemes.length > 0 ? (
                        <DeviceList
                            sx={{ height: "100%" }}
                            schemes={allSchemes}
                            onChangeLastPowerOn={(d) => setLastPowerOn(d)}
                            showWardsIds={showWardsIds}
                            fileName={schemeExportFileName}
                            distName={distName}
                        />
                    ) : null}
                </Grid>
            </Grid>
            {schemes["status"] == "loading" && <Loader />}
        </>
    );
};

export default Schemes;
