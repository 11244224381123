import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Formik } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { resetUserManagement, updatePassword } from "../../slices/userManagement";

function ChangePasswordView() {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const dispatch = useDispatch();

  const [votpRespMessage, setVotpRespMessage] = useState({
    type: null,
    message: "",
    fields: [],
  });
  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleClickShowCPassword = () => {
    setShowCPassword(!showCPassword);
  };
  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const { updatePasswordRespone } = useSelector(
    (state) => state.userManagement
  );

  useEffect(() => {
    let message = "",
      type = null,
      fields = [];
    if (updatePasswordRespone) {
      if (updatePasswordRespone.statusCode == 200) {
        // dispatch(resetUserManagement())
        message = updatePasswordRespone.message + ".";
        type = "success";
      } else if (updatePasswordRespone.statusCode == 400) {
        message = updatePasswordRespone.message;
        type = "error";
        if (updatePasswordRespone.errors && updatePasswordRespone.errors.length)
          fields = updatePasswordRespone.errors.map((re) => {
            return <li>{re.msg}</li>;
          });
      } else {
        message = updatePasswordRespone.message;
        type = "error";
      }
    }
    setVotpRespMessage({ type, message, fields });
  }, [updatePasswordRespone]);

  return (
    <>
      {votpRespMessage.type ? (
        <div
          style={{
            color: votpRespMessage.type == "success" ? "green" : "red",
          }}
        >
          <p>{votpRespMessage.message}</p>
          <ul>{votpRespMessage.fields}</ul>
        </div>
      ) : (
        ""
      )}
      <Formik
        initialValues={{
          password: "",
          npassword: "",
          cpassword: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .required("Password is required")
            .min(
              8,
              "Use 8 for more charecters with mix of letters, numbers & symbols"
            ),
          npassword: Yup.string()
            .required("New Password is required")
            .min(
              8,
              "Use 8 for more charecters with mix of letters, numbers & symbols"
            ),
          cpassword: Yup.string()
            .oneOf([Yup.ref("npassword"), null], "Passwords must match")
            .required("Confirm password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          let updatedValues = {
            ...values,
          };
          try {
            console.log("updatedValues", updatedValues);
            dispatch(updatePassword(updatedValues));
          } catch (err) {
            console.error(err);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container display="flex" flexDirection={"column"}>
              <Grid item md={4}>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Current Password:"
                  type={showCurrentPassword ? "text" : "password"}
                  name="password"
                  margin="normal"
                  fullWidth
                  value={values.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCurrentPassword}
                          edge="end"
                        >
                          {showCurrentPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  error={Boolean(touched.npassword && errors.npassword)}
                  helperText={touched.npassword && errors.npassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="New Password:"
                  type={showNewPassword ? "text" : "password"}
                  name="npassword"
                  //   margin="normal"
                  fullWidth
                  value={values.npassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          edge="end"
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  error={Boolean(touched.cpassword && errors.cpassword)}
                  helperText={touched.cpassword && errors.cpassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Confirm Password:"
                  type={showCPassword ? "text" : "password"}
                  name="cpassword"
                  margin="normal"
                  fullWidth
                  value={values.cpassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCPassword}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showCPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <Button
                  fullWidth
                  margin="normal"
                  // disabled={canLogin}
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
}

export default ChangePasswordView;
