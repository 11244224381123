import { createSlice } from '@reduxjs/toolkit';
import { API } from '../config';
import { post, get } from './service';

const initialState = {
    generateOTPResponse: {
        status: null,
        data: null
    },
    verifyOtpResponse: {
        status: null,
        data: null
    },
    resetPasswordResponse: {
        status: null,
        data: null
    },
    userByRole: {
        status: null,
        data: null
    },
    assignUserToWards: {
        status: null,
        data: null
    },
    removeAssignee: {
        status: null,
        data: null
    },
    updateUserDataRespone:{
        status: null,
        data: null
    },
    generateOtpForNewNumberResponse:{
        status: null,
        data: null
    },
    votpForNewNumberResponse:{
        status: null,
        data: null
    },
    updatePasswordRespone:{
        sttaus: null,
        data: null
    }
};

const slice = createSlice({
    name: 'userManagement',
    initialState,
    reducers: {
        setGenerateOTP(state, action) {
            state.generateOTPResponse = action.payload
        },
        setVerifyOtpResponse(state, action) {
            state.verifyOtpResponse = action.payload
        },
        setVerifyOtpResetPassword(state, action) {
            state.resetPasswordResponse = action.payload
        },
        setUserByRole(state, action){
            state.userByRole = action.payload
        },
        setAssignUserToWards(state, action){
            state.assignUserToWards = action.payload
        },
        setRemoveasignee(state, action){
            state.removeAssignee = action.payload
        },
        setUpdateUserData(state, action){
            state.updateUserDataRespone = action.payload
        },
        setGenerateOtpForNewNumber(state, action){
            state.generateOtpForNewNumberResponse = action.payload
        },
        setVerifyOtpForNewNumber(state, action){
            state.votpForNewNumberResponse = action.payload
        },
        setUpdatePassword(state, action){
            state.updatePasswordRespone = action.payload
        },
        resetData(state, action){
            state.generateOtpForNewNumberResponse = initialState.generateOtpForNewNumberResponse
            state.votpForNewNumberResponse = initialState.votpForNewNumberResponse
            state.updatePasswordRespone = initialState.updatePasswordRespone
            state.updateUserDataRespone = initialState.updateUserDataRespone
        },
    }
});

export const { reducer } = slice;

export const updateUserData = (data) => async (dispatch) => {
    dispatch(slice.actions.setUpdateUserData({ ...initialState.updateUserDataRespone, status: "loading" }));

    post(API.base_url + "auth/update-user-data", { ...data })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setUpdateUserData(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setUpdateUserData({ ...errResponse, status: "failed" }))
        });
};

export const generateOTP = (data) => async (dispatch) => {
    dispatch(slice.actions.setGenerateOTP({ ...initialState.generateOTP, status: "loading" }));

    post(API.base_url + "auth/generate-otp", { ...data })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setGenerateOTP(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setGenerateOTP({ ...errResponse, status: "failed" }))
        });
};

export const verifyOtpForNewNumber = (data) => async (dispatch) => {
    dispatch(slice.actions.setVerifyOtpForNewNumber({ ...initialState.votpForNewNumberResponse, status: "loading" }));

    post(API.base_url + "auth/verify-otp-for-new-phonenumber", { ...data })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setVerifyOtpForNewNumber(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setVerifyOtpForNewNumber({ ...errResponse, status: "failed" }))
        });
}

export const resetUserManagement = () => async (dispatch) => {
    dispatch(slice.actions.resetData({}));
}
export const updatePassword = (data) => async (dispatch) => {
    dispatch(slice.actions.setUpdatePassword({ ...initialState.updatePasswordRespone, status: "loading" }));

    post(API.base_url + "auth/change-password", { ...data })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setUpdatePassword(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setUpdatePassword({ ...errResponse, status: "failed" }))
        });
}

export const generateOTPForNewNumber = (data) => async (dispatch) => {
    dispatch(slice.actions.setGenerateOtpForNewNumber({ ...initialState.generateOtpForNewNumberResponse, status: "loading" }));

    post(API.base_url + "auth/generate-otp-for-new-phonenumber", { ...data })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setGenerateOtpForNewNumber(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setGenerateOtpForNewNumber({ ...errResponse, status: "failed" }))
        });
};

export const verifyOtpAndResetPassword = (data) => async (dispatch) => {
    dispatch(slice.actions.setVerifyOtpResetPassword({ ...initialState.resetPasswordResponse, status: "loading" }));

    post(API.base_url + "auth/verify-otp-reset-password", { ...data })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setVerifyOtpResetPassword(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setVerifyOtpResetPassword({ ...errResponse, status: "failed" }))
        });
};

export const verifyOtpAndUpdate = (data) => async (dispatch) => {
    dispatch(slice.actions.setVerifyOtpResponse({ ...initialState.generateOTP, status: "loading" }));

    post(API.base_url + "auth/verify-otp", { ...data })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setVerifyOtpResponse(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setVerifyOtpResponse({ ...errResponse, status: "failed" }))
        });
};

export const getUserByRole = (data) => async (dispatch) => {
    dispatch(slice.actions.setUserByRole({ ...initialState.userByRole, status: "loading" }));

    get(API.base_url + "auth/getUserByRole", { ...data })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setUserByRole(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setUserByRole({ ...errResponse, status: "failed" }))
        });
};

export const assignedUsersToWard = (data) => async (dispatch) => {
    dispatch(slice.actions.setAssignUserToWards({ ...initialState.assignUserToWards, status: "loading" }));

    post(API.base_url + "auth/assignedUserToWards", data)
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setAssignUserToWards(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setAssignUserToWards({ ...errResponse, status: "failed" }))
        });
};


export const removeAssignee = (data) => async (dispatch) => {
    dispatch(slice.actions.setRemoveasignee({ ...initialState.removeAssignee, status: "loading" }));

    post(API.base_url + "auth/removeWardAssignment", data)
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setRemoveasignee(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setRemoveasignee({ ...errResponse, status: "failed" }))
        });
};
export default slice;