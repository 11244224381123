import Chart from 'react-apexcharts';
import { Avatar, Box, Button, Card, Divider, Grid, Typography } from '@material-ui/core';
import { alpha, useTheme } from '@material-ui/core/styles';
import ArrowRightIcon from '../../../icons/ArrowRight';
import ChevronDownIcon from '../../../icons/ChevronDown';
import ChevronUpIcon from '../../../icons/ChevronUp';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import MapIcon from '@material-ui/icons/Map';
import RoomIcon from '@material-ui/icons/Room';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import StreetviewIcon from '@material-ui/icons/Streetview';
import { getTarget } from '../../../slices/target';
import avgelec from "../../../images/icons/avgelec.png"
import avgwater from "../../../images/icons/avgwater.png"
import site from "../../../images/icons/site.svg"
import block from "../../../images/icons/block.png"
import panchayat from "../../../images/icons/panchayat.png"
import scheme from "../../../images/icons/scheme.svg"
import { getOperationalData } from '../../../slices/operationalData';
import { getDeviceStats } from '../../../slices/deviceStats';
import { convertNumToTime, historyDate } from '../../../utils/helper';
import { getShcemeOperationalData, resetOpData } from '../../../slices/schemesDeviceOperational';
import _ from 'lodash';
const operationalInitialValue = { nonFunctional: 0, functional: 0, functionalWLS: 0, functionalPWS: 0, nonFunctionalWLS: 0, nonFunctionalPWS: 0 }
const AnalyticsGeneralOverview = (props) => {

  const dispatch = useDispatch()
  const { target } = useSelector((state) => state.target);
  // const [summaryAllData, setSummaryAllData] = useState([]);
  // const [summaryData, setSummaryData] = useState({ target: "-", installed: "-", wls: "-", pws_svs: "-" })
  const { schemeOperationalData: opData } = useSelector((state) => state.schemesDeviceOperational);
  // const [operationalData, setOperationalData] = useState(operationalInitialValue);
  const [opFinalData, setOpFinalData] = useState({ functional: "-", nonFunctional: "-" });
  const [totalSchemes, setTotalSchemes] = useState("-");
  const [currentType, setCurrentType] = useState("");
  const { deviceStats: devStats } = useSelector((state) => state.deviceStats);
  const [devStatsState, setDevStatsState] = useState({ avgMotorRunning: 0, avgElectricity: 0, avgWaterConsumption: 0 })

  useEffect(() => {
    dispatch(getTarget(0,0))
  }, [])

  useEffect(() => {
    if (devStats["status"] == "SUCCESS" && devStats["data"]) {
      const d = devStats["data"]
      let tempDevstats = { ...devStatsState }
      tempDevstats["avgElectricity"] = d["avgElectricity"] ? convertNumToTime((d["avgElectricity"])) + " (Hrs)" : 0
      tempDevstats["avgMotorRunning"] = d["avgMotorRunning"] ? convertNumToTime((d["avgMotorRunning"])) + " (Hrs)" : 0
      tempDevstats["avgWaterConsumption"] = d["avgWaterConsumption"] ? (d["avgWaterConsumption"] / 1000).toFixed(2) + " (KL)" : 0
      // tempDevstats["avgElectricity"] = d["avgElectricity"] ? d["avgElectricity"].toFixed(2) + " (Hrs)" : 0
      // tempDevstats["avgMotorRunning"] = d["avgMotorRunning"] ? d["avgMotorRunning"].toFixed(2) + " (Hrs)" : 0
      // tempDevstats["avgWaterConsumption"] = d["avgWaterConsumption"] ? d["avgWaterConsumption"].toFixed(2) + " (KL)" : 0

      setDevStatsState(tempDevstats)
    } else {
      let tempDevstats = { ...devStatsState }
      tempDevstats["avgElectricity"] = "-"
      tempDevstats["avgMotorRunning"] = "-"
      tempDevstats["avgWaterConsumption"] = "-"
      setDevStatsState(tempDevstats)
    }

  }, [devStats])

  useEffect(() => {
    const {district, division, block, panchayat, ward } = props.schemeLocation
    if (district) {
      dispatch(getShcemeOperationalData({ district, division }, historyDate(3, "currentDay")))
      if (district) {
        let pld = {
          did: district
        }
        if(division){
          pld["divid"] = division
        }
        if(block){
          pld["block"] = block
        }
        if(panchayat){
          pld["panchayat"] = panchayat
        }
        if(ward){
          pld["ward"] = ward
        }
        dispatch(getDeviceStats(pld, historyDate(1)))
        dispatch(getTarget(props.districtId, props.divId))
      }
    } else {
    }
  }, [props.schemeLocation ])

  useEffect(() => {
    if (opData["status"] == "SUCCESS" && opData["data"]) {
      setOpFinalData(opData["data"])
      setTotalSchemes(opData["data"]["total"])
      setCurrentType("")
      props.onChangeShowWards([])
    } else {
      setOpFinalData({ functional: "-", nonFunctional: "-" })
      setCurrentType("")
      setTotalSchemes("-")
      props.onChangeShowWards([])
    }

  }, [opData])

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid item >
        <Card style={{ border: "1px solid #727cf5", height: "100%" }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: "column",
              p: 2
            }}
            style={{ alignItems: "center" }}
          >
            <Typography
              color="textPrimary"
            >
              Total Schemes
            </Typography>
            <div style={{
              margin: 30,
              display: 'flex',
              flexDirection: "column",
              alignItems: "center"
            }}>
              <img src={scheme} width={28} style={{ color: "#688eff", margin: 8 }} />
              <Typography
                color="textPrimary"
                variant="h5"
              >
                <span style={{cursor: "pointer", textDecoration: currentType == "" ? "underline" : "none" }} onClick={()=>{
                  props.onChangeShowWards([]);
                  props.onChangeSection("All_Schemes")
                  setCurrentType("")
                }}>{totalSchemes}</span>
              </Typography>
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
      >
        <Card style={{ border: "1px solid #727cf5", height: "100%" }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: "column",
              p: 2
            }}
            style={{ alignItems: "center" }}
          >
            <Typography
              color="textPrimary"
            >
              Functional Schemes
              {/* <span style={{ color: "red" }}>(WIP)</span> */}
            </Typography>

            <div style={{
              margin: 30,
              display: 'flex',
              flexDirection: "column",
              alignItems: "center"
            }}>
              <img src={site} width={28} style={{ color: "#688eff", margin: 8 }} />
              <Typography
                color="textPrimary"
                variant="h5"
              >
                <span style={{ cursor: "pointer", textDecoration: currentType == "functional" ? "underline" : "none" }} onClick={() => {
                  if (currentType == "functional") {
                    props.onChangeShowWards([]);
                    setCurrentType("")
                  }
                  else {
                    props.onChangeShowWards(opData["data"] ? opData["data"]["functionalWards"] : []);
                    setCurrentType("functional")
                  }
                  props.onChangeSection("Functional_Schemes")
                }}>{opFinalData["functional"]}</span>
              </Typography>
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
      >
        <Card style={{ border: "1px solid #727cf5", height: "100%" }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: "column",
              p: 2
            }}
            style={{ alignItems: "center" }}
          >
            <Typography
              color="textPrimary"
            >
              Non Functional Schemes
            </Typography>

            <div style={{
              margin: 30,
              display: 'flex',
              flexDirection: "column",
              alignItems: "center"
            }}>
              <img src={block} width={28} style={{ color: "#688eff", margin: 8 }} />
              <Typography
                color="textPrimary"
                variant="h5"
              >
                <span style={{ cursor: "pointer", textDecoration: currentType == "nonFunctional" ? "underline" : "none" }} onClick={() => {
                  if (currentType == "nonFunctional") {
                    props.onChangeShowWards([])
                    setCurrentType("")
                  } else {
                    props.onChangeShowWards(opData["data"] ? opData["data"]["nonFunctionalWards"] : [])
                    setCurrentType("nonFunctional")
                  }
                  props.onChangeSection("Non_Functional_Schemes")
                }}>{opFinalData["nonFunctional"]}</span>
              </Typography>
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
      >
        <Card style={{ border: "1px solid #727cf5", height: "100%" }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: "column",
              p: 2
            }}
            style={{ alignItems: "center" }}
          >
            <Typography
              color="textPrimary"
            >
              Non Reporting Schemes
            </Typography>

            <div style={{
              margin: 30,
              display: 'flex',
              flexDirection: "column",
              alignItems: "center"
            }}>
              <img src={block} width={28} style={{ color: "#688eff", margin: 8 }} />
              <Typography
                color="textPrimary"
                variant="h5"
              >
                <span style={{ cursor: "pointer", textDecoration: currentType == "nonReporting" ? "underline" : "none", textAlign:"center" }} 
                onClick={() => {
                  if (currentType === "nonReporting") {
                    props.onChangeShowWards([])
                    setCurrentType("")
                  } else {
                    props.onChangeShowWards(opData["data"] ? opData["data"]["nonReportingWards"] : [])
                    setCurrentType("nonReporting")
                  }
                  props.onChangeSection("Non_Reporting_Schemes")
                }}
                >{opFinalData["nonReporting"]}</span>
              </Typography>
                <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                  <Typography fontSize={16}>
                    New: <span style={{ cursor: "pointer", textDecoration: currentType === "nonReporting.new" ? "underline" : "none", textAlign:"center" }} onClick={() => {
                        props.onChangeShowWards(_.get(opFinalData, "deviceTypeWiseIds.new", []))
                        setCurrentType("nonReporting.new")
                    }}>{_.get(opFinalData, "deviceTypeWise.new", "-")}</span>
                  </Typography>
                  <Typography fontSize={16} marginLeft={4}>
                    Old: <span style={{ cursor: "pointer", textDecoration: currentType === "nonReporting.old" ? "underline" : "none", textAlign:"center" }}  onClick={() => {
                        props.onChangeShowWards(_.get(opFinalData, "deviceTypeWiseIds.old", []))
                        setCurrentType("nonReporting.old")
                    }}>{_.get(opFinalData, "deviceTypeWise.old", "-")}</span>
                  </Typography>
                </Box>
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
      >
        <Card style={{ border: "1px solid #727cf5", height: "100%" }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: "column",
              p: 2
            }}
            style={{ alignItems: "center" }}
          >
            <Typography
              color="textPrimary"
            >
              Avg Pump Running
            </Typography>

            <div style={{
              margin: 30,
              display: 'flex',
              flexDirection: "column",
              alignItems: "center"
            }}>
              <img src={panchayat} width={28} style={{ color: "#688eff", margin: 8 }} />
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {devStatsState["avgMotorRunning"]}
              </Typography>
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
      >
        <Card style={{ border: "1px solid #727cf5", height: "100%" }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: "column",
              p: 2
            }}
            style={{ alignItems: "center" }}
          >
            <Typography
              color="textPrimary"
            >
              Avg Electricity
            </Typography>

            <div style={{
              margin: 30,
              display: 'flex',
              flexDirection: "column",
              alignItems: "center"
            }}>
              <img src={block} width={28} style={{ color: "#688eff", margin: 8 }} />
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {devStatsState["avgElectricity"]}
              </Typography>
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
      >
        <Card style={{ border: "1px solid #727cf5", height: "100%" }}>

          <Box
            sx={{
              display: 'flex',
              flexDirection: "column",
              p: 2
            }}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <Typography
              color="textPrimary"
            >
              Avg Water Consumption
            </Typography>

            <div style={{
              margin: 30,
              display: 'flex',
              flexDirection: "column",
              alignItems: "center"
            }}>
              <img src={panchayat} width={28} style={{ color: "#688eff", margin: 8 }} />
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {devStatsState["avgWaterConsumption"]}
              </Typography>
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
        md={2}
        sm={6}
        xs={12}
      >
        {/* <Card style={{border:"1px solid #727cf5"}}>

          <Box
            sx={{
              display: 'flex',
              flexDirection: "column",
              justifyContent: 'space-between',
              p: 3
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Avg Pump Running (Hrs.)
              </Typography>
              <Typography
                color="textPrimary"
              >
                <span style={{ color: "red" }}>WIP</span>
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Avg Electricity (Hrs.)
              </Typography>
              <Typography
                color="textPrimary"
              >
                <span style={{ color: "red" }}>WIP</span>
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Avg Water Consumption (KL)
              </Typography>
              <Typography
                color="textPrimary"
              >
                <span style={{ color: "red" }}>WIP</span>
              </Typography>
            </div>
          </Box>
        </Card> */}
      </Grid>
    </Grid>
  )
}

export default AnalyticsGeneralOverview;
