import {
    Box, Card, CardHeader, Breadcrumbs, Container, Grid, Link, Typography,
    ListItemText,
    ListItem,
    ListItemIcon,
    List
} from '@material-ui/core';
import Chart from 'react-apexcharts';
import { Helmet } from 'react-helmet-async';
import useSettings from '../../hooks/useSettings';
import ChevronRightIcon from '../../icons/ChevronRight';
import { Link as RouterLink } from 'react-router-dom';
import { ElectricityAndMonitoring, SchemeHealth, SchemeIssues } from '../../components/dashboard/schemedashboard';
import { useTheme } from '@material-ui/core/styles';

import electricityIcon from '../../images/icons/electricity.svg'
import motorIcon from '../../images/icons/motor.png'
import pressureIcon from '../../images/icons/pressure.svg'
import waterIcon from '../../images/icons/water.png'
import electricityoff from '../../images/icons/electricityoff.svg'
import nonoperationalmotor from '../../images/icons/nonoperationalmotor.svg'
import { AvgEleAndMotor, Issues, OperationalNonOp } from '../../components/dashboard/blockdashboard';

const BlockDashboard = () => {
    const { settings } = useSettings();
    const theme = useTheme();

    const chartOptions = {
        chart: {
            type: 'line',
            background: 'parent'
        },
        colors: ['#27c6db'],
        labels: ['50 (20%)'],
        plotOptions: {
            radialBar: {
                dataLabels: {
                    value: {
                        show: false
                    }
                },
                hollow: {
                    size: '60%'
                },
                track: {
                    background: theme.palette.background.default
                }
            }
        },
        theme: {
            mode: theme.palette.mode
        }
    };

    const chartSeries = [83];
    const infoList = [[
        { title: "Block", value: "HAJIPUR" },
        { title: "Division", value: "ABC" },
        { title: "Panchayat", value: "15" }], [
        { title: "Total Scheme", value: "411" },
        { title: "Single Ward", value: "30" },
        { title: "Multi Ward", value: "30" }
    ]]

    const funList = [[
        { title: "Average Electricity", value: "8 hrs", icon: <img width={16} src={electricityIcon} /> },
        { title: "Average Motor Running", value: "8 hrs", icon: <img width={16} src={motorIcon} /> },
        { title: "Average Water Consumption", value: "8 hrs", icon: <img width={16} src={waterIcon} /> }], [
    ]]

    const nonList = [[
        { title: "Electricity Issue", value: "28", icon: <img width={16} src={electricityoff} /> },
        { title: "Motor Not Running", value: "20", icon: <img width={16} src={nonoperationalmotor} /> }
    ]]

    return (
        <>
            <Helmet>
                <title>Scheme Dashboard | Har Ghar Nal Jal Yojna</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Block Dashboard (Work In Progress)
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard"
                                    variant="subtitle2"
                                >
                                    Block Dashboard
                                </Link>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                md={4}
                                xs={12}
                            >
                                <Card>
                                    <CardHeader title={"Information"} />
                                    <Grid
                                        container
                                        spacing={3}
                                    >{infoList.map((il) => {
                                        return <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <List dense={true}>
                                                {il.map((il1) => {
                                                    return <ListItem>
                                                        <ListItemText
                                                            primary={il1["title"]}
                                                            secondary={il1["value"]}
                                                        />
                                                    </ListItem>
                                                })}
                                            </List>
                                        </Grid>
                                    })}
                                    </Grid>
                                </Card>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                            >
                                <Card>
                                    <CardHeader title={"Functional - 250"} />
                                    <Grid
                                        container
                                        spacing={3}
                                    >{funList.map((il) => {

                                        if (il.length)
                                            return <Grid
                                                item
                                                md={12}
                                                xs={12}
                                            >
                                                <List dense={true}>
                                                    {il.map((il1) => {
                                                        return <ListItem>
                                                            <ListItemIcon>
                                                                {il1["icon"]}
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={il1["title"]}
                                                                secondary={il1["value"]}
                                                            />
                                                        </ListItem>
                                                    })}
                                                </List>
                                            </Grid>
                                    })}
                                    </Grid>
                                </Card>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                            >
                                <Card>
                                    <CardHeader title={"Non Functional - 50"} />
                                    <Grid
                                        container
                                        spacing={3}
                                    >{nonList.map((il) => {

                                        if (il.length)
                                            return <Grid
                                                item
                                                md={12}
                                                xs={12}
                                            >
                                                <List dense={true}>
                                                    {il.map((il1) => {
                                                        return <ListItem>
                                                            <ListItemIcon>
                                                                {il1["icon"]}
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={il1["title"]}
                                                                secondary={il1["value"]}
                                                            />
                                                        </ListItem>
                                                    })}
                                                </List>
                                            </Grid>
                                    })}
                                    </Grid>
                                </Card>
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Card>
                                    <CardHeader title={"Panchayat wise functional and non-functional"} />
                                    <OperationalNonOp />
                                </Card>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <Card>
                                    <CardHeader title={"Avg. Electricity And Motor Running (Hrs)"} />
                                    <AvgEleAndMotor />
                                </Card>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <Card>
                                    <CardHeader title={"Issues"} />
                                    <Issues />
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>

    )
}

export default BlockDashboard