import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Box,
    Grid,
    Typography,
    Container,
    Breadcrumbs,
    Link,
    Button,
    ButtonGroup
} from '@material-ui/core';
import ChevronRightIcon from '../../../icons/ChevronRight';
import UploadNewDevice from "./UploadNewDevice";
import EditDevice from "./EditDevice";
import ShowDuplicates from './ShowDuplicates';
import ModifyMaster from './ModifyMaster';
import NicLinking from './NicLinking';
import UploadSchemeImages from '../admin/UploadSchemeImages';

const menus = ["Upload new device data", "Modify Existing Device data", "Link with NIC ID", "Show Duplicates", "Update Master Data", "GIS Upload"]
const DeviceDataManagement = () => {
    const [menuId, setMenuId] = useState(0)
    const navigate = useNavigate()
    return (
        <>
            <Helmet>
                <title>Device Data Management | Har Ghar Nal Jal Yojna</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 2
                }}
            >
                <Container maxWidth={"xl"}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item width="100%">
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Device Data Management
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/overview"
                                    variant="subtitle2"
                                >
                                    Home
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Device Data Management
                                </Typography>
                            </Breadcrumbs>
                            <Box
                                sx={{
                                    mt: 3
                                }}
                                width="100%"
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <ButtonGroup aria-label="small outlined button group">
                                        {menus.map((m, i) => {
                                            return <Button
                                                key={i} 
                                                color="primary"
                                                variant={menuId == i ? "contained" : ""}
                                                onClick={() => i > menus.length ? null : setMenuId(i)}
                                            >
                                                {m}
                                            </Button>
                                        })}
                                    </ButtonGroup>
                                    <Button onClick={() => { navigate(-1) }}>Back</Button>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        {menuId == 0 && <UploadNewDevice />}
                        {menuId == 1 && <EditDevice />}
                        {menuId == 2 && <NicLinking />}
                        {menuId == 3 && <ShowDuplicates />}
                        {menuId == 4 && <ModifyMaster />}
                        {menuId == 5 && <UploadSchemeImages />}
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default DeviceDataManagement;
