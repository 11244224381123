import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import {
    Box,
    Grid,
    MenuItem,
    TextField,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { priColor } from "../../../constants";
import { useSelector } from "react-redux";
import _, { get, has } from "lodash";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


Array.prototype.move = function (x, y) {
    this.splice(y, 0, this.splice(x, 1)[0]);
    return this;
};

export default function UpdateNewDeviceUI(props) {
    const [newDeviceId, setNewDeviceId] = useState("");
    const [newWardName, setNewWardName] = useState("");
    const [newSchemeId, setNewSchemeId] = useState("");
    const [newSchemeType, setNewSchemeType] = useState("");

    const [household, setHousehold] = useState("");
    const [fhtc, setFhtc] = useState("");
    const [motorCapacity, setMotorCapacity] = useState("");
    const [lps, setLps] = useState("");
    const [nicId, setNicId] = useState("");
    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");
    const [op_name, setOp_name] = useState("");
    const [op_number, setOp_number] = useState("");

    const { district, division, block, panchayat, ward } = useSelector((state) => state.siteinfo);
    const [allBlock, setAllBlock] = useState([])

    // console.log(">>", block, panchayat)
    useEffect(() => {
        const ddata = props.deviceDatails
        if (ddata) {
            setNewDeviceId(ddata["other"]["deviceid"])
            setNewWardName(ddata["name"])
            setNewSchemeId(ddata["schemeId"])
            setNewSchemeType(ddata["subScheme"])
            setHousehold(ddata["household"])
            setFhtc(ddata["fhtc"])
            setMotorCapacity(ddata["motorCapacity"])
            setNicId(ddata["nicId"])
            setLps(ddata["lps"])
            setLng(get(ddata, "survey_data.location.coords.longitude", ddata["other"]["lng"]))
            setLat(get(ddata, "survey_data.location.coords.latitude", ddata["other"]["lat"]))
            setOp_name(get(ddata, "survey_data.location.name", ddata["other"]["op_name"]))
            setOp_number(get(ddata, "survey_data.anurakshak.mobile_number", ddata["other"]["op_number"]))
        }
    }, [props.deviceDatails]);


    const isInValid = () => {
        let invalid = false;
        if (newDeviceId == "" || newWardName == "" || newSchemeId == "" || newSchemeType == ""
            || household == "" || fhtc == "") {
            invalid = true;
        }

        return invalid;
    };

    useEffect(() => {
        console.log(">>", district, division, block, panchayat, ward)
        if(has(block, "data.length", 0)){
            setAllBlock(block.data.map((e) => { return {id: e.id, name: e.name}}))
        }
    }, [district, division, block, panchayat, ward])

    console.log(">>", allBlock)
    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"md"}
                onClose={(e, r) => {
                    if (r != "backdropClick") {
                        props.setOpenStatus(false);
                    }
                }}
                aria-labelledby="customized-dialog-title"
                open={props.openStatus}
                disableEscapeKeyDown={true}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={() => props.setOpenStatus(false)}
                >
                    Update Device
                </DialogTitle>
                <DialogContent dividers>
                    {props.deviceDatails && (
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography gutterBottom>
                                District Name :{" "}
                                <span style={{ color: priColor }}>
                                    {props.deviceDatails["districtName"]}{" "}
                                </span>
                            </Typography>
                            <Typography gutterBottom>
                                Block Name:{" "}
                                <span style={{ color: priColor }}>
                                    {props.deviceDatails["blockName"]}{" "}
                                </span>{" "}
                            </Typography>

                            <Typography gutterBottom>
                                Panchayat Name:{" "}
                                <span style={{ color: priColor }}>
                                    {props.deviceDatails["panchayatName"]}
                                </span>
                            </Typography>

                            <Typography gutterBottom>
                                Ward :{" "}
                                <span style={{ color: priColor }}>
                                    {props.deviceDatails["name"]}
                                </span>{" "}
                            </Typography>

                            <Typography gutterBottom>
                                Device Id :{" "}
                                <span style={{ color: priColor }}>
                                    {props.deviceDatails["other"]["deviceid"]}{" "}
                                </span>
                                <br />
                            </Typography>
                        </Box>
                    )}
                    <Grid container spacing={3}>
                        <Grid item md={3}>
                            <TextField
                                value={newWardName}
                                onChange={(e) => setNewWardName(e.target.value)}
                                name="newWardName"
                                label="Ward Name"
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                value={newSchemeId}
                                onChange={(e) => setNewSchemeId(e.target.value)}
                                name="newSchemeId"
                                placeholder="Scheme Id"
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                value={newSchemeType}
                                onChange={(e) => setNewSchemeType(e.target.value)}
                                name="newSchemeType"
                                placeholder="Scheme Type"
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Tooltip title="Disabled because it cannot be edited. Contact admin">
                                <TextField
                                    value={newDeviceId}
                                    disabled
                                    onChange={(e) => setNewDeviceId(e.target.value)}
                                    name="newDeviceId"
                                    label="New Device Id"
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                value={household}
                                onChange={(e) => setHousehold(e.target.value)}
                                name="household"
                                label="New Household"
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                value={fhtc}
                                onChange={(e) => setFhtc(e.target.value)}
                                name="fhtc"
                                label="New FHTC"
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                value={motorCapacity}
                                onChange={(e) => setMotorCapacity(e.target.value)}
                                name="motorCapacity"
                                label="New Motor Capacity"
                            />
                        </Grid>

                        <Grid item md={3}>
                            <TextField
                                value={lps}
                                onChange={(e) => setLps(e.target.value)}
                                name="lps"
                                label="New LPS"
                            />
                        </Grid>

                        <Grid item md={3}>
                            <TextField
                                value={nicId}
                                onChange={(e) => setNicId(e.target.value)}
                                name="nicId"
                                label="New nicId"
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                value={lat}
                                onChange={(e) => setLat(e.target.value)}
                                name="lat"
                                label="New lat"
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                value={lng}
                                onChange={(e) => setLng(e.target.value)}
                                name="lng"
                                label="New lng"
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                value={op_name}
                                onChange={(e) => setOp_name(e.target.value)}
                                name="op_name"
                                label="New op_name"
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                value={op_number}
                                onChange={(e) => setOp_number(e.target.value)}
                                name="op_number"
                                label="New op_number"
                            />
                        </Grid>
                        <Grid
                            item
                            md={2}
                            sm={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Block"
                                name="block"
                                // onChange={handleBlockChange}
                                select
                                // SelectProps={{ native: true }}
                                // value={selectedBlock.id ? selectedBlock.id : ""}
                                variant="outlined"
                            >
                                {_.sortBy([{ id: "", name: "" }, ...allBlock], "name").map((category) => (
                                    <MenuItem
                                        key={category.id}
                                        value={category.id}
                                    >
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Tooltip open={isInValid()} title="Please enter issue details">
                        <Button
                            autoFocus
                            onClick={() =>{
                                // props.update({
                                console.log({
                                    wardData: {
                                        ...props.deviceDatails,
                                        other: {
                                            ...props.deviceDatails["other"],
                                            deviceid: newDeviceId,
                                            lat,
                                            lng,
                                            op_name,
                                            op_number

                                        },
                                        name: newWardName,
                                        subScheme: newSchemeType,
                                        schemeId: newSchemeId,
                                        lps,
                                        motorCapacity,
                                        fhtc,
                                        household,
                                        nicId
                                    },
                                    id: props.deviceDatails["id"],
                                    oldDeviceId: props.deviceDatails["other"]["deviceid"]
                                })}
                            }
                            color="primary"
                            disabled={isInValid()}
                        >
                            Submit
                        </Button>
                    </Tooltip>
                </DialogActions>
            </Dialog>
        </div>
    );
}
