import { API } from "../../../config";
import { get } from "../../../slices/service";

export const getRegDataUsingBardCodeId = (barcode) => {
  const token = window.localStorage.getItem("accessToken");
  const options = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return new Promise((resolve, reject) => {
    fetch(
      API.base_url + `/phed/debugging/checkifBarcodeUsed?barcode=${barcode}`,
      options
    )
      .then((rawData) => rawData.json())
      .then(function (response) {
        response = response || {};
        resolve(response);
      })
      .catch(function (errResponse) {
        reject(new Error("Internal server error"));
      });
  });
};

export const getRegData = (imei) => {
  const token = window.localStorage.getItem("accessToken");
  const options = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return new Promise((resolve, reject) => {
    fetch(
      API.base_url + `/phed/debugging/getRegistrationData?imei=${imei}`,
      options
    )
      .then((rawData) => rawData.json())
      .then(function (response) {
        response = response || {};
        resolve(response);
      })
      .catch(function (errResponse) {
        reject(new Error("Internal server error"));
      });
  });
};

export const updateRegData = (data) => {
  const token = window.localStorage.getItem("accessToken");
  const options = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  };
  return new Promise((resolve, reject) => {
    fetch(API.base_url + `phed/debugging/updateBarCode`, options)
      .then((rawData) => rawData.json())
      .then(function (response) {
        response = response || {};
        resolve(response);
      })
      .catch(function (errResponse) {
        reject(new Error("Internal server error"));
      });
  });
};

export const getDeviceLastData = (did) => {
  const token = window.localStorage.getItem("accessToken");
  const options = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return new Promise((resolve, reject) => {
    fetch(
      API.base_url + `/phed/debugging/deviceTelemetry?deviceId=${did}`,
      options
    )
      .then((rawData) => rawData.json())
      .then(function (response) {
        response = response || {};
        resolve(response);
      })
      .catch(function (errResponse) {
        reject(new Error("Internal server error"));
      });
  });
};

export const genearteCert = (data) => {
  const token = window.localStorage.getItem("accessToken");
  const options = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data)
  };
  return new Promise((resolve, reject) => {
    fetch(API.base_url + `/phed/debugging/generate-cert`, options)
      .then((rawData) => rawData.json())
      .then(function (response) {
        response = response || {};
        resolve(response);
      })
      .catch(function (errResponse) {
        reject(new Error("Internal server error"));
      });
  });
};

export const genearteDeviceInstallationReport = (data) => {
  const token = window.localStorage.getItem("accessToken");
  const options = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data)
  };
  return new Promise((resolve, reject) => {
    fetch(API.base_url + `/phed/debugging/generate-installation-report-pdf`, options)
      .then((rawData) => rawData.json())
      .then(function (response) {
        response = response || {};
        resolve(response);
      })
      .catch(function (errResponse) {
        reject(new Error("Internal server error"));
      });
  });
};

export const genearteDeviceInstallationReportMultiple = (data) => {
  const token = window.localStorage.getItem("accessToken");
  const options = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data)
  };
  return new Promise((resolve, reject) => {
    fetch(API.base_url + `/phed/debugging/generate-installation-report-pdf-multiple`, options)
      .then((rawData) => rawData.json())
      .then(function (response) {
        response = response || {};
        resolve(response);
      })
      .catch(function (errResponse) {
        reject(new Error("Internal server error"));
      });
  });
};

export const getTotalFWInstalled = (data) => {
  return get(API.base_url + "phed/debugging/get-fw-installation-count")
};