import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Typography,
  Link,
  TextField,
  Button,
  Stepper,
  CircularProgress,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import ChevronRightIcon from "../../../icons/ChevronRight";
import { Helmet } from "react-helmet-async";
import useAuth from "../../../hooks/useAuth";
import pv01 from "../../../images/fw_test/pv01.png";
import { useEffect } from "react";
import { getDeviceLastData, getRegData } from "./_service";
import ProgressIndicator from "./ProgressIndicator";
import { getDateTime, getSGMStrength } from "../../../utils/helper";

const TRY_LIMIT = 30; // 15 attempts. 1 at every 10 sec.
const INTERVAL = 10000;
const TEST_SUCCESS = "Test Pass";
const progressStopStatus = ["Test Pass", "Cancelled", "Test Failed"];

const Step1 = (props) => {
  const { user } = useAuth();
  const [step1, setStep1] = useState("Checking");
  const [step2, setStep2] = useState("Yet to start");
  const [step2started, setStep2started] = useState(false);
  const [step3started, setStep3started] = useState(false);
  const [step3, setStep3] = useState("Yet to start");
  const [step1Data, setStep1Data] = useState({
    dateTime: { date: "", time: "" },
  });
  const [deviceData, setDeviceData] = useState(props.deviceRegData);

  const startTest3 = () => {
    //Third test case
    setStep3started(true);
    let times = TRY_LIMIT;
    const test3 = setInterval(() => {
      getDeviceLastData(deviceData["deviceId"]).then((e) => {
        setStep3("Checking..");
        if (e.data && e.data.p == "1" && e.data.l == "0" && e.data.m == "5") {
          clearInterval(test3);
          setStep3(TEST_SUCCESS);
        } else {
          times--;
          if (!times) {
            clearInterval(test3);
            setStep3("Test Failed");
          }
        }
      });
    }, INTERVAL);
  };

  useEffect(() => {
    props.updateData(deviceData)
  }, [deviceData])

  const startTest2 = () => {
    //Second test case
    setStep2started(true);
    let times = TRY_LIMIT;
    const test2 = setInterval(() => {
      setStep2("Checking");
      getDeviceLastData(deviceData["deviceId"]).then((e) => {
        if (e.data && e.data.p == "1" && e.data.l == "1" && e.data.m == "4") {
          setStep2(TEST_SUCCESS);
          clearInterval(test2);
        } else {
          times--;
          if (!times) {
            clearInterval(test2);
            setStep3("Cancelled");
            setStep2("Test Failed");
          }
        }
      });
    }, INTERVAL);
  };

  const startTest = () => {
    if (deviceData && deviceData["deviceId"]) {
      let times = TRY_LIMIT;
      const test1 = setInterval(() => {
        getDeviceLastData(deviceData["deviceId"]).then((e) => {
          if (e.data && e.data.p == "1" && e.data.l == "0" && e.data.m == "2") {
            const dateTime = getDateTime(e.data["_ts"]);
            setStep1Data({ ...e.data, dateTime });
            setStep1(TEST_SUCCESS);
            clearInterval(test1);
          } else {
            times--;
            if (!times) {
              clearInterval(test1);
              setStep3("Cancelled");
              setStep2("Cancelled");
              setStep1("Test Failed");
            }
          }
        });
      }, INTERVAL);
    } else {
      alert("Device data not found !!");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      startTest();
    }, 1000);
  }, []);

  // useEffect(() => {
  //     setTimeout(() => {
  //       props.next();
  //     }, 5000);
  // }, []);

  return (
    <>
      <Box sx={{ width: "100%", py: 3 }}>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <Card>
              <CardHeader title={"Device Data"} />
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography color="textPrimary" variant="subtitle2">
                        IMEI Number
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                        {deviceData.imei}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography color="textPrimary" variant="subtitle2">
                        Software Device ID
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                        {deviceData.deviceId}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography color="textPrimary" variant="subtitle2">
                        Operator Name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                        {user.name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography color="textPrimary" variant="subtitle2">
                        Operator Mobile Number
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                        {user.mobile_number}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Grid>
          <Grid item md={6}>
            <Card>
              {progressStopStatus.includes(step1) ||
              progressStopStatus.includes(step2) ||
              progressStopStatus.includes(step3) ? (
                ""
              ) : (
                <ProgressIndicator />
              )}
              <CardHeader title={"Verifying device data"} />
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography color="textPrimary" variant="subtitle2">
                        1: Power Status
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                        {step1 === "Checking" ? <CircularProgress size="20px" /> : <>{step1}</>}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography color="textPrimary" variant="subtitle2">
                        2: Last Hartbeat timestamp
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                      {step1Data["dateTime"]["date"] === "" && step1Data["dateTime"]["time"] == ""
                        ? <>{step1 === "Test Failed" ? "" : <CircularProgress size="20px" />}</>
                        : <>{`${step1Data["dateTime"]["date"]} ${step1Data["dateTime"]["time"]}`}</>}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography color="textPrimary" variant="subtitle2">
                        3: GSM network status
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                        {step1Data["g"] ? getSGMStrength(step1Data["g"]) : <>{step1 === "Test Failed" ? <></> : <CircularProgress size="20px" />}</>}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {step1 == TEST_SUCCESS && (
                    <>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span>Switch ON Motor</span>
                            <Button
                              variant="contained"
                              onClick={() => startTest2()}
                            >
                              Ok
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                      {step2started && (
                        <TableRow>
                          <TableCell>
                            <Typography color="textPrimary" variant="subtitle2">
                              4: Motor on status
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography color="textSecondary" variant="body2">
                              {(step2 === "Yet to start" || step2 === "Checking") ? <CircularProgress size="20px" /> : step2}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {step2 == TEST_SUCCESS && (
                        <>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <span>Switch OFF Motor</span>
                                <Button
                                  variant="contained"
                                  onClick={() => startTest3()}
                                >
                                  Ok
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                          {step3started && (
                            <TableRow>
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                                >
                                  5: Motor off status
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  color="textSecondary"
                                  variant="body2"
                                >
                                  {step3 === "Cancelled" ||  step3 === "Checking.." ? <CircularProgress size="20px" /> : step3}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
              {step1 == TEST_SUCCESS &&
                step2 == TEST_SUCCESS &&
                step3 == TEST_SUCCESS && (
                  <Button
                    style={{ margin: 10 }}
                    variant="contained"
                    onClick={props.next}
                  >
                    Proceed to Certificate Generate Page
                  </Button>
                )}
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Step1;
