import numeral from "numeral";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ExternalLinkIcon from "../../../icons/ExternalLink";
import InformationCircleIcon from "../../../icons/InformationCircle";
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLastPowerOn } from "../../../slices/lastPowerOn";
import moment from "moment-timezone";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { getDateTime, getDiffInMin, historyDate } from "../../../utils/helper";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import { operationalInitialValue } from "../../../constants";
import SignalCellular4BarIcon from "@material-ui/icons/SignalCellular4Bar";
import SignalCellularConnectedNoInternet0BarIcon from "@material-ui/icons/SignalCellularConnectedNoInternet0Bar";
import { getLastMotorOn } from "../../../slices/lastMotorOn";
import IOSSwitch from "../../../components/IOSSwitch";
import { removeAssignee } from "../../../slices/userManagement";

const useStyles = makeStyles({
  root: {
    "& .scheme-data-table": {
      fontWeight: "bold",
    },
  },
});

function CustomToolbar(props) {
  let cdate = moment(new Date()).format("DD_MM_YYYY_HH_mm_ss");
  return (
    <GridToolbarContainer
      style={{
        padding: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Typography color="textPrimary" variant="h6">
        Scheme details
      </Typography>
      <GridToolbarExport
        csvOptions={{
          allColumns: true,
          fileName: `${props.fileName}_${props.locationName}_${cdate}`,
        }}
      />
    </GridToolbarContainer>
  );
}

const WardList = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { lastPowerOn: lastPowerOn } = useSelector(
    (state) => state.lastPowerOn
  );
  const classes = useStyles();

  const [lastPowerOnData, setLastPoweronData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const fetchDeviceStatus = (type) => {
    const deviceids = props.schemes.map((e) => {
      if (e.smsid) return e.smsid;
    });
    const clearDids = deviceids.filter((e) => e);

    dispatch(getLastPowerOn(clearDids, type));
    dispatch(getLastMotorOn(clearDids, historyDate(20, "currentDay"), type));
  };

  useEffect(() => {
    if (props.schemes) {
      fetchDeviceStatus();

      const callRefreshApi = setInterval(() => {
        fetchDeviceStatus("refresh");
      }, 5000);

      return () => {
        clearInterval(callRefreshApi);
      };
    }
  }, []);

  useEffect(() => {
    let tableDataTemp = [];
    if (props.schemes && props.schemes.length) {
      let lastPowerOnData = [];
      if (lastPowerOn["data"] && lastPowerOn["data"].length) {
        lastPowerOnData = lastPowerOn["data"];
      }
      const dr = historyDate(0, "currentDay");
      props.schemes.map((page, i) => {
        if (props.showWardsIds && props.showWardsIds.length) {
          if (!props.showWardsIds.includes(page["id"])) {
            return;
          }
        }

        let motorOn = false;
        let powerOn = false;

        let dateTime = { time: "-", date: "-" };
        let network = false;
        if (lastPowerOnData) {
          const lastDataReceivedTsArr = lastPowerOnData.filter(
            (e) => e.id == page.smsid
          );
          if (lastDataReceivedTsArr && lastDataReceivedTsArr.length) {
            let ldr = lastDataReceivedTsArr[0];
            let lastDataReceivedTs = ldr["_ts"];
            if (ldr["manualGeneratedTS"])
              lastDataReceivedTs = ldr["manualGeneratedTS"];

            dateTime = getDateTime(lastDataReceivedTs);

            let t1 = new Date();
            let diff = getDiffInMin(lastDataReceivedTs, t1.getTime() / 1000);
            if (ldr.current >= 2) {
              //Motor flag
              if (diff <= 17) {
                motorOn = true;
              }
            }

            if (diff <= 17) {
              powerOn = true;
            }

            if (diff <= 1440) {
              network = true;
            }
          }
        }

        tableDataTemp.push({
          ...page,
          ...page.other,
          nicId: page.nicdata ? page.nicdata.NICDeviceId : "",
          did: page.other.deviceid,
          wname: page.name,
          powerOn,
          motorOn,
          assignedOn: page.installation_user_mapping?page.installation_user_mapping.date:"",
          assignedTo: page.installation_user_mapping?page.installation_user_mapping.name:"",
          datastamp: dateTime,
          action: page.installation_user_mapping?page:null,
          network,
          id: page.id,
          slno: i + 1,
        });
      });
    }

    setTableData(tableDataTemp);
    setLastPoweronData(lastPowerOn["data"]);
    props.onChangeLastPowerOn(lastPowerOn["data"]);
  }, [lastPowerOn, props.showWardsIds]);

  const handleClick = (data) => {
    if(data && data.installation_user_mapping)
      dispatch(removeAssignee({wardid: data.id, userid: data.installation_user_mapping.id}))
    else 
      alert("Details not found!!")
  }

  const columns = [
    {
      field: "slno",
      headerName: "Sl No",
      width: 130,
      sortable: false,
      headerClassName: "scheme-data-table",
    },
    {
      field: "id",
      hide: true,
      width: 130,
      sortable: false,
      headerClassName: "scheme-data-table",
    },
    {
      field: "districtName",
      headerName: "Distric",
      width: 130,
      sortable: false,
    },
    {
      field: "divisionName",
      headerName: "Division",
      width: 130,
      sortable: false,
    },
    {
      field: "blockName",
      headerName: "Block",
      width: 130,
      sortable: false,
    },
    {
      field: "panchayatName",
      headerName: "Panchayat",
      width: 130,
      sortable: false,
    },
    {
      field: "wname",
      headerName: "Ward",
      width: 130,
      sortable: false,
    },
    {
      field: "subScheme",
      headerName: "Scheme Type",
      width: 130,
      sortable: false,
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      width: 130,
    },
    {
      field: "assignedOn",
      headerName: "Assigned On",
      width: 130,
      sortable: false,
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      sortable: false,
      // renderCell: (params) => {
      //   const data = params["formattedValue"]
      //   return <FormControlLabel
      //     control={<IOSSwitch checked={data} />}
      //     label={data ? "on" : "off"}
      //   />
      // },
      renderCell: (params) => {
        const data = params["formattedValue"]
        let actionWidget = <Button disabled={Boolean(data && data.installation_data)} onClick={() => handleClick(params["formattedValue"])}>Remove Assignee</Button>
        if(data && data.installation_data){
          actionWidget = <Tooltip title="Cannot remove assigned user, as the device is already in installation process."><span style={{color:"red"}}>Cannot remove</span></Tooltip>
        }
        return params["formattedValue"]?actionWidget:<></>
      }
    },
  ];

  return (
    <Card>
      <div style={{ height: 800, width: "100%" }} className={classes.root}>
        <DataGrid
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = tableData.filter((row) =>
              selectedIDs.has(row.id.toString())
            );
            props.onSelect(selectedRowData);
          }}
          rows={tableData}
          columns={columns}
          pageSize={40}
          rowsPerPageOptions={[5]}
          components={{
            Toolbar: CustomToolbar,
          }}
          isRowSelectable={(params) => !params.row.installation_user_mapping}
          componentsProps={{
            toolbar: { fileName: props.fileName, locationName: props.distName },
          }}
        />
      </div>

      {/* {lastPowerOn["status"] == "loading" ? <Loader /> : null} */}
    </Card>
  );
};

export default WardList;
