import {
    Box, Card, CardHeader, CardContent, Breadcrumbs, Button, Container, Grid, Link, Typography, TextField
} from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import useSettings from '../../../hooks/useSettings';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { resetUploadStatus, uploadCSV } from '../../../slices/uploadCSV';
import { useEffect, useState } from 'react';
import { getBlock, getDistrict, getDivision, getPanchayat, getWard } from '../../../slices/siteInfo';
import { Loader } from '../../../components/Loader/Loader';
import { checkIfRoleExist } from '../../../utils/helper';

const Admin = () => {
    const { settings } = useSettings();
    const dispatch = useDispatch();

    const [allDist, setAllDist] = useState([])
    const [allBlock, setAllBlock] = useState([])
    const [allPanchayat, setAllPanchayat] = useState([])
    const [allWard, setAllWard] = useState([])
    const [allDivision, setAllDivision] = useState([])
    const [calledUpload, setCalledUpload] = useState(false)

    const { district, block, panchayat, ward, division } = useSelector((state) => state.siteinfo);
    const { uploadCSV: uploadCSVStatus } = useSelector((state) => state.uploadCSV);
    const [currentDistrict, setCurrentDistrict] = useState(0)
    const [currentDivision, setCurrentDivision] = useState(0)

    useEffect(() => {
        if (uploadCSVStatus.status == "success") {
            alert("Success. It takes some time to reflect in UI. As it is processsing CSV.")
            dispatch(resetUploadStatus())
        }
    }, [uploadCSVStatus.status])

    const onChange = function (e) {
        if (!calledUpload) {
            if (currentDistrict > 0) {
                const file = e.target.files[0]
                if (file && (file['type'] == "text/csv" || file["type"] == "application/vnd.ms-excel")) {
                    const cDistObj = allDist.filter((e) => e.id == currentDistrict)
                    const cDivObj = allDivision.filter((e) => e.id == currentDivision)
                    if (cDistObj.length) {
                        dispatch(uploadCSV(e.target.files[0], currentDistrict, cDistObj[0]["name"], currentDivision, cDivObj[0]["name"]))
                    } else
                        alert("cDistObj length 0")
                } else {
                    alert("Please select CSV file")
                }
            } else {
                alert("Please select district")
            }
        } else {
            setCalledUpload(true)
        }
    };

    const handleDistrictChange = function (e) {
        setCurrentDistrict(e.target.value)
        dispatch(getDivision(e.target.value))
        // console.log(">>111", e.target.value)
    }

    const handleDivisionChange = function (e) {
        setCurrentDivision(e.target.value)
        // console.log(">>111", e.target.value)
    }

    useEffect(() => {
        dispatch(getDistrict())
    }, [])

    useEffect(() => {
        if (district.data)
            setAllDist(district.data)
    }, [district])

    useEffect(() => {
        if (division.data)
            setAllDivision(division.data)
    }, [division])

    useEffect(() => {
        if (block.data)
            setAllBlock(block.data)
    }, [block])

    useEffect(() => {
        if (block.data)
            setAllBlock(block.data)
    }, [block])

    useEffect(() => {
        if (panchayat.data)
            setAllPanchayat(panchayat.data)
    }, [panchayat])

    useEffect(() => {
        if (ward.data)
            setAllWard(ward.data)
    }, [ward])

    return (
        <>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={3}>
                    <Grid item md={12}>
                        <Card>
                            <CardHeader title="Upload file (CSV)" />
                            <CardContent>
                                { !checkIfRoleExist(localStorage.getItem("USER_TYPE"), ["APP_DATA_MANAGEMENT", "APP_SUPER_ADMIN"]) && <span style={{ color: "red" }}>Only Admin user can upload ward details</span>}

                                <Grid container spacing={3}>
                                    <Grid item md={12}>
                                        <Grid container spacing={3}>
                                            <Grid item md={2}>
                                                <TextField
                                                    fullWidth
                                                    label="District"
                                                    name="district"
                                                    onChange={handleDistrictChange}
                                                    select
                                                    disabled={!checkIfRoleExist(localStorage.getItem("USER_TYPE"), ["APP_DATA_MANAGEMENT", "APP_SUPER_ADMIN"])}
                                                    SelectProps={{ native: true }}
                                                    value={currentDistrict.id}
                                                    variant="outlined"
                                                >
                                                    {[{ id: 0, name: "" }, ...allDist].map((category) => (
                                                        <option
                                                            key={category.id}
                                                            value={category.id}
                                                        >
                                                            {category.name}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item md={2}>
                                                <TextField
                                                    fullWidth
                                                    label="Division"
                                                    name="division"
                                                    onChange={handleDivisionChange}
                                                    select
                                                    disabled={!checkIfRoleExist(localStorage.getItem("USER_TYPE"), ["APP_DATA_MANAGEMENT", "APP_SUPER_ADMIN"])}
                                                    SelectProps={{ native: true }}
                                                    value={currentDivision.id}
                                                    variant="outlined"
                                                >
                                                    {[{ id: 0, name: "" }, ...allDivision].map((category) => (
                                                        <option
                                                            key={category.id}
                                                            value={category.id}
                                                        >
                                                            {category.name}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item md={4}>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    size="large"
                                                    sx={{ mr: 3 }}
                                                    disabled={!checkIfRoleExist(localStorage.getItem("USER_TYPE"), ["APP_DATA_MANAGEMENT", "APP_SUPER_ADMIN"])}>
                                                    Choose CSV File
                                                    <input
                                                        type="file"
                                                        onChange={onChange}
                                                        hidden
                                                    />
                                                </Button>

                                                <a href="https://storageaccounthires8e69.blob.core.windows.net/templates/upload_template.csv?sp=r&st=2022-06-19T14:58:35Z&se=2023-06-19T22:58:35Z&spr=https&sv=2021-06-08&sr=b&sig=Lck28jLXKeMSDoUzfC9CofAeaRmRiql3%2FJgc6L9zm0E%3D">Download Template</a>
                                            </Grid>
                                            <Grid item md={12}>
                                                {uploadCSVStatus.status && uploadCSVStatus.status != "failed" ? uploadCSVStatus.status : ""}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            {uploadCSVStatus.status == "loading" && <Loader />}
        </>
    )
}

export default Admin