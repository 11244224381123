import { Card, CardHeader, Grid, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import FunctionalityUtilisation from "../../../../pages/dashboard/_functionality_utilisation"
import { getScheduledData } from "../../../../slices/scheduledData"
import { historyDate } from "../../../../utils/helper"
import DeviceDataDump from "../../../../pages/dashboard/DeviceDataDump"

const IOTEventReport = function (props) {

    const dispatch = useDispatch()
    const [scheduleData, setScheduleData] = useState({})
    const { scheduled: scheduled } = useSelector((state) => state.scheduledData);

    useEffect(() => {
        if (scheduled["status"] == "SUCCESS") {
            setScheduleData(scheduled["data"])
        }
    }, [scheduled])

    useEffect(() => {
        const oneScheme = props.schemeData
        const did = oneScheme["smsid"] ? oneScheme["smsid"] : ""
        if (did) {
            const h = historyDate(0, "currentDay")
            dispatch(getScheduledData(did, h))
        }
    }, [props.schemeData])

    const schemeData = props.schemeData
    return <Grid
        container
        spacing={3}
    >
        <Grid
            item
            md={3}
            xs={12}
        >
            <Card>
                <CardHeader title="Information" />
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Division
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {schemeData["division"]}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Block
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {schemeData["blockName"]}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Panchayat
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {schemeData["panchayatName"]}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Ward
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    Ward-{schemeData["name"]}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Scheme Code
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {schemeData["schemeId"]}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Device ID
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {schemeData["other"]["deviceid"].padStart(5, '0')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Card>
        </Grid>
        <Grid
            item
            md={9}
            xs={12}
        >
            <FunctionalityUtilisation scheduleData={scheduleData} type={"verticle"} />
        </Grid>
        <Grid item md={12}>
            {props.schemeData ?
                <DeviceDataDump wardData={props.schemeData} assignmentType="New Device Installation" />
                : null}
        </Grid>
    </Grid>
}

export default IOTEventReport