import { Box, Container } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import { useLocation } from "react-router";
import PageHeading from "../../../../components/PageHeading";
import { title } from "../../../../constants";
import useSettings from "../../../../hooks/useSettings";
import { useState } from "react";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { genearteDeviceInstallationReport, getDeviceLastData } from "../../FirmwareTestSteps/_service";
import { capitalizeFirstLetter, getDateTime, getDeviceDataFlag, getInstallationDate, getSGMStrength } from "../../../../utils/helper";
import { getAllDeviceTelemetry, getDeviceTelemetry } from '../../../../slices/debugging';
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../../components/Loader/Loader";
import { get, has } from "lodash";
import DeviceDataDump from "../../DeviceDataDump";

function getInstallationTestStatus(wardData) {
  if (wardData && wardData.installation_data && wardData.regData) {
    if (wardData.regData.installation_testing_status)
      return capitalizeFirstLetter(wardData.regData.installation_testing_status.overall)
    else
      return "Fail"
  } else {
    return "Pending"
  }
}

function getInstallationStatus(wardData) {
  if (wardData && wardData.installation_data) {
    if (wardData && wardData.verification && wardData.verification.status) {
      return capitalizeFirstLetter(wardData.verification.status)
    } else {
      return "Pending For Approval"
    }
  } else {
    return "Yet to install !!"
  }
}

function InstallationDeviceInfo() {
  const location = useLocation();
  const settings = useSettings();
  const [assignmentType, setAssignementType] = useState("");
  const [wardData, setWardData] = useState({});
  const [gsmStrength, setGSMStrength] = useState("");

  useEffect(() => {
    const assignmentType = location.state.assignmentType
      ? location.state.assignmentType
      : "-";
    setWardData(location.state.wardData);
    setAssignementType(assignmentType);

    if (has(location.state, 'wardData.fw_device_id', null)) {
      getDeviceLastData(location.state.wardData.fw_device_id).then((e) => {
        if (e.data && e.data.g) setGSMStrength(getSGMStrength(e.data.g));
      });
    }

  }, [location.state]);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "left",
    height: "100%",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <Helmet>
        <title>{title.label2} | Installation Device Details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <PageHeading
            title={"Installation Device Details"}
            prevPath={[{ name: "Installation Device Details" }]}
          />
          <Box sx={{ py: 3 }}>
            <Grid container spacing={1}>
              <Grid item md={1}>
                <Typography color="textPrimary" variant="subtitle2">
                  Assignment Type:
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography
                  style={{ fontWeight: "500", color: "#777" }}
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {assignmentType}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ py: 2 }}>
            <Grid container spacing={1}>
              <Grid item md={4}>
                <Item>
                  <Typography color="textPrimary" variant="subtitle2">
                    Site Details
                  </Typography>

                  <Box sx={{ py: 2 }}>
                    <Grid container>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          {" "}
                          District:{" "}
                        </Typography>{" "}
                        <span style={{ marginLeft: 8 }}>
                          {wardData.districtName}
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Division:{" "}
                        </Typography>{" "}
                        <span style={{ marginLeft: 8 }}>
                          {wardData.division}
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Block:{" "}
                        </Typography>{" "}
                        <span style={{ marginLeft: 8 }}>
                          {" "}
                          {wardData.blockName}
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Panchayat:{" "}
                        </Typography>{" "}
                        <span style={{ marginLeft: 8 }}>
                          {wardData.panchayatName}
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Ward:{" "}
                        </Typography>{" "}
                        <span style={{ marginLeft: 8 }}> {wardData.name}</span>
                      </Grid>
                    </Grid>
                  </Box>
                </Item>
              </Grid>
              <Grid item md={4}>
                <Item>
                  <Typography color="textPrimary" variant="subtitle2">
                    TE Details
                  </Typography>
                  <Box sx={{ py: 2 }}>
                    <Grid container>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          TE Name:
                        </Typography>
                        <span style={{ marginLeft: 8 }}>
                          {wardData.installation_user_mapping
                            ? wardData.installation_user_mapping.name
                            : ""}
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          TE Mobile Number:
                        </Typography>
                        <span style={{ marginLeft: 8 }}>
                          {wardData.installation_user_mapping
                            ? wardData.installation_user_mapping.mobile_number
                            : "Not Mapped"}
                        </span>
                      </Grid>
                    </Grid>
                  </Box>
                </Item>
              </Grid>
              <Grid item md={4}>
                <Item>
                  <Typography color="textPrimary" variant="subtitle2">
                    Device Details
                  </Typography>
                  <Box sx={{ py: 2 }}>
                    <Grid container>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Device Barcode:
                        </Typography>
                        <span style={{ marginLeft: 8 }}>
                          {wardData.regData ? wardData.regData.barcode : ""}
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Date of Installation:
                        </Typography>
                        <span style={{ marginLeft: 8 }}>
                          {getInstallationDate(wardData)}
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Installation status:
                        </Typography>
                        <span style={{ marginLeft: 8 }}>
                          {getInstallationStatus(wardData)}
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Installation test status:
                        </Typography>
                        <span style={{ marginLeft: 8 }}>
                          {getInstallationTestStatus(wardData)}
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Network Status:
                        </Typography>
                        <span style={{ marginLeft: 8 }}>{gsmStrength}  (last received)</span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          SIM Number:
                        </Typography>
                        <span style={{ marginLeft: 8 }}>{wardData.regData ? wardData.regData.simNumber : ""}</span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Carrier Name:
                        </Typography>
                        <span style={{ marginLeft: 8 }}>{wardData.regData ? wardData.regData.carrierName : ""}</span>
                      </Grid>
                    </Grid>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Box>

          <DeviceDataDump wardData={wardData} assignmentType={assignmentType} />
        </Container>
      </Box>
    </>
  );
}

export default InstallationDeviceInfo;
