export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};

export const API = {
  base_url: process.env.NODE_ENV == 'production' ? "https://hidashboardapi.azurewebsites.net/api/" : 'http://localhost:5000/api/',
  // static_url: process.env.NODE_ENV == 'production' ? "https://rollzddbapi.azurewebsites.net/static/" : 'http://localhost:5000/static/'
}
const getSubdomain = url => {
  let domain = url;
  if (url.includes("://")) {
      domain = url.split('://')[1];
  }
  const subdomain = domain.split('.')[0];
  return subdomain;
};
let subdomain = getSubdomain(window.location.origin)
export const BASE_URL = process.env.NODE_ENV == 'production' ? `https://${subdomain}.hidashboard.com` : 'http://localhost:3000'