import { Card, Grid, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core"
import useSettings from "../../../../hooks/useSettings"
import SiteInfoImages from "./SiteInfoImages";
import SiteInfoMap from "./SiteInfoMap";
import _, { get, has } from "lodash";
import { INSTALLATION_REPORT_OPTIONS, priColor } from "../../../../constants";
import { getSGMStrength, getSGMStrengthColor } from "../../../../utils/helper";

const SiteInfoSection = function (props) {
    const { settings } = useSettings();
    const schemeData = props.schemeData
    const smsid = schemeData["smsid"]

    let locInfo = schemeData["other"]
    if (schemeData) {
        if (has(schemeData, "survey_data.location.coords")) {
            locInfo = { lng: schemeData.survey_data.location.coords.longitude, lat: schemeData.survey_data.location.coords.latitude }
        }
    }

    let anurakshakName = get(schemeData, "survey_data.anurakshak.name", "-");
    let anurakshakPhone = get(schemeData, "survey_data.anurakshak.mobile_number", "-");

    console.log(schemeData)
    let i_remark = INSTALLATION_REPORT_OPTIONS.find((e) => e.value == get(schemeData, "survey_data.installation_remark", null))
    let remarkValue = (i_remark && i_remark.value == "other") ? get(schemeData, "survey_data.extra_input", "Other (comment not given)") :get(i_remark, "label", "-") 

    const ss_status = [{
        title: "Borewell / Motor Status",
        value: get(schemeData, "survey_data.borewell.status", false) ? "Working" : "Not Working",
        color: get(schemeData, "survey_data.borewell.status", false) ? "green" : "red"
    },
    {
        title: "Electricity Meter",
        value: get(schemeData, "survey_data.electricity_meter.status", false) ? "Working" : "Not Working",
        color: get(schemeData, "survey_data.electricity_meter.status", false) ? "green" : "red"
    },
    {
        title: "Distribution line",
        value: get(schemeData, "survey_data.distribution_line.status", false) ? "Ok" : "Not Ok",
        color: get(schemeData, "survey_data.distribution_line.status", false) ? "green" : "red"
    },
    {
        title: "Starter Status",
        value: get(schemeData, "survey_data.starter.status", false) ? "Working" : "Not Working",
        color: get(schemeData, "survey_data.starter.status", false) ? "green" : "red"
    },
    {
        title: "Network Status",
        value: getSGMStrength(schemeData.network),
        color: getSGMStrengthColor(schemeData.network)
    },
    {
        title: "Installation Remarks",
        value: remarkValue ? remarkValue : 'No Remark',
        color: get(schemeData, "survey_data.installation_remark", "nr") == "nr" ? "green" : "red"
    }
    ]
    return <Grid
        container
        spacing={3}
    >
        <Grid
            item
            lg={settings.compact ? 6 : 4}
            md={6}
            xl={settings.compact ? 6 : 3}
            xs={12}
        >
            <Card>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Site Name
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {schemeData["blockName"]}-{schemeData["panchayatName"]}-{schemeData["subScheme"]}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    District
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {schemeData["districtName"]}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Division
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {schemeData["divisionName"]}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Block
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {schemeData["blockName"]}

                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Panchayat
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {schemeData["panchayatName"]}

                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Ward
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    Ward - {schemeData["name"]}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Scheme/Sub-Scheme
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {schemeData["subScheme"]}
                                </Typography>
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </Card>
        </Grid>
        <Grid
            item
            lg={settings.compact ? 6 : 4}
            md={6}
            xl={settings.compact ? 6 : 3}
            xs={12}
        >
            <Card>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Device ID
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    { _.get(schemeData, "regData.barcode", `D-${schemeData["other"]["deviceid"].padStart(5, '0')}`)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Scheme Code
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {schemeData["subScheme"]}-{schemeData["schemeId"]}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Anurakshak
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {anurakshakName}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Anurakshak Contact
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {anurakshakPhone}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        {/* <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Contractor Name
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {"-"}
                                </Typography>
                            </TableCell>
                        </TableRow> */}
                        {/* <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Contractor No.
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {"-"}
                                </Typography>
                            </TableCell>
                        </TableRow> */}
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Targeted Household
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {schemeData["household"]}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    FHTC
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {schemeData["fhtc"]}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Motor HP
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {schemeData["motorCapacity"]}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Card>
        </Grid>
        <Grid item md={12}>
            <Card sx={{ p: 2 }}>
                <Typography color={priColor} fontWeight={"bold"}>Scheme / Site Status</Typography>
                <Grid container mt={3} spacing={2}>
                    {ss_status.map((e, i) => {
                        return <Grid
                            item md={2}
                            textAlign={"center"}
                            display={"flex"}
                            flexDirection={"column"}
                            sx={i < ss_status.length - 1 ? { borderRight: "1px solid grey" } : {}}>
                            <Typography color={"#CCC"} fontWeight={"bold"}>{e.title}</Typography>
                            <Typography fontWeight={"bold"} color={e.color}>{e.value}</Typography>
                        </Grid>
                    })}
                </Grid>
            </Card>
        </Grid>
        <Grid
            item
            lg={settings.compact ? 6 : 4}
            md={6}
            xl={settings.compact ? 6 : 3}
            xs={12}
        >
            <SiteInfoMap locationinfo={locInfo} />
        </Grid>
        <Grid
            item
            lg={settings.compact ? 6 : 4}
            md={6}
            xl={settings.compact ? 6 : 3}
            xs={12}
        >
            <SiteInfoImages wardData={schemeData} />
        </Grid>

    </Grid>
}

export default SiteInfoSection