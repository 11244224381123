import {
    Box,
    Breadcrumbs,
    Link,
    Container,
    Grid,
    Typography,
    ButtonGroup,
    Button,
  } from "@material-ui/core";
  import { Helmet } from "react-helmet-async";
  import { useDispatch, useSelector } from "react-redux";
  import { LocationFilter } from "../../../components/dashboard/shemes";
  import useSettings from "../../../hooks/useSettings";
  import ChevronRight from "../../../icons/ChevronRight";
  import { Link as RouterLink } from "react-router-dom";
  import { useState } from "react";
  import PageHeading from "../../../components/PageHeading";
  import { getSchemes, resetSchemes, setFilters } from "../../../slices/siteInfo";
  import { resetOpData } from "../../../slices/schemesDeviceOperational";
  import AssignedList from "./installation-report/assignedList";
  import { useEffect } from "react";
  import { historyDate } from "../../../utils/helper";
  import NotAssignedList from "./installation-report/notAssignedList";
import ManualEntrySchemeList from "./manual-installation/ManualEntrySchemeList";
  
  // const menus = [
  //   "New Device Installation",
  //   "Device Replacement",
  //   "SIM Replacement",
  // ];
  function InstallationManualEntry() {
    const [menuId, setMenuId] = useState(0);
    const { settings } = useSettings();
    const { district, schemes, lastSearchFilter } = useSelector(
      (state) => state.siteinfo
    );
    const [allSchemes, setAllSchemes] = useState([]);
    const [assignedSchemes, setAssignedSchemes] = useState([]);
    const [notAssignedSchemes, setNotAssignedSchemes] = useState([]);
    const [completedSchemes, setCompletedSchemes] = useState([]);
    const [pendingSchemes, setPendingSchemes] = useState([]);
    const [installPending, setInstallPending] = useState([]);
    const [surveyPendingState, setSurveyPendingState] = useState([]);
    const [verifPending, setVerifPending] = useState([]);
    const [rejectedSchemes, setRejectedSchemes] = useState([]);
  
    const dispatch = useDispatch();
    const [schemeLocation, setSchemeLocation] = useState({});
    const onChangeLocation = (data) => {
      const keys = Object.keys(data);
      let allReset = true;
      for (let i = 0; i < keys.length; i++) {
        allReset = allReset && !data[keys[i]];
      }
  
      if (allReset) {
        setAllSchemes([]);
        dispatch(resetSchemes());
        dispatch(resetOpData());
      }
      setSchemeLocation(data);
    };
  
    const sendGetSchemeReq = () => {
      let d = {};
      if (schemeLocation.district) {
        d["district"] = schemeLocation.district;
      }
      if (schemeLocation.division) {
        d["division"] = schemeLocation.division;
      }
      if (schemeLocation.block) {
        d["block"] = schemeLocation.block;
      }
      if (schemeLocation.panchayat) {
        d["panchayat"] = schemeLocation.panchayat;
      }
      if (schemeLocation.ward) {
        d["ward"] = schemeLocation.ward;
      }
      dispatch(setFilters(schemeLocation));
      dispatch(getSchemes(d, historyDate(0, "currentDay")));
    };
  
    useEffect(() => {
      if (
        schemeLocation.district ||
        schemeLocation.block ||
        schemeLocation.panchayat ||
        schemeLocation.ward
      ) {
        sendGetSchemeReq();
        // console.log(">>", props.districtId, schemeLocation)
        //   dispatch(getShcemeOperationalData(d, historyDate(3, "currentDay")));
      }
    }, [schemeLocation]);
  
    useEffect(() => {
      setAllSchemes(schemes["data"]);
      const schemesList = schemes["data"];
      const allAssignedSchemes = schemesList.filter(
        (e) => e.installation_user_mapping
      );
      setAssignedSchemes(allAssignedSchemes);
  
      const yetToAssignSchemes = schemesList.filter(
        (e) => !e.installation_user_mapping
      );
      setNotAssignedSchemes(yetToAssignSchemes);
  
      const completed = allAssignedSchemes.filter(
        (e) => e.verification && e.verification.status == "Approved"
      );
      setCompletedSchemes(completed);
  
      const siteSurveyPending = allAssignedSchemes.filter((e) => !e.survey_data);
      const installationPending = allAssignedSchemes.filter(
        (e) => e.survey_data && !e.installation_data
      );
      const verificationPending = allAssignedSchemes.filter(
        (e) => e.survey_data && e.installation_data && !e.verification
      );
      const rejectedSchemes = allAssignedSchemes.filter(
        (e) => e.installation_data && e.installation_data.rejected_by
      );
      setSurveyPendingState(siteSurveyPending);
      setInstallPending(installationPending);
      setVerifPending(verificationPending);
      setRejectedSchemes(rejectedSchemes);
      setPendingSchemes([
        ...siteSurveyPending,
        ...installationPending,
        ...verificationPending,
      ]);
    }, [schemes]);
    return (
      <>
        <Helmet>
          <title></title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: "background.default",
            minHeight: "100%",
            py: 2,
          }}
        >
          <Container maxWidth={settings.compact ? "xl" : false}>
            <PageHeading
              title={"Manually upload Installation Data"}
              prevPath={[{ name: "Manually upload Installation Data" }]}
            />
            {/* <Box sx={{ py: 3 }}>
              <ButtonGroup aria-label="small outlined button group">
                {menus.map((m, i) => {
                  return (
                    <Button
                      key={i}
                      color="primary"
                      variant={menuId == i ? "contained" : ""}
                      onClick={() => (i > menus.length ? null : setMenuId(i))}
                    >
                      {m}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Box> */}
            <Box sx={{ py: 2, pt:4}}>
              <LocationFilter
                onChange={onChangeLocation}
                initialValues={lastSearchFilter}
                schemes={allSchemes}
                downloadExcel={true}
              />
            </Box>
            <Grid container spacing={3}>
              <Grid item xl={12} md={12} xs={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirectio: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ fontWeight: "bold", color: "#999" }}>
                    Total :
                    <span style={{ marginLeft: 5, color: "blue" }}>
                      {allSchemes.length}
                    </span>
                  </div>
  
                  <div style={{ fontWeight: "bold", color: "#999" }}>
                    Yet to Assign :
                    <span style={{ marginLeft: 5, color: "blue" }}>
                      {notAssignedSchemes.length}
                    </span>
                  </div>
  
                  <div style={{ fontWeight: "bold", color: "#999" }}>
                    Assigned :
                    <span style={{ marginLeft: 5, color: "blue" }}>
                      {assignedSchemes.length}
                    </span>
                  </div>
  
                  <div style={{ fontWeight: "bold", color: "#999" }}>
                    Completed :
                    <span style={{ marginLeft: 5, color: "green" }}>
                      {completedSchemes.length}
                    </span>
                  </div>
  
                  <div style={{ fontWeight: "bold", color: "#999" }}>
                    Rejected :
                    <span style={{ marginLeft: 5, color: "red" }}>
                      {rejectedSchemes.length}
                    </span>
                  </div>
  
                  <div style={{ fontWeight: "bold", color: "#999" }}>
                    Pending :
                    <span style={{ marginLeft: 5, color: "red" }}>
                      {pendingSchemes.length}
                    </span>
                    <span style={{ marginLeft: 5 }}>
                      [Site Survey Pending {surveyPendingState.length}, Installation Pending:{" "}
                      {installPending.length}, Approval Pending: {verifPending.length}
                      ]
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid item xl={12} md={12} xs={12}>
                  <ManualEntrySchemeList
                    title={"Assigned a TE:"}
                    sx={{ height: "100%" }}
                    schemes={assignedSchemes}
                  />
              </Grid>              
            </Grid>
          </Container>
        </Box>
      </>
    );
  }
  
  export default InstallationManualEntry;
