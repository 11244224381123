import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Formik } from "formik";
import { useEffect } from "react";
import PageHeading from "../../components/PageHeading";
import useAuth from "../../hooks/useAuth";
import useSettings from "../../hooks/useSettings";
import * as Yup from "yup";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import {
  generateOTP,
  generateOTPForNewNumber,
  resetUserManagement,
  updateUserData,
  verifyOtpForNewNumber,
} from "../../slices/userManagement";
import ChangePasswordView from "./ChangePasswordView";

function Profile() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(user);
  const [updatedUserData, setUpdatedUserData] = useState({});
  const [mobileNumber, setMobileNumber] = useState(
    (user && user.mobile_number) ?? ""
  );
  const {
    generateOtpForNewNumberResponse: generateOTPResponse,
    updateUserDataRespone,
    votpForNewNumberResponse,
  } = useSelector((state) => state.userManagement);
  const [resendOtpState, setResetOtpState] = useState(false);
  const [sendOtpRespMessage, setSendOtpRespMessage] = useState({
    type: null,
    message: "",
    fields: [],
  });
  const [resendOtpEnabled, setResendOtpEnabled] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [userDataUpdateMessage, setUserDataUpdateMessage] = useState({
    type: null,
    message: "",
    fields: [],
  });
  const [votpRespMessage, setVotpRespMessage] = useState({
    type: null,
    message: "",
    fields: [],
  });

  useEffect(() => {
    if (user && user.name) {
      setUserData(user);
    }
  }, [user]);

  useEffect(() => {
    let message = "",
      type = null,
      fields = [];
    if (generateOTPResponse) {
      if (generateOTPResponse.statusCode == 200) {
        setShowOTP(true);
        setTimeout(() => {
          setResendOtpEnabled(true);
        }, 4000);
        message = generateOTPResponse.message + ".";
        type = "success";
      } else if (generateOTPResponse.statusCode == 400) {
        message = generateOTPResponse.message;
        type = "error";
        if (generateOTPResponse.errors && generateOTPResponse.errors.length)
          fields = generateOTPResponse.errors.map((re) => {
            return <li>{re.msg}</li>;
          });
      } else {
        message = generateOTPResponse.message;
        type = "error";
      }
    }
    setSendOtpRespMessage({ type, message, fields });
  }, [generateOTPResponse]);

  useEffect(() => {
    let message = "",
      type = null,
      fields = [];
    if (updateUserDataRespone) {
      if (updateUserDataRespone.statusCode == 200) {
        // setShowOTP(false);
        // setResetOtpState(true);
        // setTimeout(() => {
        //   setResetOtpState(false);
        // }, 60000);
        message = updateUserDataRespone.message + ".";
        type = "success";
      } else if (updateUserDataRespone.statusCode == 400) {
        message = updateUserDataRespone.message;
        type = "error";
        if (updateUserDataRespone.errors && updateUserDataRespone.errors.length)
          fields = updateUserDataRespone.errors.map((re) => {
            return <li>{re.msg}</li>;
          });
      } else {
        message = updateUserDataRespone.message;
        type = "error";
      }
    }
    setUserDataUpdateMessage({ type, message, fields });
  }, [updateUserDataRespone]);

  useEffect(() => {
    let message = "",
      type = null,
      fields = [];
    if (votpForNewNumberResponse) {
      if (votpForNewNumberResponse.statusCode == 200) {
        setShowOTP(false);
        dispatch(updateUserData(updatedUserData));
        // setResetOtpState(true);
        // setTimeout(() => {
        //   setResetOtpState(false);
        // }, 60000);
        message = votpForNewNumberResponse.message + ".";
        type = "success";
      } else if (votpForNewNumberResponse.statusCode == 400) {
        message = votpForNewNumberResponse.message;
        type = "error";
        if (
          votpForNewNumberResponse.errors &&
          votpForNewNumberResponse.errors.length
        )
          fields = votpForNewNumberResponse.errors.map((re) => {
            return <li>{re.msg}</li>;
          });
      } else {
        message = votpForNewNumberResponse.message;
        type = "error";
      }
    }
    setVotpRespMessage({ type, message, fields });
  }, [votpForNewNumberResponse]);

  useEffect(() => {
    return () => {
        dispatch(resetUserManagement())
    }
  }, [])
  
  const { settings } = useSettings();
  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={settings.compact ? "md" : false}>
          <PageHeading
            title={"Profile"}
            prevPath={[{ name: "Edit Profile" }]}
          />
          <Box sx={{ py: 3 }}>
            <Formik
              initialValues={{
                name: userData.name,
                email: userData.email,
                mobile_number: userData.mobile_number,
                dob: dayjs(userData.dob, "DD/MM/YYYY"),
                organization: userData.organization,
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255, "Max 255 length allowed")
                  .required("Email is required"),
                name: Yup.string().max(255).required("Name is required"),
                mobile_number: Yup.string()
                  .required("Mobile number is required")
                  .matches(/^[0-9]{10}$/, "Must be 10 digits"),
                dob: Yup.date().required("DOB is required"),
                organization: Yup.string()
                  .max(255)
                  .required("Organization name is required"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                
                let updatedValues = {
                  ...values,
                  dob: dayjs(values.dob).format("DD/MM/YYYY"),
                };
                setMobileNumber(updatedValues["mobile_number"]);
                try {
                  setUpdatedUserData(updatedValues);
                  dispatch(
                    generateOTPForNewNumber({
                      new_mobile_number: updatedValues.mobile_number,
                    })
                  );
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={2} style={{ marginTop: 0 }}>
                    <Grid item md={6}>
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        autoFocus
                        fullWidth
                        label="Name"
                        margin="normal"
                        name="name"
                        variant="outlined"
                        value={values.name}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        error={Boolean(
                          touched.mobile_number && errors.mobile_number
                        )}
                        helperText={
                          touched.mobile_number && errors.mobile_number
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        label="Mobile Number"
                        margin="normal"
                        name="mobile_number"
                        variant="outlined"
                        value={values.mobile_number}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        label="Email Id (This will be your Username) "
                        margin="normal"
                        disabled
                        type="email"
                        name="email"
                        variant="outlined"
                        value={values.email}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          label="Date of Birth"
                          inputFormat="DD/MM/YYYY"
                          name="dob"
                          value={values.dob}
                          disableFuture
                          onChange={(value) => {
                            setFieldValue("dob", value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              style={{ marginTop: 16, marginBotton: 8 }}
                              {...params}
                              error={Boolean(touched.dob && errors.dob)}
                              helperText={touched.dob && errors.dob}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  <TextField
                    error={Boolean(touched.organization && errors.organization)}
                    helperText={touched.organization && errors.organization}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    label="Organization"
                    margin="normal"
                    name="organization"
                    variant="outlined"
                    value={values.organization}
                  />
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      //   disabled={showOTP}
                      //   fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Update
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>

            {sendOtpRespMessage.type ? (
              <div
                style={{
                  color: sendOtpRespMessage.type == "success" ? "green" : "red",
                }}
              >
                <p>{sendOtpRespMessage.message}</p>
                <ul>{sendOtpRespMessage.fields}</ul>
              </div>
            ) : (
              ""
            )}
            {showOTP && (
              <Formik
                initialValues={{
                  otp: "",
                }}
                validationSchema={Yup.object().shape({
                  otp: Yup.string().required("OTP is required"),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  // console.log(">>", values)
                  dispatch(
                    verifyOtpForNewNumber({
                      ...values,
                      new_mobile_number: updatedUserData.mobile_number,
                    })
                  );
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item md={4}>
                        <TextField
                          margin="normal"
                          fullWidth
                          error={Boolean(touched.otp && errors.otp)}
                          helperText={touched.otp && errors.otp}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="otp"
                          label="Enter OTP"
                          value={values.otp}
                        />
                      </Grid>
                      <Grid item md={1} style={{ marginTop: 16 }}>
                        <Button
                          // disabled={canLogin}
                          style={{ padding: 14 }}
                          type="submit"
                          variant="contained"
                          onClick={() => {
                            // setShowOTP(true);
                          }}
                        >
                          Verify
                        </Button>
                      </Grid>
                      <Grid item md={2} style={{ marginTop: 16 }}>
                        {resendOtpEnabled ? (
                          <Button
                            style={{ padding: 14 }}
                            disabled={resendOtpState}
                            onClick={() => {
                              dispatch(
                                generateOTPForNewNumber({
                                  new_mobile_number:
                                    updatedUserData.mobile_number,
                                })
                              );
                            }}
                          >
                            Resend OTP
                          </Button>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            )}
            {votpRespMessage.type ? (
              <div
                style={{
                  color: votpRespMessage.type == "success" ? "green" : "red",
                }}
              >
                <p>{votpRespMessage.message}</p>
                <ul>{votpRespMessage.fields}</ul>
              </div>
            ) : (
              ""
            )}
            {userDataUpdateMessage.type ? (
              <div
                style={{
                  color:
                    userDataUpdateMessage.type == "success" ? "green" : "red",
                }}
              >
                <p>{userDataUpdateMessage.message}</p>
                <ul>{userDataUpdateMessage.fields}</ul>
              </div>
            ) : (
              ""
            )}
          </Box>
        </Container>
        <Container maxWidth={settings.compact ? "md" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Change Password
              </Typography>
            </Grid>
          </Grid>
          <ChangePasswordView />
        </Container>
      </Box>
    </>
  );
}

export default Profile;
