import {Card, CardHeader} from "@material-ui/core";
import Step0_steps from "./step0_steps";

const LoadFormware = function (props) {
    return (
        <Card style={{ marginTop: 20 }}>
            <CardHeader title={"Please follow SOP for loading firmware to the IOT Enabled Water Monitoring Module"} />
            <div style={{ margin: 20 }}>
                <Step0_steps next={props.next} updateDeviceData={props.updateData} />
            </div>
        </Card>
    );
};

export default LoadFormware;