import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert,
  Box,
  Button,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import useAuth from "../../../hooks/useAuth";
import useMounted from "../../../hooks/useMounted";
import { API } from "../../../config";
import { useState } from "react";

const WIPButtor = ()=> {
  const [color, setColor] = useState("#03a9f4")
  return <div onClick={() => setColor("red")} style={{ width:'100%', borderRadius:4, padding:10, backgroundColor:"rgb(229, 246, 253)", color}}>Not Ready Yet. Work In Progress..</div>
}

const LoginJWT = (props) => {
  const mounted = useMounted();
  const { login } = useAuth();

  return (<>
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await login(values.email, values.password);
          
          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: "Username and Password did not match" });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            { API.base_url && 
             <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Log In
            </Button> }
          </Box>
          
          {/* <Box sx={{ mt: 2 }}>
            <Alert severity="info">
              <div>
                Use
                {' '}
                <b>demo@devias.io</b>
                {' '}
                and password
                {' '}
                <b>Password123!</b>
              </div>
            </Alert>
          </Box> */}
        </form>
      )}
    </Formik>
    { !(API.base_url) &&
     <WIPButtor />}
    </>
  );
};

export default LoginJWT;
