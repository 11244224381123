import { Box, Container } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import { useLocation } from "react-router";
import PageHeading from "../../../../components/PageHeading";
import {
  INSTALLATION_REPORT,
  INSTALLATION_REPORT_OPTIONS,
  priColor,
  title,
} from "../../../../constants";
import useSettings from "../../../../hooks/useSettings";
import { useState } from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import {
  capitalizeFirstLetter,
  getDateTime,
  getSGMStrength,
} from "../../../../utils/helper";
import {
  getAllDeviceTelemetry,
} from "../../../../slices/debugging";
import _, { get, startCase, toString } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import DialogUI, { DialogPopup } from "../../../../components/ImageViewer";
import { Formik } from "formik";
import * as Yup from "yup";
import { checkIfBarCodeAssignedToWard, linkDeviceToWardAction, linkDeviceUpdateWardStatus, resetManualLinking, updateFirmwareData } from "../../../../slices/firmwareRegistration";
import { FormControlLabel, Radio, RadioGroup, Tooltip } from "@mui/material";
import { InfoSharp } from "@material-ui/icons";
import { saveSurveyDetails } from "../../../../slices/siteInfo";
import { fileUpload, manualUpdateWardImages } from "./_fileUpload";

function getInstallationDate(wardData) {
  if (
    wardData &&
    wardData.installation_data &&
    wardData.installation_data.status &&
    wardData.installation_data.status.date
  ) {
    return wardData.installation_data.status.date;
  } else {
    const datetime = getDateTime(wardData._ts);
    return `${datetime["date"]} ${datetime["time"]}`;
  }
}

// function TestItem({ data }) {
//   const status = data.toLowerCase().includes("pass");
//   return (
//     <span
//       style={{
//         marginLeft: 8,
//         fontWeight: "bold",
//         color: status ? "green" : "red",
//       }}
//     >
//       {data}
//     </span>
//   );
// }

function OtherDetailsItem(props) {
  // const status = _.has(props.wardData, `survey_data.${props.item}.status`)
  //   ? props.options[props.wardData.survey_data[props.item]["status"]]
  //   : "";

  return (
    <Grid item md={12} style={{ display: "flex", flexDirection: "row" }}>
      <Typography color="textSecondary" variant="subtitle2">
        {props.title}
      </Typography>
      <span
        style={{
          marginLeft: 8,
          fontWeight: "bold"
        }}
      >
        {/* {status} */}
        {/* <input type="text" style={{width: 150}}/> */}
        <select style={{ borderColor: props.error ? "red" : "" }} name={props.name} value={props.value} onChange={props.onChange} onBlur={props.onBlur}>
          <option value="">Select Value</option>
          {props.options.map((e) => <option value={e.value}>{e.label}</option>)}
        </select>
      </span>
    </Grid>
  );
}

function InstallationReportDeviceInfoManualEntry() {
  const [siteImageObject, setSiteImageObject] = useState({
    "anurakshak": null,
    "site": null,
    "borewell motor": null,
    "electricity meter": null,
    "distribution line": null,
    "starter": null,
    "deviceImage": null
  })
  const location = useLocation();
  const dispatch = useDispatch()
  const { barCodeAssignedToWard, linkDeviceToWard, manualLinkingUpdateWardStatus, updateFirmwareState} = useSelector(
    (state) => state.firmwareRegistration
  );
  const { saveSurveyDetailsData } = useSelector((state) => state.siteinfo);
  const { allDeviceTelemetry: allDeviceData } = useSelector((state) => state.debugging);
  const [open, setOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState(null);
  const settings = useSettings();
  const [wardData, setWardData] = useState({});
  const [deviceBarcode, setDeviceBarcode] = useState("")
  const [dateOfInstallation, setDateOfInstallation] = useState("")

  const [enableLinkingDevice, setEnableLinkingDevice] = useState(null)
  const [devRegData, setDevRegData] = useState(null)

  const [linkingStatus, setLinkingStatus] = useState(null)
  const [wardUpdateStatus, setWardUpdateStatus] = useState(null);

  const [testStatus, setTestStatus] = useState(null);
  const [editLinking, setEditLinking] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);

  const [editFirmwareFields, setEditFirmawareFields] = useState(null)
  const [canUpdateStatus, setCanUpdateStatus] = useState(false)
  const [wardStatus, setWardStatus] = useState(false)

  // const { lastPowerOn } = useSelector((state) => state.lastPowerOn);
  // const { lastMotorOn } = useSelector((state) => state.lastMotorOn);

  //Test states
  const [powerOnStatus, setPowerOnStatus] = useState(null)
  const [motorOnStatus, setMotorOnStatus] = useState(null)
  const [motorOffStatus, setMotorOffStatus] = useState(null)

  const [confirmSendForApproval, setConfirmSendForApproval] = useState(false)
  const [forceLink, setForceLink] = useState(false)
  const [forceLinkConfirmPopup, setForceLinkConfirmPopup] = useState(null)
  
  useEffect(() => {
    if (saveSurveyDetailsData && saveSurveyDetailsData.statusCode == 200) {
      setWardUpdateStatus("Updated successfully")
    } else if(saveSurveyDetailsData.statusCode == 400) {
      setWardUpdateStatus("Something went wrong!")
    }
  }, [saveSurveyDetailsData])

  useEffect(() => {
    const ward_data = location.state.wardData
    if (ward_data) {
      setWardData(ward_data)
      setWardStatus(ward_data.wardStatus)
      if (ward_data && ward_data.regData) {
        setDevRegData(ward_data.regData)
      }
      if (ward_data.survey_data) {
        setSiteImageObject({
          "anurakshak": get(ward_data, 'survey_data.anurakshak.photo', null),
          "site": get(ward_data, 'survey_data.siteDetails.photo', null),
          "borewell motor": get(ward_data, 'survey_data.borewell.borewell_motor_photo', null),
          "electricity meter": get(ward_data, 'survey_data.electricity_meter.photo', null),
          "distribution line": get(ward_data, 'survey_data.distribution_line.photo', null),
          "starter": get(ward_data, 'survey_data.starter.photo', null),
          "deviceImage": get(ward_data, 'deviceImage', null)
        })
      }
    }
  }, [location.state.wardData])
  const validationDeviceDetails = () => {
    if (deviceBarcode && deviceBarcode !== "" && dateOfInstallation) {
      dispatch(checkIfBarCodeAssignedToWard(deviceBarcode))
    } else {
      alert("Enter device barcode & date of installation")
    }
  }

  useEffect(() => {
    if (barCodeAssignedToWard && barCodeAssignedToWard.statusCode === 200) {
      const data = barCodeAssignedToWard.data
      console.log(">>", data)
      if (data.regdata && data.regdata.simNumber && data.regdata.carrierName) {
        setEnableLinkingDevice(data.canbeAssigned)
        if (data.canbeAssigned === false) {
          let locationDetailArray = [], locationString = ""
          const wardDataLinked = data.wardData
          locationDetailArray = wardDataLinked.map((e) => {
            return `${e.districtName} -> ${e.divisionName} -> ${e.blockName} -> ${e.panchayatName} -> ${e.name}`
          })
          locationString = `This device is linked to ${locationDetailArray.length} scheme. ${locationDetailArray.join(" and ")}`
          
          if(wardDataLinked.lenght && wardData.id === wardDataLinked[0].id){
            locationString = `${locationString}. This device is linked to same (current) scheme.`
          }

          locationString = `${locationString} Do you want to confirm? Proceeding 
              will enabled the Link device option, clicking which will removed this device 
              from the schemes it is linked and link it to current scheme.
            `
          // alert(`Cannot be assigned. Could be already assigned to some ward! ${locationString}`)
          setForceLinkConfirmPopup(locationString)
        }
      } else {
        alert("No details found (Any of these fields are empty - sim number, carrier name, firmware data)")
      }
      setDevRegData(data.regdata)
    } else if (barCodeAssignedToWard && barCodeAssignedToWard.statusCode == 400) {
      alert("No matching record found")
    }
  }, [barCodeAssignedToWard])

  useEffect(() => {
    if (manualLinkingUpdateWardStatus && manualLinkingUpdateWardStatus.statusCode == 200) {
      alert("Status updated successfully")
      dispatch(resetManualLinking())
    }
  }, [dispatch, manualLinkingUpdateWardStatus])

  useEffect(() => {
    if(updateFirmwareState && updateFirmwareState.statusCode == 200 && updateFirmwareState.data){
      setDevRegData(updateFirmwareState.data)
      setEditFirmawareFields(null)
    } else if(updateFirmwareState && updateFirmwareState.statusCode == 400) {
      alert("Something went wrong!!")
    }
  }, [updateFirmwareState])

  const linkDeviceToWardHandler = () => {
    // console.log("Link to wad", devRegData.deviceId, wardData.id);
    let date = new Date().setHours(0, 0, 0, 0) / 1000
    if (dateOfInstallation) {
      date = new Date(dateOfInstallation).setHours(0, 0, 0, 0) / 1000
    }
    dispatch(linkDeviceToWardAction(devRegData.deviceId, wardData.id, date, forceLink))
  }

  useEffect(() => {
    if (linkDeviceToWard && linkDeviceToWard.statusCode === 200) {
      alert("Device Linking Success")
      let wardDataTemp = { ...wardData }
      if(linkDeviceToWard.data){
        wardDataTemp = linkDeviceToWard.data
      }

      setWardData({ ...wardDataTemp, regData: devRegData })
      
      dispatch(resetManualLinking())
      setEnableLinkingDevice(false)
      setForceLink(false)
    } else {
      // setLinkingStatus("Device Linking Failed")
    }
  }, [linkDeviceToWard])

  const runTest = (deviceId, ts) => {
    if (deviceId) {
      const dtFrom = new Date(ts)
      const dateFrom = dtFrom.setHours(dtFrom.getHours() + 5) / 1000
      const dateTo = new Date().getTime() / 1000

      dispatch(getAllDeviceTelemetry(deviceId, '', parseInt(dateTo - dateFrom) / 60))
    } else {
      alert("First link the device")
    }
  }

  useEffect(() => {
    let testStatus = {}
    if (allDeviceData && allDeviceData.statusCode == 200) {
      const telemetryData = allDeviceData.data
      if (telemetryData.length) {
        testStatus["power_status"] = "Test Pass"
        testStatus["device_hb"] = getDateTime(telemetryData[0]["_ts"]);
        testStatus["newtwork_status"] = getSGMStrength(telemetryData[0]["g"])
        const motorOnData = telemetryData.find((e) => {
          if (e.p == "1" && e.l == "1" && e.m == "4") {
            return e
          }
        })

        const motorOffData = telemetryData.find((e) => {
          if (e.p == "1" && e.l == "0" && e.m == "5") {
            return e
          }
        })

        if(motorOffData){
          testStatus["motor_off_ts"] = getDateTime(motorOffData["_ts"]);
        }

        if (motorOnData) {
          testStatus["motor_on_ts"] = getDateTime(motorOnData["_ts"]);
          testStatus["motor_on_status"] = "Test Pass"
          testStatus["overall"] = "pass"
        } else {
          testStatus["motor_on_ts"] = null
          testStatus["motor_on_status"] = "Test Failed"
          testStatus["overall"] = "fail"
        }
      } else {
        testStatus["power_status"] = "Test Failed"
        testStatus["device_hb"] = null
        testStatus["motor_on_status"] = "Test Failed"
        testStatus["overall"] = "fail"
      }
      setCanUpdateStatus(true)
    }
    setTestStatus(testStatus)
  }, [allDeviceData])

  const onImageChange = (e, title) => {
    let siteImageObjectTemp = { ...siteImageObject }
    siteImageObjectTemp[title] = e.target.files.length ? e.target.files[0] : null;
    setSiteImageObject(siteImageObjectTemp)
  }

  const saveImages = async () => {
    let error = false
    // Object.keys(siteImageObject).forEach(e => {
    //   if (siteImageObject[e] == null) {
    //     error = true
    //   }
    // })

    if (error) {
      alert("Select all the images")
    } else {
      const uploadedURLS = {}
      const imagetitles = Object.keys(siteImageObject)
      for (let i = 0; i < imagetitles.length; i++) {
        if (siteImageObject[imagetitles[i]] && ((typeof siteImageObject[imagetitles[i]]) !== 'string')) { //to ignore data from server
          const imageURL = await fileUpload(siteImageObject[imagetitles[i]])
          uploadedURLS[imagetitles[i]] = imageURL
        }
      }

      manualUpdateWardImages(wardData.id, uploadedURLS).then((resp) => {
        alert("Ward updated successfully. Check Details from installation report page")
        if(resp && resp.statusCode == 200 && resp.data)
          setWardData({...resp.data, regData: devRegData})
      })
    }
  }

  useEffect(() => {
    if(wardData && wardData.regData && wardData.regData.barcode){
      runTest(wardData.regData.deviceId, wardData.regData._ts)
    }
  }, [wardData])

  return (
    <>
      <Helmet>
        <title>{title.label2} | Installation Device Details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <PageHeading
            title={"Installation"}
            prevPath={[
              { name: " Installation Report" },
              { name: "Scheme Installation Approval" },
            ]}
          />
          {/* <Box sx={{ py: 1 }}>
            <Grid container spacing={1}>
              <Grid item md={1}>
                <Typography color="textPrimary" variant="subtitle2">
                  Assignment Type:
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography
                  style={{ fontWeight: "500", color: "#777" }}
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {assignmentType}
                </Typography>
              </Grid>
            </Grid>
          </Box> */}

          <Box sx={{ py: 4 }}>
            <Grid container spacing={1}>
              <Grid item md={6} sx={{ border: "1px solid #CCC", backgroundColor: "#FFF", borderRadius: 1, padding: 2 }}>
                <Grid
                  item
                  md={12}
                  display="flex"
                  flexDirection={"row"}
                  justifyContent="center"
                  marginBottom={5}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    style={{ color: priColor }}
                  >
                    Site Survey
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ marginLeft: 18, fontWeight: "bold", color: priColor }}
                  >
                    (Status: {wardStatus})
                  </Typography>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <Typography color={priColor} variant="subtitle2">
                      Site Details
                    </Typography>

                    <Box sx={{ py: 2 }}>
                      <Grid container>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            {" "}
                            District:{" "}
                          </Typography>{" "}
                          <Typography color="textSecondary" variant="subtitle2" fontWeight={'bold'} style={{ marginLeft: 8 }}>
                            {wardData.districtName}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            Division:{" "}
                          </Typography>{" "}
                          <Typography variant="subtitle2" color="textSecondary" fontWeight={'bold'} style={{ marginLeft: 8 }}>
                            {wardData.division}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            Block:{" "}
                          </Typography>{" "}
                          <Typography variant="subtitle2" color="textSecondary" fontWeight={'bold'} style={{ marginLeft: 8 }}>
                            {" "}
                            {wardData.blockName}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            Panchayat:{" "}
                          </Typography>{" "}
                          <Typography variant="subtitle2" color="textSecondary" fontWeight={'bold'} style={{ marginLeft: 8 }}>
                            {wardData.panchayatName}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            Ward:{" "}
                          </Typography>{" "}
                          <Typography variant="subtitle2" color="textSecondary" fontWeight={'bold'} style={{ marginLeft: 8 }}>
                            {" "}
                            {wardData.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item md={6} borderLeft={"1px solid #CCC"}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><Typography color={priColor} variant="subtitle2">
                      Device Details
                    </Typography>
                      <button onClick={() => {
                        setConfirmPopup(true)
                      }}>Edit</button>
                    </Box>
                    <Box sx={{ py: 2 }}>
                      <Grid container>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            Device Barcode:
                          </Typography>
                          <Typography color="textSecondary" fontWeight={'bold'} style={{ marginLeft: 8 }}>
                            {
                              !editLinking && wardData.regData && wardData.regData.barcode ?
                                wardData.regData.barcode :
                                <input onChange={(e) => setDeviceBarcode(e.target.value)} value={deviceBarcode} style={{ width: 150 }} />
                            }
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            Date of Installation:
                          </Typography>
                          <Typography color="textSecondary" fontWeight={'bold'} style={{ marginLeft: 8 }}>
                            {!editLinking && wardData.regData && wardData.regData.barcode ?
                              getInstallationDate(wardData) :
                              <input
                                type="date"
                                value={dateOfInstallation}
                                onChange={(e) => setDateOfInstallation(e.target.value)}
                                style={{ width: 150 }} />}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            SIM Number:
                          </Typography>
                          <Typography variant="subtitle2" color="textSecondary" fontWeight={'bold'} style={{ marginLeft: 8 }}>
                            {devRegData ? (devRegData.simNumber ? devRegData.simNumber : "Not Available") : "-"}
                          </Typography>
                          {devRegData && devRegData.simNumber ? <button onClick={() => {
                            setEditFirmawareFields({
                              fieldName: "simNumber",
                              label: "SIM Number",
                              oldData: devRegData.simNumber,
                              newData: devRegData.simNumber,
                              id: devRegData.id
                            })
                          }}>Edit</button> : null}
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            Carrier Name:
                          </Typography>
                          <Typography variant="subtitle2" color="textSecondary" fontWeight={'bold'} style={{ marginLeft: 8 }}>
                            {devRegData ? (devRegData.carrierName ? devRegData.carrierName : "Not Available") : "-"}
                          </Typography>
                          {devRegData && devRegData.carrierName ? <button onClick={() => {
                            setEditFirmawareFields({
                              fieldName: "carrierName",
                              label: "Carrier Name",
                              fieldType: "dropdown",
                              oldData: devRegData.carrierName,
                              newData: devRegData.carrierName,
                              id: devRegData.id
                            })
                          }}>Edit</button> : null}
                        </Grid>
                        <Grid md={12} item display="flex" flexDirection={"row-reverse"} pt={1}>
                          {
                            enableLinkingDevice === false &&
                            <Tooltip title="Reason for cannot link : Already assigned to ward or Sim number, Carrier name missing">
                              <InfoSharp fontSize="small" />
                            </Tooltip>
                          }
                          {linkingStatus !== null ? <Typography color="textSecondary" variant="subtitle2" >
                            {linkingStatus}
                          </Typography> : <>
                            <button disabled={!enableLinkingDevice} onClick={linkDeviceToWardHandler}>Link Device to this ward</button>
                            &nbsp;
                            <button disabled={!editLinking && devRegData && devRegData.barcode} onClick={validationDeviceDetails}>Validate</button>
                          </>
                          }
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item md={6} borderTop={"1px solid #CCC"}>
                    <Typography color={priColor} variant="subtitle2">
                      TE Details
                    </Typography>
                    <Box sx={{ py: 2 }}>
                      <Grid container>
                        <Grid
                          item
                          md={12}
                          pr={2}
                          style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            Name:
                          </Typography>
                          <Typography variant="subtitle2" color="textSecondary" fontWeight={'bold'} style={{ marginLeft: 8 }}>
                            {wardData.installation_user_mapping
                              ? wardData.installation_user_mapping.name
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          pr={2}
                          style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            Mobile Number:
                          </Typography>
                          <Typography variant="subtitle2" color="textSecondary" fontWeight={'bold'} style={{ marginLeft: 8 }}>
                            {wardData.installation_user_mapping
                              ? wardData.installation_user_mapping
                                .mobile_number
                              : "Not Mapped"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    borderLeft={"1px solid #CCC"}
                    borderTop="1px solid #CCC"
                  >

                  </Grid>
                  <Grid item md={12} style={{ borderTop: "1px solid #CCC" }}>
                    <Typography color={priColor} variant="subtitle2">
                      Other Details
                    </Typography>
                    <Formik
                      initialValues={{
                        installation_remark: get(wardData, "survey_data.installation_remark", ""),
                        extra_input: get(wardData, "survey_data.extra_input", ""),
                        borewell_status: toString(get(wardData, "survey_data.borewell.status", "")),
                        electricity_meter_status: toString(get(wardData, "survey_data.electricity_meter.status", "")),
                        distribution_line_status: toString(get(wardData, "survey_data.distribution_line.status", "")),
                        starter_status: toString(get(wardData, "survey_data.starter.status", "")),
                        anurakshak_name: get(wardData, "survey_data.anurakshak.name", ""),
                        anurakshak_phone: get(wardData, "survey_data.anurakshak.mobile_number", "")
                      }}
                      enableReinitialize
                      onSubmit={async (
                        values,
                        { setErrors, setStatus, setSubmitting }
                      ) => {
                        let surveyData = {}
                        if (wardData.survey_data) {
                          surveyData = { ...wardData.survey_data }
                          surveyData.anurakshak.name = values.anurakshak_name
                          surveyData.anurakshak.mobile_number = values.anurakshak_phone
                          surveyData.borewell.status = values.borewell_status == 'true'
                          surveyData.electricity_meter.status = values.electricity_meter_status == 'true'
                          surveyData.distribution_line.status = values.distribution_line_status == 'true'
                          surveyData.starter.status = values.starter_status == 'true'
                          surveyData.installation_remark = values.installation_remark
                          surveyData.extra_input = values.extra_input
                        } else {
                          surveyData = {
                            installation_remark: values.installation_remark,
                            extra_input: values.extra_input,
                            "anurakshak": {
                              "status": true,
                              "name": values.anurakshak_name,
                              "mobile_number": values.anurakshak_phone,
                              "photo": null
                            },
                            "siteDetails": {
                              "photo": ""
                            },
                            "borewell": {
                              "status": values.borewell_status == 'true',
                              "borewell_photo": "",
                              "borewell_motor_photo": ""
                            },
                            "electricity_meter": {
                              "status": values.electricity_meter_status == 'true',
                              "photo": ""
                            },
                            "distribution_line": {
                              "status": values.distribution_line_status == 'true',
                              "photo": ""
                            },
                            "starter": {
                              "status": values.starter_status == 'true',
                              "photo": ""
                            },
                            "status": "pending"
                          }
                        }
                        dispatch(saveSurveyDetails(surveyData, wardData.id))
                      }
                      }
                      validationSchema={Yup.object().shape({
                        borewell_status: Yup.string().required(),
                        electricity_meter_status: Yup.string().required(),
                        distribution_line_status: Yup.string().required(),
                        starter_status: Yup.string().required(),
                        anurakshak_name: Yup.string().required(),
                        anurakshak_phone: Yup.string()
                          .required()
                          .matches(/^[0-9]+$/, "Must be only digits")
                          .min(10, 'Must be exactly 10 digits')
                          .max(10, 'Must be exactly 10 digits'),
                        
                        installation_remark: Yup.string().required(),
                      })}>
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                      }) => (
                        
                        <form noValidate onSubmit={handleSubmit}>
                          <Box sx={{ py: 2 }}>
                            <Grid container>
                              <Grid
                                item
                                md={12}
                                style={{ display: "flex", flexDirection: "row" }}
                              >
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle2"
                                >
                                  Anurakshak Name:
                                </Typography>
                                <span style={{ marginLeft: 8 }}>
                                  <input
                                    type="text"
                                    name={"anurakshak_name"}
                                    value={values.anurakshak_name}
                                    onChange={handleChange}
                                    style={{
                                      width: 150, borderColor: Boolean(touched.anurakshak_name && errors.anurakshak_name) ? "red" : ""
                                    }} />
                                </span>
                                <Typography color="error" style={{ marginLeft: 10 }}>{touched.anurakshak_name && errors.anurakshak_name}</Typography>
                              </Grid>
                              <Grid
                                item
                                md={12}
                                style={{ display: "flex", flexDirection: "row" }}
                              >
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle2"
                                >
                                  Anurakshak Mobile Number:
                                </Typography>
                                <span style={{ marginLeft: 8 }}>
                                  <input
                                    type="text"
                                    name={"anurakshak_phone"}
                                    value={values.anurakshak_phone}
                                    onChange={handleChange}
                                    style={{
                                      width: 150, borderColor: Boolean(touched.anurakshak_phone && errors.anurakshak_phone) ? "red" : ""
                                    }} />
                                </span>
                                <Typography color="error" style={{ marginLeft: 10 }}>{touched.anurakshak_phone && errors.anurakshak_phone}</Typography>
                              </Grid>
                              <OtherDetailsItem
                                value={values.borewell_status}
                                onBlur={handleBlur}
                                name={"borewell_status"}
                                onChange={handleChange}
                                title="Borewell/Motor Status:"
                                options={[
                                  {value: true, label: "Working"},
                                  {value: false, label: "Not Working" }
                                ]}
                                error={Boolean(touched.borewell_status && errors.borewell_status)}
                                item={"borewell"}
                              />
                              <OtherDetailsItem
                                value={values.electricity_meter_status}
                                onBlur={handleBlur}
                                name={"electricity_meter_status"}
                                onChange={handleChange}
                                title="Electricity Meter:"
                                options={[
                                  {value: true, label: "Working"},
                                  {value: false, label: "Not Working" }
                                ]}
                                error={Boolean(touched.electricity_meter_status && errors.electricity_meter_status)}
                                item={"electricity_meter"}
                              />
                              <OtherDetailsItem
                                value={values.distribution_line_status}
                                onBlur={handleBlur}
                                name={"distribution_line_status"}
                                onChange={handleChange}
                                title="Distribution line:"
                                options={[
                                  {value: true, label: "Ok"},
                                  {value: false, label: "Not Ok" }
                                ]}
                                error={Boolean(touched.distribution_line_status && errors.distribution_line_status)}
                                item={"distribution_line"}
                              />
                              <OtherDetailsItem
                                value={values.starter_status}
                                onBlur={handleBlur}
                                name={"starter_status"}
                                onChange={handleChange}
                                title="Starter Status:"
                                options={[
                                  {value: true, label: "Working"},
                                  {value: false, label: "Not Working" }
                                ]}
                                error={Boolean(touched.starter_status && errors.starter_status)}
                                item={"starter"}
                              />
                              <OtherDetailsItem
                                value={values.installation_remark}
                                onBlur={handleBlur}
                                name={"installation_remark"}
                                onChange={handleChange}
                                title="Installation Remark:"
                                options={INSTALLATION_REPORT_OPTIONS}
                                error={Boolean(touched.installation_remark && errors.installation_remark)}
                                item={"installation_remark"}
                              />
                              {(values.installation_remark == "drn" || values.installation_remark == "other") ? <>
                                <input placeholder="Enter reason" type="text" name={"extra_input"}
                                  value={values.extra_input}
                                  onChange={handleChange} />
                              </> : null}
                            </Grid>
                            <Grid md={12} item display="flex" flexDirection={"row-reverse"}>
                              <button disabled={!Object.keys(touched).length} type="submit">Update</button> &nbsp;&nbsp;
                              <Typography color={'green'} variant="subtitle2"> { isSubmitting ? "Loading.." : (Object.keys(touched).length == 0 ? wardUpdateStatus : "")}</Typography>&nbsp;&nbsp;
                            </Grid>
                          </Box>
                        </form>)}
                    </Formik>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={12}
                  display="flex"
                  flexDirection={"row"}
                  justifyContent="center"
                  style={{ borderTop: "1px solid #CCC" }}
                >
                  <Typography color={priColor} variant="subtitle2">
                    Testing
                  </Typography>
                  {/* <button style={{ marginLeft: 10 }} onClick={runTest}>Run Test</button> */}
                </Grid>
                <Grid container spacing={1}>
                  <Grid item md={8}>
                    <Box sx={{ py: 2 }}>
                      <Grid container>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            Device Barcode:
                          </Typography>
                          <Typography variant="subtitle2" color="textSecondary" fontWeight={'bold'} style={{ marginLeft: 8 }}>
                            {_.has(wardData, "regData.barcode")
                              ? wardData.regData.barcode
                              : ""}
                          </Typography>
                        </Grid>
                        
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            Network Status:
                          </Typography>{" "}
                          <Typography variant="subtitle2" color="textSecondary" fontWeight={'bold'} style={{ marginLeft: 8 }}>
                            {" "}
                            {_.has(
                              testStatus,
                              "newtwork_status"
                            )
                              ? `${testStatus.newtwork_status} (last received)`
                              : "-"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            Device Heartbeat Details:
                          </Typography>{" "}
                          <Typography variant="subtitle2" color="textSecondary" fontWeight={'bold'} style={{ marginLeft: 1 }}>
                            {_.has(
                              testStatus,
                              "device_hb.date"
                            )
                              ? `${testStatus.device_hb.date} ${testStatus.device_hb.time}`
                              : "-"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            Motor on time:
                          </Typography>{" "}
                          <Typography variant="subtitle2" color="textSecondary" fontWeight={'bold'} style={{ marginLeft: 1 }}>
                            {_.has(
                              testStatus,
                              "motor_on_ts.date"
                            )
                              ? `${testStatus.motor_on_ts.date} ${testStatus.motor_on_ts.time}`
                              : "-"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            Motor off time:
                          </Typography>{" "}
                          <Typography variant="subtitle2" color="textSecondary" fontWeight={'bold'} style={{ marginLeft: 1 }}>
                            {_.has(
                              testStatus,
                              "motor_off_ts.date"
                            )
                              ? `${testStatus.motor_off_ts.date} ${testStatus.motor_off_ts.time}`
                              : "-"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row", alignItems:"center" }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            Power On Test:
                          </Typography>
                          <RadioGroup name="radio-buttons-group" sx={{flexDirection:"row", display:"flex", ml:2}} onChange={(e) => {
                            setPowerOnStatus(e.target.value)
                          }}>
                            <FormControlLabel value="Test Pass" control={<Radio />} label="Pass" sx={{color:"green"}}/>
                            <FormControlLabel value="Test Fail" control={<Radio />} label="Fail" sx={{color:"red", ml:2}}/>
                          </RadioGroup>
                          {/* <TestItem
                            data={
                              _.has(
                                testStatus,
                                "power_status"
                              )
                                ? testStatus.power_status
                                : "Not Found"
                            }
                          /> */}
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row", alignItems:"center"  }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            Motor On Test:
                          </Typography>{" "}
                          <RadioGroup name="radio-buttons-group" sx={{flexDirection:"row", display:"flex", ml:2}} onChange={(e) => {
                             setMotorOnStatus(e.target.value)
                          }}>
                            <FormControlLabel value="Test Pass" control={<Radio />} label="Pass" sx={{color:"green"}}/>
                            <FormControlLabel value="Test Fail" control={<Radio />} label="Fail" sx={{color:"red", ml:2}}/>
                          </RadioGroup>
                          {/* <TestItem
                            data={
                              _.has(
                                testStatus,
                                "power_status"
                              )
                                ? testStatus.motor_on_status
                                : "Not Found"
                            }
                          /> */}
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row", alignItems:"center"  }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            Motor Off Test:
                          </Typography>{" "}
                          <RadioGroup name="radio-buttons-group" sx={{flexDirection:"row", display:"flex", ml:2}} onChange={(e) => {
                            setMotorOffStatus(e.target.value)
                          }}>
                            <FormControlLabel value="Test Pass" control={<Radio />} label="Pass" sx={{color:"green"}}/>
                            <FormControlLabel value="Test Fail" control={<Radio />} label="Fail" sx={{color:"red", ml:2}}/>
                          </RadioGroup>
                          {/* <TestItem
                            data={
                              _.has(
                                testStatus,
                                "power_status"
                              )
                                ? testStatus.motor_on_status
                                : "Not Found"
                            }
                          /> */}
                        </Grid>
                        {/* <Grid
                          item
                          md={12}
                          marginTop={3}
                          marginBottom={3}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography color={priColor} variant="h5">
                            Test result:
                          </Typography>
                          <Typography
                            color={
                              _.has(
                                testStatus,
                                "overall"
                              )
                                ? testStatus.overall == "pass"
                                  ? "green"
                                  : "red"
                                : ""
                            }
                            variant="h5"
                            marginLeft={3}
                          >
                            {_.has(
                              testStatus,
                              "overall"
                            )
                              ? testStatus.overall.toUpperCase()
                              : "Not Found"}
                          </Typography>
                        </Grid> */}
                        {testStatus && <Grid item> <button disabled={!canUpdateStatus || !(powerOnStatus && motorOffStatus && motorOnStatus)} onClick={() => {
                          
                          setConfirmSendForApproval(true)
                          
                        }}>Send for Approval</button> </Grid>}

                        <Grid
                          item
                          md={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          {/* <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            Status:
                          </Typography>{" "}
                          <span
                            style={{
                              marginLeft: 8,
                              fontWeight: "bold",
                              color: InstallationStatusColor(
                                getInstallationStatus(wardData)
                              ),
                            }}
                          >
                            {getInstallationStatus(wardData)}
                          </span>
                          {getInstallationStatus(wardData) == "Approved" && (
                            <>
                              {wardData.verification &&
                                wardData.verification.report_url ? (
                                <a
                                  href={wardData.verification.report_url}
                                  target="_blank"
                                  style={{ marginLeft: 20 }}
                                >
                                  View Report
                                </a>
                              ) : (
                                ""
                              )}
                            </> */}
                          {/* )} */}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={0.1}></Grid>
              <Grid item md={5.9} sx={{ border: "1px solid #CCC", backgroundColor: "#FFF", borderRadius: 1, padding: 2 }}>
                <Grid
                  item
                  md={12}
                  display="flex"
                  flexDirection={"row"}
                  marginBottom={2}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    style={{ color: priColor }}
                  >
                    Site Survey Photos
                  </Typography>
                </Grid>
                <Grid container direction="column" display={"flex"} justifyContent={"space-between"}>
                  {Object.keys(siteImageObject).map((eTitle) => {
                    return <Grid item container border={'1px solid #CCC'} borderRadius={1} marginBottom={1} p={1} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                      <Grid item display={"flex"} alignItems={"center"} margin={1}>
                        <Typography variant="subtitle1" color={"textSecondary"}>{startCase(eTitle)}</Typography>
                      </Grid>
                      <Grid item display={"flex"} flexGrow={1} flexDirection={"row-reverse"} marginRight={2}>
                        {(typeof siteImageObject[eTitle] === 'string' && siteImageObject[eTitle]) && <img height={80} src={siteImageObject[eTitle]} />}
                        {typeof siteImageObject[eTitle] === 'object' && siteImageObject[eTitle] && <img height={80} src={URL.createObjectURL(siteImageObject[eTitle])} />}
                      </Grid>
                      <Grid item display={"flex"} alignItems={"center"}>
                        <Button
                          variant="contained"
                          component="label"
                        >
                          Select photo
                          <input
                            type="file"
                            onChange={(e) => onImageChange(e, eTitle)}
                            hidden
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  })}
                  <Grid item>
                    <Typography>Select all the images and click Save Images</Typography>
                    <Button variant="outlined" onClick={saveImages}>Save images</Button>
                  </Grid>
                  {/* {surveyImages.map((e, i) => {
                    return (
                      <Grid item key={i} md={2}>
                        <img
                          width={"100%"}
                          src={e.imgURL}
                          onClick={() => {
                            setOpen(true);
                            setCurrentUrl(e);
                          }}
                        />
                        <span>{e.title}</span>
                      </Grid>
                    );
                  })} */}
                </Grid>
                {/* <Grid
                  item
                  md={12}
                  marginBottom={2}
                  marginTop={2}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    style={{ color: priColor }}
                  >
                    Device Installation & Testing
                  </Typography>
                </Grid> */}
                {/* <Grid container spacing={2}>
                  {deviceImages.map((e, i) => {
                    return (
                      <Grid item key={i} md={2}>
                        <img
                          width={"100%"}
                          src={e.imgURL}
                          onClick={() => {
                            setOpen(true);
                            setCurrentUrl(e);
                          }}
                        />
                        <span>{e.title}</span>
                      </Grid>
                    );
                  })}
                </Grid> */}
                {/* {getInstallationStatus(wardData) ==
                  "Pending For Approval" && (
                    <SubmitControl wardData={wardData} />
                  )} */}
              </Grid>
            </Grid>
          </Box>
        </Container>

        <DialogPopup open={confirmPopup} handleConfirm={() => {
          setConfirmPopup(false)
          setEditLinking(true)
        }} handleClose={() => {
          setConfirmPopup(false)
          setEditLinking(false)
        }}>
          <Typography>Editing will remove current scheme link and update to new based on device barcode you are entering. Are you sure?</Typography>
        </DialogPopup>

        <DialogPopup open={confirmSendForApproval} handleConfirm={() => {
          if(powerOnStatus && motorOffStatus && motorOnStatus){            
            const datetime = getInstallationDate(wardData) 
            dispatch(linkDeviceUpdateWardStatus(
              {
                installation_data: {
                  status: "completed",
                  date: datetime
                },
                verification: null
              }, wardData.id, {
              ...devRegData, "installation_testing_status": {
                "power_status": powerOnStatus,
                "motor_on_status": motorOnStatus,
                "motor_off_status": motorOffStatus,
                "overall": (powerOnStatus == "Test Pass" && motorOffStatus == "Test Pass" && motorOnStatus === "Test Pass")?"pass":"fail" ,
                "date": `${datetime.date} ${datetime.time}`
              }
            }))
            
            setConfirmSendForApproval(false)
          } else {
            alert("Please select test status")
          }
        }} handleClose={() => {
          setConfirmSendForApproval(false)
        }}>
          <Typography>This is send current scheme for Approval. If already approved, status will change to 'Pending for Approval'. Are you sure?</Typography>
        </DialogPopup>

        {currentUrl && (
          <DialogUI
            handleClose={() => setOpen(false)}
            open={open}
            image={currentUrl}
          />
        )}

        {editFirmwareFields ?
          <DialogPopup open={editFirmwareFields} handleConfirm={() => {
            if (devRegData && devRegData["id"] && devRegData["deviceId"]) {
              if (editFirmwareFields.fieldName && editFirmwareFields.newData) {
                dispatch(updateFirmwareData({
                  ...devRegData,
                  [editFirmwareFields.fieldName]: editFirmwareFields.newData
                }))
              } else {
                alert("Please enter value for " + editFirmwareFields.fieldName)
              }
            } else {
              alert("Device reg data not found")
            }
          }} handleClose={() => {
            setEditFirmawareFields(null)
          }}>
            <Typography>{editFirmwareFields && editFirmwareFields.label}</Typography>
            {editFirmwareFields.fieldType === "dropdown" ? <>
              <select onChange={(e) => {
                setEditFirmawareFields({ ...editFirmwareFields, newData: e.target.value })
              }}>
                <option>AIRTEL</option>
                <option>BSNL</option>
                <option>VI</option>
              </select>
            </>:
            <TextField value={editFirmwareFields.newData} onChange={(e) => {
              setEditFirmawareFields({ ...editFirmwareFields, newData: e.target.value })
            }} />
          }
          </DialogPopup> : null}
          <DialogPopup 
            open={forceLinkConfirmPopup} 
            handleConfirm={() => {
              setForceLink(true)
              setForceLinkConfirmPopup(null)
            }}
            handleClose={() => {
              setForceLinkConfirmPopup(null)
          }}>
            {forceLinkConfirmPopup}
          </DialogPopup>
      </Box>
    </>
  );
}

export default InstallationReportDeviceInfoManualEntry;

// function getHBtime(ts){
//   if(!ts){
//     return "-"
//   }
//   const d = getDateTime(ts)
//   return `${d["date"]} ${d["time"]}`
// }