import numeral from "numeral";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getLastPowerOn } from "../../../slices/lastPowerOn";
import moment from "moment-timezone";
import { getDateTime, getDiffInMin, historyDate } from "../../../../utils/helper";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import { priColor } from "../../../../constants";

const useStyles = makeStyles({
  root: {
    "& .scheme-data-table": {
      fontWeight: "bold",
    },
  },
});

function CustomToolbar(props) {
  let cdate = moment(new Date()).format("DD_MM_YYYY_HH_mm_ss");
  return (
    <GridToolbarContainer
      style={{
        padding: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Typography color="textPrimary" variant="h6">
       {props.title}
      </Typography>
      <GridToolbarExport
        csvOptions={{
          allColumns: true,
          fileName: `${props.fileName}_${props.locationName}_${cdate}`,
        }}
      />
    </GridToolbarContainer>
  );
}

const AssignedList = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { lastPowerOn: lastPowerOn } = useSelector(
    (state) => state.lastPowerOn
  );
  const classes = useStyles();

  const [lastPowerOnData, setLastPoweronData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const fetchDeviceStatus = (type) => {
    const deviceids = props.schemes.map((e) => {
      if (e.smsid) return e.smsid;
    });
    const clearDids = deviceids.filter((e) => e);

  };

  useEffect(() => {
    if (props.schemes) {
      fetchDeviceStatus();

      const callRefreshApi = setInterval(() => {
        fetchDeviceStatus("refresh");
      }, 5000);

      return () => {
        clearInterval(callRefreshApi);
      };
    }
  }, []);

  const getColor = (v) => {
    if(v.includes("Pending"))
      return "blue"
    else if(v.includes("Failed"))
      return "red"
    else if(v.includes("Approved")){
      return "green"
    } else {
      return "black"
    }
  }

  const getInstallationFieldObj = (v) => {
    let ret = {color:"red", text:"Site Survey Pending"}   
    if(v && !v.survey_data){
      ret =  {color:"red", text:"Site Survey Pending"}
    } else if(v && !v.installation_data){
      ret = {color:"orange", text:"Installation Pending"}
    } else if(v && v.installation_data && v.installation_data.rejected_by){
      ret =  {color:"white", bgColor:"red", text:"Rejected"}
    } else if(v && !v.verification){
      ret =  {color:priColor, text:"Approval Pending"}
    } else if( v && v.verification){
      ret =  {color:"green", text:"Completed"}
    } 
    return ret
  }

  useEffect(() => {
    let tableDataTemp = [];
    if (props.schemes && props.schemes.length) {
      let lastPowerOnData = [];
      if (lastPowerOn["data"] && lastPowerOn["data"].length) {
        lastPowerOnData = lastPowerOn["data"];
      }
      const dr = historyDate(0, "currentDay");
      props.schemes.map((page, i) => {
        if (props.showWardsIds && props.showWardsIds.length) {
          if (!props.showWardsIds.includes(page["id"])) {
            return;
          }
        }

        let motorOn = false;
        let powerOn = false;

        let dateTime = { time: "-", date: "-" };
        let network = false;
        if (lastPowerOnData) {
          const lastDataReceivedTsArr = lastPowerOnData.filter(
            (e) => e.id == page.smsid
          );
          if (lastDataReceivedTsArr && lastDataReceivedTsArr.length) {
            let ldr = lastDataReceivedTsArr[0];
            let lastDataReceivedTs = ldr["_ts"];
            if (ldr["manualGeneratedTS"])
              lastDataReceivedTs = ldr["manualGeneratedTS"];

            dateTime = getDateTime(lastDataReceivedTs);

            let t1 = new Date();
            let diff = getDiffInMin(lastDataReceivedTs, t1.getTime() / 1000);
            if (ldr.current >= 2) {
              //Motor flag
              if (diff <= 17) {
                motorOn = true;
              }
            }

            if (diff <= 17) {
              powerOn = true;
            }

            if (diff <= 1440) {
              network = true;
            }
          }
        }

        tableDataTemp.push({
          ...page,
          ...page.other,
          nicId: page.nicdata ? page.nicdata.NICDeviceId : "",
          did: page.other.deviceid,
          wname: page.name,
          assignedOn: page.installation_user_mapping ? page.installation_user_mapping.date:"",
          assignedTo: page.installation_user_mapping ? page.installation_user_mapping.name:"",
          completed_status: page,
          completed_date: page.verification ? page.verification.date : "-",
          datastamp: dateTime,
          action: page,
          network,
          id: page.id,
          remark:page.installation_data && page.installation_data.remark ? page.installation_data.remark : "-NA-",
          slno: i + 1,
        });
      });
    }

    setTableData(tableDataTemp);
    setLastPoweronData(lastPowerOn["data"]);
  }, [props.schemes]);

  
  const columns = [
    {
      field: "slno",
      headerName: "Sl No",
      width: 50,
      sortable: false,
      headerClassName: "scheme-data-table",
    },
    {
      field: "id",
      hide: true,
      width: 100,
      sortable: false,
      headerClassName: "scheme-data-table",
    },
    {
      field: "districtName",
      headerName: "Distric",
      width: 120,
      sortable: false,
    },
    {
      field: "divisionName",
      headerName: "Division",
      width: 130,
      sortable: false,
    },
    {
      field: "blockName",
      headerName: "Block",
      width: 130,
      sortable: false,
    },
    {
      field: "panchayatName",
      headerName: "Panchayat",
      width: 130,
      sortable: false,
    },
    {
      field: "wname",
      headerName: "Ward",
      width: 50,
      sortable: false,
    },
    {
      field: "subScheme",
      headerName: "Scheme Type",
      width: 80,
      sortable: false,
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      width: 80,
    },
    {
      field: "assignedOn",
      headerName: "Assigned On",
      width: 130,
      sortable: false,
    },
    {
      field: "completed_status",
      headerName: "Installation Status",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        const obj = getInstallationFieldObj(params["row"])
        return <span style={{color: obj["color"], backgroundColor:obj["bgColor"]?obj["bgColor"]:""}}>{obj["text"]}</span>
      },
      valueGetter: (params) => { 
        const obj = getInstallationFieldObj(params["value"])
        return  obj["text"]
      }
    },
    {
      field: "completed_date",
      headerName: "Completed On",
      width: 170,
      sortable: false,
    },
    {
      field: "remark",
      headerName: "Remark",
      width: 130,
      sortable: false,
      renderCell: (params) => {
        return <span style={{color: getColor(params["formattedValue"])}}>{params["formattedValue"]}</span>
      }
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      sortable: false,
      renderCell: (params) => {
        const obj = getInstallationFieldObj(params["formattedValue"])
        let disabled = true
        if(obj.text == "Completed" || obj.text == "Approval Pending" || obj.text == "Rejected" || obj.text.toLocaleLowerCase() == 'installation pending'){
          disabled = false
        }
        return <Button disabled={disabled} onClick={() => handleClick(params["formattedValue"])}>View Details</Button>
      }
    },
  ];

  const handleClick = (data) => {
    navigate('device-info', {
      state: {
        wardData: data,
        assignmentType: "New Device Installation"
      }
    });
  }

  return (
    <Card>
      <div style={{ height: 800, width: "100%" }} className={classes.root}>
        <DataGrid
          loading={!tableData.length}
          // checkboxSelection
          disableSelectionOnClick
          // onSelectionModelChange={(ids) => {
          //   const selectedIDs = new Set(ids);
          //   const selectedRowData = tableData.filter((row) =>
          //     selectedIDs.has(row.id.toString())
          //   );
          //   props.onSelect(selectedRowData);
          // }}
          rows={tableData}
          columns={columns}
          pageSize={40}
          rowsPerPageOptions={[5]}
          components={{
            Toolbar: CustomToolbar,
          }}
          // isRowSelectable={(params) => !params.row.installation_user_mapping}
          componentsProps={{
            toolbar: { fileName: props.fileName, locationName: props.distName, title:props.title },
          }}
        />
      </div>

      {/* {lastPowerOn["status"] == "loading" ? <Loader /> : null} */}
    </Card>
  );
};

export default AssignedList;
