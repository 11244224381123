export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};

export const title = {
  label1 : "Bihar Government",
  label2 : "Har Ghar Nal Jal Yojna" 
}

export const colors = {
  textGreen: "#10b759",
  textYellow: "#fbbc06"
}

export const priColor = "#727cf5"

export const operationalInitialValue = { nonFunctional: 0, functional: 0, functionalWLS: 0, functionalPWS: 0, nonFunctionalWLS: 0, nonFunctionalPWS: 0 }

export const ISSUE_TYPES = {
  "SCHEME NON-FUNCTIONAL": [
    "CT Removed", 
    "CT Not Working", 
    "Motor Burn", 
    "Starter Burn", 
    "No Starter/ Direct Motor Operation",
    "Motor Not Running Due To Pipe Leakege", 
    "Motor Not Running Due To Operator's Non Payment",
    "Other"
  ],
  "DEVICE NON-FUNCTIONAL": [
    "Power Cable Removed/Burn",
    "All Device Setup Burn",
    "Electric Meter Burn",
    "No Electricity Supply/Fault At Scheme",
    "Electricity Prepaid Meter Not Rechrge/Fault",
    "Power Not Showing In Device",
    "All Device Setup Removed By Operator/Contractor",
    "Other"
  ]
}

export const userIdentityTypes = [
  {label: "PHED User", value: "PHED_USER"},
  {label: "NIC User", value: "NIC_USER"},
  {label: "Geron/Rollz Employee", value: "GERON_"},
  {label: "Other", value: "OTHER"}
]

export const geronEmpTypes = [
  {label: "Firmware operator", value: "GERON_FIRMWARE_OPERATOR"},
  {label: "Data Operator", value: "GERON_DATA_OPERATOT"},
  {label: "GIS Operator", value: "GERON_GIS_OPERATOR"},
  {label: "Mobile App User", value: "MOBILE_APP_USER"}
]

export const mobileAppUserType = [
  {label: "Head /Zonal Office", value: "HO"},
  {label: "Division Supervisor", value: "DS"},
  {label: "Technical Executive", value: "TE"}
]

export const InstallationStatusColor  = (v) => {
  if(v == "Approved")
    return "green"
  else if(v == "Pending")
    return "red"
  else if(v == "Pending For Approval")
    return priColor
  else 
    return "red"
}

export const POWERED_BY = "AYNIC Private Limited"

export const INSTALLATION_REPORT_OPTIONS = [
  {value: "nr", label:"No Remark"},
  {value: "snfslt", label:"Scheme is not functional since long time"},
  {value: "odi", label:"Operator denied installtion"},
  {value: "wlas", label: "Water logging at scheme"},
  {value: "drn", label: "Device Replacement needed"},
  {value: "other", label: "Other"}
]


export const getInstallationStatus = (v) => {
  let ret = {color:"red", text:"Site Survey Pending"}   
  if(!v.survey_data){
    ret =  {color:"red", text:"Site Survey Pending"}
  } else if(!v.installation_data){
    ret = {color:"orange", text:"Installation Pending"}
  } else if(v.installation_data && v.installation_data.rejected_by){
    ret =  {color:"white", bgColor:"red", text:"Rejected"}
  } else if(!v.verification){
    ret =  {color:priColor, text:"Approval Pending"}
  } else if(v.verification){
    ret =  {color:"green", text:"Completed"}
  } 
  return ret
}